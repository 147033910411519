import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Colors } from '../../Constant/Color'
import CustomButton from '../../Components/Button'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import BasicSelect from '../../Components/Dropdown';
import { DataGrid } from "@mui/x-data-grid"
import Checkbox from '../../Components/checkbox';
import { CheckBox } from '@mui/icons-material';
import { Navigate, useNavigate } from 'react-router-dom';
import { RingLoader } from "react-spinners";
import axios from 'axios';
import { Base_Uri, Image_Uri } from '../../Constant/BaseUri';
import { useDispatch, useSelector } from 'react-redux';
import { updateAllEmployees, updateAllExchangeInvoice, updateAllInvoices, updateAllReturnInvoices, updateDayReturnInvoiceEmp, updateInvoice, updateProducts, updateSuppliers, updatetodayEmployeeExchangeInvoice, viewSelectedInvoice } from '../../Redux/LoginReduces';
import Navbar from '../../Components/Navbar';
import ViewInvoiceDetailModal from '../../Components/viewInvoiceDetailModal';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import DownloadIcon from "@mui/icons-material/Download";
import CustomModal from '../../Components/modal';
import { getElementError } from '@testing-library/react';
import EditSupplierModal from '../../Components/editSuppliers';
import AddSupplierModal from '../../Components/addSupplierModal';
import SupplierLedgerModal from '../../Components/supplierLedgerModal';
import SupplierPayModal from '../../Components/supplierPayModal';


function Suppliers() {


    const dispatch = useDispatch()

    const allSuppliers = useSelector(state => state?.loginReducer?.suppliers)

    const [dataClicked, setDataClicked] = useState("")
    const userData = useSelector((state) => state.loginReducer.updatedState);

    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [gridKey, setGridKey] = useState(0); // Add this state variable
    const [loading, setLoading] = useState(false)
    const [suppliers, setSuppliers] = useState([])
    const [searchData, setSearchData] = useState(false)
    const [openEditSupplierModal, setOpenEditSupplierModal] = useState(false)
    const [openAddSupplierModal, setOpenAddSupplierModal] = useState(false)

    const [ledgerLoading, setLedgerLoading] = useState(false);


    const [openLedgerModal, setOpenLedgerModal] = useState(false)
    const [openPayModal, setOpenPayModal] = useState(false)
    const [supplierClicked, setSupplierClicked] = useState("")
    const [amountPaid, setAmountPaid] = useState("")
    const [paymentLoading, setPaymentLoading] = useState(false)

    const [paymentReason, setPaymentReason] = useState("")


    const [paymentMethod, setPaymentMethod] = useState([
        "Cash",
        "Card",
        "Online",
        "Discount"
    ])


    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("select")



    const getAllSuppliers = async () => {



        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;

        axios.get(`${Base_Uri}getSuppliers`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json", // Include other headers as needed
            },
        }).then((res) => {

            let data = res.data


            if (data.status) {

                let suppliers = data.data

                setSuppliers(suppliers)
                dispatch(updateSuppliers(suppliers && suppliers.length > 0 ? suppliers : []));

            }



        }).catch((error) => {

            console.log(error, "error")

        })

    }

    useEffect(() => {

        setSuppliers(allSuppliers)
        getAllSuppliers()

    }, [userData.id, allSuppliers.length])


    const handleDeleteClick = (data) => {

        setOpenDeleteModal(true)
        setDataClicked(data)

    }

    const handleEditSupplierClicked = (data) => {
        setDataClicked(data)
        setOpenEditSupplierModal(true)
    }


    const handleOpenLedgerModal = () => {

        let clickedSupplier = suppliers && suppliers.length > 0 && suppliers.filter((e, i) => e.selected)
        if (clickedSupplier.length > 0) {
            clickedSupplier = clickedSupplier[0]
        }

        setLedgerLoading(true)

        let id = clickedSupplier?._id;

        axios
            .get(`${Base_Uri}getSupplierLedger/${id}`)
            .then((res) => {
                let data = res.data;


                if (!data.status) {
                    setLedgerLoading(false);
                    alert(data?.message);
                    return;
                }

                let ledgerData = data?.data;

                let dataToSend = { ...clickedSupplier };
                dataToSend.supplier_ledger = ledgerData;

                // clickedProduct.productLedger = ledgerData;

                setLedgerLoading(false)
                setSupplierClicked(dataToSend)
                setOpenLedgerModal(true)
                // setOpenLedgerModal(true);
            })
            .catch((error) => {
                alert(error?.message);
                setLedgerLoading(false);
                return;
            });


    }

    const handleOpenPayModal = () => {

        let clickedSupplier = suppliers && suppliers.length > 0 && suppliers.filter((e, i) => e.selected)
        if (clickedSupplier.length > 0) {
            clickedSupplier = clickedSupplier[0]
        }
        setSupplierClicked(clickedSupplier)
        setOpenPayModal(true)
    }

    const columns = [
        {
            field: "supplierName",
            headerName: "Supplier Name",
            width: 150,
            filterable: false,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: "shopDetails",
            headerName: "Shop Details",
            width: 350,
            filterable: false,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: "mobileNumber",
            headerName: "Mobile Number",
            width: 150,
            filterable: false,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: "balance",
            headerName: "Balance",
            width: 150,
            filterable: false,
            headerClassName: 'super-app-theme--header',
            renderCell: (cellValues) => {
                return (
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "30%",
                            color: Colors.red,
                            backgroundColor: Colors.white,
                        }}
                    >
                        {`£${cellValues?.row?.balance ? Number(cellValues?.row?.balance).toFixed(2) : 0}`}
                    </Box>
                );
            },
        },
        {
            field: "created_at",
            headerName: "Date",
            headerClassName: 'super-app-theme--header',
            width: 150,
            filterable: false,
            renderCell: (cellValues) => {
                return (
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "30%",
                            backgroundColor: Colors.white,
                        }}
                    >
                        {`${new Date(cellValues?.row?.created_at).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                        })}`}
                    </Box>
                );
            },
        },

        {
            field: "action",
            headerName: "Actions",
            width: 450,
            headerClassName: 'super-app-theme--header',
            filterable: false,
            renderCell: (cellValues) => {
                return (
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Button
                            onClick={() => handleDeleteClick(cellValues?.row)}
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "50%",
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                backgroundColor: Colors.white,
                                color: Colors.red,
                                fontWeight: "bold"
                            }}
                        >
                            Delete
                        </Button>

                        <Button
                            onClick={() => handleEditSupplierClicked(cellValues?.row)}
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "50%",
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                backgroundColor: Colors.white,
                                fontWeight: "bold",
                            }}
                        >
                            Edit Details
                        </Button>

                    </Box>
                );
            },
        },
    ];

    const handleSearchProducts = (e) => {

        let value = e.target.value


        if (!value) {
            setSearchData([])
            return
        }

        setSearchData(suppliers && suppliers?.length > 0 && suppliers?.filter((e, i) => {


            if (e?.supplierName?.toLowerCase().includes(value?.toLowerCase())
                || e?.mobileNumber == value || e?.shopDetails?.toLowerCase().includes(value?.toLowerCase())

            ) {
                return e
            }
        }))



    }

    const handleConfirmDeleteEmployee = async () => {



        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;


        setLoading(true)

        axios.delete(`${Base_Uri}deleteSupplier/${dataClicked?._id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {

            let data = res.data
            if (data?.status) {


                setSuppliers(suppliers && suppliers.length > 0 && suppliers.filter((e, i) => e._id !== dataClicked?._id))

                setOpenDeleteModal(false)
                getAllSuppliers()
                setLoading(false)
                alert(data?.message)

            } else {
                alert(data?.message)
                setLoading(false)
            }

        }).catch((error) => {

            alert(error?.message)
            setLoading(false)

        })

    }


    const handleSelectionModelChange = (selectionModel) => {

        if (selectionModel.length > 0) {


            selectionModel = selectionModel.length > 1 ? selectionModel[1] : selectionModel[0]


            setSuppliers(
                suppliers && suppliers.length > 0
                    ? suppliers.map((e) => {


                        if (selectionModel.includes(e._id)) {
                            return {
                                ...e,
                                selected: true,
                            };
                        } else {
                            return {
                                ...e,
                                selected: false,
                            };
                        }
                    })
                    : []
            );
        }
        else {
            setSuppliers(suppliers && suppliers?.length > 0 && suppliers.map((e, i) => {
                return {
                    ...e,
                    selected: false
                }
            }))
        }
    };


    const handleConfirmMakePayment = async () => {


        let dataToSend = {
            id: supplierClicked?._id,
            amount: amountPaid,
            paymentMethod: selectedPaymentMethod,
            reason: paymentReason,
            employeeId: userData?.id

        }

        let myData = await localStorage.getItem("userData");
        myData = JSON.parse(myData);
        let token = myData

        setPaymentLoading(true)


        axios.put(`${Base_Uri}payToSupplier`, dataToSend, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json", // Include other headers as needed
            },
        }).then((res) => {

            let data = res.data

            if (!data?.status) {
                setPaymentLoading(false)
                alert(data.message)
            } else {

                let otherData = suppliers && suppliers.length > 0 && suppliers?.filter((e, i) => e?._id !== data?.data?._id)
                let allSuppliers = [...otherData, data?.data]

                setSuppliers(allSuppliers)


                alert(data.message)
                setPaymentLoading(false)
                getAllSuppliers()
                setOpenPayModal(false)

            }


        }).catch((error) => {

            setPaymentLoading(false)
            alert(error?.message)

        })




    }

    return (


        ledgerLoading ? (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "70vh",
                    width: "100%",
                }}
            >
                <RingLoader loading={ledgerLoading} size={100} color={Colors.black} />
            </Box>
        ) :

            loading ? <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minHeight: "70vh", width: "100%" }} >
                <RingLoader loading={loading} size={100} color={Colors.black} />
                <Typography style={{ fontSize: "24px", color: Colors.black, fontFamily: "Poppins" }} >Deleting Data...</Typography>
            </Box> :

                <Box sx={{ padding: "20px", backgroundColor: Colors.white, marginTop: "0px" }} >
                    <Navbar currentPage="Suppliers" />
                    <Box sx={{ display: "flex" }} >
                        <Grid container md={12} xs={12} sm={12} >

                            <Grid item xs={12} sm={12} md={6} >
                                <Box sx={{ width: "100%" }} >

                                    <Grid md={11} xs={10} sm={10} >
                                        <Box sx={{ width: "100%", marginTop: { xs: "10px", sm: "10px", md: "0px" }, border: `1px solid ${Colors.button}`, borderRadius: "5px", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px 10px 5px 10px", margin: 0 }} >
                                            <TextField
                                                variant="standard" // <== changed this
                                                margin="normal"
                                                required
                                                fullWidth
                                                InputProps={{
                                                    // startAdornment: <AccountCircle />, // <== adjusted this
                                                    disableUnderline: true,
                                                    fontSize: "12px"
                                                    // <== added this
                                                    // fontSize: "2px"
                                                }}
                                                onChange={handleSearchProducts}
                                                placeholder={"Search Supplier Name Or Number"}
                                                sx={{ width: "90%", fontSize: "12px", padding: 0, fontFamily: "Poppins", fontWeight: "bold", margin: 0 }}
                                            />
                                            <img src={require("../../Images/search.png")} style={{ height: "20px" }} />
                                        </Box>

                                    </Grid>

                                </Box>
                            </Grid>

                            <CustomButton text="Add New Supplier" onClick={() => setOpenAddSupplierModal(true)} style={{ width: "200px", padding: 1, borderRadius: 1, fontSize: "14px" }} />

                        </Grid>
                    </Box>

                    {suppliers && suppliers.length > 0 && suppliers?.some((e, i) => e.selected) && <Box sx={{ display: "flex", mt: 2, ml: 1 }} >
                        <Grid container md={12} xs={12} sm={12} >
                            <Grid item xs={12} sm={12} md={6} >
                                <Box sx={{ display: "flex", alignItems: "center" }} >


                                    <Grid item xs={4} sm={3} md={4} lg={5} xl={2.5} style={{ marginRight: "5px" }} >
                                        {suppliers && suppliers?.length > 0 && suppliers?.filter((item) => item.selected).length == 1 && <CustomButton onClick={() => handleOpenLedgerModal()} text="Ledger" style={{ width: "100%", padding: "5px 5px 5px 5px", fontSize: { xl: "12px", lg: "12px", md: "10px", sm: "10px", xs: "8px" }, display: "flex", justifyContent: "space-around", marginRight: "5px", alignItems: "center", backgroundColor: Colors.white, color: "purple", border: `1px solid purple` }} />}
                                    </Grid>

                                    <Grid item xs={4} sm={3} md={4} lg={5} xl={2.5} style={{ marginRight: "5px" }} >
                                        {suppliers && suppliers?.length > 0 && suppliers?.filter((item) => item.selected).length == 1 && <CustomButton onClick={() => handleOpenPayModal()} text="Pay" style={{ width: "100%", padding: "5px 5px 5px 5px", fontSize: { xl: "12px", lg: "12px", md: "10px", sm: "10px", xs: "8px" }, display: "flex", justifyContent: "space-around", marginRight: "5px", alignItems: "center", backgroundColor: Colors.white, color: "purple", border: `1px solid purple` }} />}
                                    </Grid>

                                </Box>
                            </Grid>


                        </Grid>
                    </Box>}




                    <Box sx={{ height: "500px", backgroundColor: "white", marginTop: 5 }} >
                        <div style={{ width: "100%" }}>
                            <DataGrid
                                key={gridKey}
                                checkboxSelection
                                rowSelectionModel={suppliers && suppliers.length > 0 ? suppliers.filter((e, i) => e?.selected)?.map((row) => row._id) : []}
                                onRowSelectionModelChange={handleSelectionModelChange}
                                rows={(searchData && searchData.length > 0) ? searchData : (suppliers && suppliers.length > 0) ? suppliers : []}
                                columns={columns}
                                getRowId={(row) => row._id}
                                sx={{
                                    minHeight: 500, width: "100%",


                                    '& .MuiDataGrid-cell': {
                                        fontFamily: 'Poppins',
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        color: "#58595B"
                                    },
                                    '& .MuiDataGrid-colCell, & .MuiDataGrid-colCellTitle': {
                                        padding: '0px', // Adjust this value as needed
                                    },
                                    '& .MuiDataGrid-columnHeaderCheckbox': {
                                        backgroundColor: Colors.black, // Change this to your desired checkbox background color
                                        color: 'white', // Change this to your desired checkbox color
                                    },
                                    '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root': {
                                        color: "gray"
                                    }
                                    
                                    ,
                                    '& .MuiDataGrid-columnHeader': {
                                        fontFamily: "Poppins",
                                        // color: "black",
                                        fontWeight: "bold",
                                    },

                                }}
                            />
                        </div>

                    </Box>

                    <CustomModal
                        heading={`Delete Employee`}
                        loading={loading}
                        text={"Are you sure you want to delete this supplier?"}
                        open={openDeleteModal}
                        closeModal={() => setOpenDeleteModal(false)}
                        confirm={handleConfirmDeleteEmployee}
                    />

                    {openPayModal && <SupplierPayModal
                        heading={`Make Payment`}
                        loading={paymentLoading}
                        paymentMethod={paymentMethod}
                        reason={paymentReason}
                        setReason={setPaymentReason}
                        selectedPaymentMethod={selectedPaymentMethod}
                        setSelectedPaymentMethod={setSelectedPaymentMethod}
                        onChange={(e) => setAmountPaid(e.target.value)}
                        credit={true}
                        placeholder={"Enter Amount"}
                        text={"Are you sure you want to make payment to supplier?"}
                        open={openPayModal}
                        closeModal={() => setOpenPayModal(false)}
                        confirm={() => handleConfirmMakePayment()}
                    />}


                    {
                        openEditSupplierModal && <EditSupplierModal getAllSuppliers={getAllSuppliers} setOpenEditSupplierModal={setOpenEditSupplierModal} open={openEditSupplierModal} data={dataClicked} closeModal={() => setOpenEditSupplierModal(false)} />
                    }

                    {
                        openAddSupplierModal && <AddSupplierModal getAllSuppliers={getAllSuppliers} setOpenAddSupplierModal={setOpenAddSupplierModal} open={openAddSupplierModal} closeModal={() => setOpenAddSupplierModal(false)} />
                    }


                    {openLedgerModal && <SupplierLedgerModal data={supplierClicked} open={openLedgerModal} closeModal={() => setOpenLedgerModal(false)} />}


                </Box>
    )
}

export default Suppliers
