import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateColors } from "../../Redux/LoginReduces";
import CustomButton from "../../Components/Button";
import axios from "axios";
import { Base_Uri } from "../../Constant/BaseUri";
import { DataGrid } from "@mui/x-data-grid"
import { Colors } from "../../Constant/Color";
import CustomModal from "../../Components/modal";




function ProductColor() {


    const dispatch = useDispatch()

    const colors = useSelector(state => state.loginReducer.colors)

    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [loading, setLoading] = useState(false)

    const [openEditModal, setOpenEditModal] = useState(false)
    const [colorName, setColorName] = useState("")



    const [colorClicked, setColorClicked] = useState(false)


    const getColors = async () => {

        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;

        axios
            .get(`${Base_Uri}getColors`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json", // Include other headers as needed
                },
            })
            .then((res) => {
                let data = res.data;

                if (data.status) {
                    dispatch(updateColors(data.data));
                }
            })
            .catch((error) => {
                console.log(error, "error");
            });


    }



    useEffect(() => {
        getColors()
    }, [])






    const columns = [



        {
            field: "_id",
            headerName: "ID",
            width: 250,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: "colorName",
            headerName: "Color",
            width: window.innerWidth - 600,
            headerClassName: 'super-app-theme--header',
        },

        {
            field: "action",
            headerName: "Action",
            width: 300,
            headerClassName: 'super-app-theme--header',
            renderCell: (cellValues) => {
                return (

                    <Box style={{ display: "flex" }} >
                        <CustomButton onClick={() => handleopenEditColorModal(cellValues?.row)} style={{ fontSize: "12px", padding: 1, borderRadius: 1, backgroundColor: "green", marginRight: "10px" }} text="Edit Color" />
                        <CustomButton onClick={() => handleopenDeleteColorModal(cellValues?.row)} style={{ fontSize: "12px", padding: 1, borderRadius: 1, backgroundColor: Colors.red }} text="Delete Color" />
                    </Box>

                )

            }

        },


    ]



    const handleopenDeleteColorModal = (color) => {


        setColorClicked(color)
        setOpenDeleteModal(true)


    }

    const handleopenEditColorModal = (color) => {


        setColorClicked(color)
        setOpenEditModal(true)

    }

    const handleDeleteColor = async () => {

        setLoading(true)


        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;

        axios
            .delete(`${Base_Uri}deleteColor/${colorClicked?._id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json", // Include other headers as needed
                },
            })
            .then((res) => {
                let data = res.data;

                if (!data?.status) {

                    alert(data?.message)
                    setLoading(false)
                    return

                }

                if (data.status) {


                    let allColors = [...colors]
                    alert(data?.message)
                    let otherColors = allColors?.filter((e, i) => e?._id !== colorClicked?._id)
                    dispatch(updateColors(otherColors));
                    setOpenDeleteModal(false)
                    setLoading(false)
                }
            })
            .catch((error) => {
                alert("Internal Server Error")
                setLoading(false)
                console.log(error, "error");
            });
    }

    const handleEditColor = async () => {


        if (!colorName) {
            alert("Kindly Enter Color Name")
            return
        }

        let dataToSend = {
            id: colorClicked?._id,
            color: colorName
        }


        setLoading(true)


        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;

        axios
            .put(`${Base_Uri}editColor`, dataToSend, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json", // Include other headers as needed
                },
            })
            .then((res) => {
                let data = res.data;

                if (!data?.status) {

                    alert(data?.message)
                    setLoading(false)
                    return

                }

                if (data.status) {


                    let allColors = [...colors]
                    let otherColors = allColors?.filter((e, i) => e?._id !== colorClicked?._id)
                    let newColor = { ...colorClicked }
                    newColor.colorName = colorName
                    let colorToDispatch = [...otherColors, newColor]

                    dispatch(updateColors(colorToDispatch));
                    alert(data?.message)
                    setOpenEditModal(false)
                    setLoading(false)
                }
            })
            .catch((error) => {
                alert("Internal Server Error")
                setLoading(false)
                console.log(error, "error");
            });




    }


    return (
        <Box>


            <Box sx={{ height: "500px", backgroundColor: "white", marginTop: 5 }} >
                <DataGrid getRowId={(row) => row.colorName} sx={{

                    '& .MuiDataGrid-cell': {
                        fontFamily: 'Poppins',
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#58595B"
                    },
                    '& .MuiDataGrid-colCell, & .MuiDataGrid-colCellTitle': {
                        padding: '0px', // Adjust this value as needed
                    },
                    '& .MuiDataGrid-columnHeaderCheckbox': {
                        backgroundColor: Colors.black, // Change this to your desired checkbox background color
                        color: 'white', // Change this to your desired checkbox color
                    },

                    '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root': {
                        color: "gray"
                    }
                    ,
                    '& .MuiDataGrid-columnHeader': {
                        fontFamily: "Poppins",
                        // color: "black",
                        fontWeight: "bold",
                    },


                }}
                    rows={colors && colors.length > 0 ? colors : []} columns={columns} />

            </Box>


            {openDeleteModal && (
                <CustomModal
                    open={openDeleteModal}
                    closeModal={() => setOpenDeleteModal(false)}
                    heading={"Delete Color"}
                    loading={loading}
                    confirm={() => handleDeleteColor()}
                    text={"Are your sure you want to delete this color."}
                />
            )}

            {openEditModal && (
                <CustomModal
                    open={openEditModal}
                    closeModal={() => setOpenEditModal(false)}
                    heading={"Edit Color"}
                    loading={loading}
                    confirm={() => handleEditColor()}
                    credit={true}
                    onChange={(e) => setColorName(e?.target?.value)}
                    placeholder={"Enter Color Name"}
                    text={"Are your sure you want to Edit this color."}
                />
            )}

        </Box>
    )
}


export default ProductColor