import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Colors } from '../../../../Constant/Color'
import CustomButton from '../../../../Components/Button'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import BasicSelect from '../../../../Components/Dropdown';
import { DataGrid } from "@mui/x-data-grid"
import Checkbox from '../../../../Components/checkbox';
import { Category, CheckBox } from '@mui/icons-material';
import { Navigate, useNavigate } from 'react-router-dom';
import { RingLoader } from "react-spinners";
import axios, { all } from 'axios';
import { Base_Uri, Image_Uri } from '../../../../Constant/BaseUri';
import { useDispatch, useSelector } from 'react-redux';
import { updateAllExchangeInvoice, updateAllInvoices, updateAllReturnInvoices, updateDayReturnInvoiceEmp, updateInvoice, updateProducts, updatetodayEmployeeExchangeInvoice, viewSelectedInvoice } from '../../../../Redux/LoginReduces';
import Navbar from '../../../../Components/Navbar';
import ViewInvoiceDetailModal from '../../../../Components/viewInvoiceDetailModal';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import DownloadIcon from "@mui/icons-material/Download";
import PaymentMethodModal from '../../../../Components/paymentMethodModal';
import CustomModal from '../../../../Components/modal';
import OnlineTransactionModal from '../../../../Components/onlineTransactionModal';
import ChequeModal from '../../../../Components/CheckModal';
import DatePickers from '../../../../Components/DatePicker';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  Document,
  Page,
  Text,
  View,
  PDFViewer,
  PDFDownloadLink,
  Image,
  Font,
} from "@react-pdf/renderer";
import MyFontRegular from "../../../../fonts/Poppins-Medium.ttf";
import MyFontBold from "../../../../fonts/Poppins-Bold.ttf";
import useScanDetection from 'use-scan-detection';



function TodayInvoices() {


  Font.register({
    family: "MyFont",
    fonts: [
      { src: MyFontBold, fontWeight: "bold" },
      { src: MyFontRegular, fontWeight: "normal" },
    ],
  });






  const navigate = useNavigate()
  const dispatch = useDispatch()



  const paymentMethod = useSelector(
    (state) => state.loginReducer.paymentMethod
  );

  const products = useSelector(state => state.loginReducer.products)
  const userData = useSelector((state) => state.loginReducer.updatedState);
  const vat = useSelector((state) => state.loginReducer.vat)


  let returnInvoices = useSelector(
    (state) => state.loginReducer.dayReturnInvoiceOfEmployee
  );
  let todayInvoices = useSelector((state) => state.loginReducer.invoices);
  let exchangeInvoice = useSelector((state => state.loginReducer?.todayExchangeEmployeeInvoices))
  let allInvoice = useSelector(state => state.loginReducer.allInvoices)

  allInvoice = allInvoice && allInvoice.length > 0 ? allInvoice : []
  let allInvoices = [...allInvoice]



  allInvoices = allInvoices && allInvoices?.length > 0 && allInvoices?.sort((a, b) => new Date(b?.saleDate).getTime() - new Date(a?.saleDate).getTime())


  let allReturnInvoice = useSelector(state => state.loginReducer.allReturnInvoices)
  allReturnInvoice = allReturnInvoice && allReturnInvoice.length > 0 ? allReturnInvoice : []
  let allReturnInvoices = [...allReturnInvoice]
  allReturnInvoices = allReturnInvoices && allReturnInvoices.length > 0 && allReturnInvoices?.sort((a, b) => new Date(b?.saleReturnDate).getTime() - new Date(a?.saleReturnDate).getTime())


  let allExchangeInvoice = useSelector(state => state.loginReducer.allExchangeInvoice)

  allExchangeInvoice = allExchangeInvoice && allExchangeInvoice.length > 0 ? allExchangeInvoice : []

  let allExchangeInvoices = [...allExchangeInvoice]

  allExchangeInvoices = allExchangeInvoices && allExchangeInvoices.length > 0 && allExchangeInvoices?.sort((a, b) => new Date(b?.exchangeDate).getTime() - new Date(a?.exchangeDate).getTime())


  const [byInvoiceNumber, setByInvoiceNumber] = useState({
    selected: false
  })

  const [byCustomerName, setByCustomerName] = useState({
    selected: false
  })

  const [byIMEI, setByIMEI] = useState({
    selected: false
  })


  const [productCategory, setProductCategory] = useState([
    'Show All', 'Sale Invoices', 'Return Invoices', 'Exchange Invoices'
  ])


  allInvoice = allInvoice && allInvoice.length > 0 ? allInvoice : []
  allReturnInvoices = allReturnInvoices && allReturnInvoices.length > 0 ? allReturnInvoices : []
  allExchangeInvoices = allExchangeInvoices && allExchangeInvoices.length > 0 ? allExchangeInvoices : []


  todayInvoices = todayInvoices && todayInvoices.length > 0 ? todayInvoices : []
  returnInvoices = returnInvoices && returnInvoices.length > 0 ? returnInvoices : []
  exchangeInvoice = exchangeInvoice && exchangeInvoice.length > 0 ? exchangeInvoice : []

  let showAllInvoices = [...allInvoice, ...allReturnInvoices, ...allExchangeInvoices]


  showAllInvoices = showAllInvoices && showAllInvoices.length > 0 ? showAllInvoices : []


  if (showAllInvoices && showAllInvoices.length > 0) {
    showAllInvoices = showAllInvoices.sort((a, b) => {
      const dateA = a.exchangeDate ? new Date(a.exchangeDate) :
        a.saleDate ? new Date(a.saleDate) :
          a.saleReturnDate ? new Date(a.saleReturnDate) : null;

      const dateB = b.exchangeDate ? new Date(b.exchangeDate) :
        b.saleDate ? new Date(b.saleDate) :
          b.saleReturnDate ? new Date(b.saleReturnDate) : null;

      if (dateA && dateB) {
        return dateB.getTime() - dateA.getTime();
      } else if (dateA) {
        return -1;
      } else if (dateB) {
        return 1;
      } else {
        return 0;
      }
    });
  }






  let todayAllInvoices = [...todayInvoices, ...returnInvoices, ...exchangeInvoice]

  todayAllInvoices = todayAllInvoices.length > 0 ? todayAllInvoices : []

  if (todayAllInvoices && todayAllInvoices.length > 0) {
    todayAllInvoices = todayAllInvoices.sort((a, b) => {
      const dateA = a.exchangeDate ? new Date(a.exchangeDate) :
        a.saleDate ? new Date(a.saleDate) :
          a.saleReturnDate ? new Date(a.saleReturnDate) : null;

      const dateB = b.exchangeDate ? new Date(b.exchangeDate) :
        b.saleDate ? new Date(b.saleDate) :
          b.saleReturnDate ? new Date(b.saleReturnDate) : null;

      if (dateA && dateB) {
        return dateB.getTime() - dateA.getTime();
      } else if (dateA) {
        return -1;
      } else if (dateB) {
        return 1;
      } else {
        return 0;
      }
    });
  }


  const [searchInvoices, setSearchInvoices] = useState([])
  const [searchReturnInvoices, setSearchReturnInvoices] = useState([])
  const [searchExchangeInvoices, setSearchExchangeInvoices] = useState([])
  const [searchAllInvoices, setSearchAllInvoices] = useState([])
  const [pdfDownloadView, setPdfDownloadView] = useState(false);
  const [dateSearchAllInvoices, setDateSearchAllInvoices] = useState([])
  const [dateSearchInvoices, setDateSearchInvoices] = useState([])
  const [dateSearchReturnInvoices, setDateSearchReturnInvoices] = useState([])
  const [dateSearchExchangeInvoices, setDateSearchExchangeInvoices] = useState([])
  const [paymentDetails, setPaymentDetails] = useState("")
  const [printInvoiceData, setPrintInvoiceData] = useState("")
  const [searchDate, setSearchDate] = useState({
    selected: false
  })
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [gridKey, setGridKey] = useState(0); // Add this state variable
  const [product, setProducts] = useState([])
  const [showSaleInvoices, setShowSaleInvoices] = useState({
    invoices: true,
    returnInvoices: false,
    claimInvoices: false,
    warrantyInvoices: false,
  });
  const [searchCategory, setSearchCategory] = useState([])
  const [loading, setLoading] = useState(false)
  const [invoiceDetailModal, setInvoiceDetailModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("Show All")
  const [paymentMethodModal, setPaymentMethodModal] = useState(false)
  const [checkOutModal, setCheckOutModal] = useState(false);
  const [onlineModalVisible, setOnlineModalVisible] = useState(false);
  const [chequeModalVisible, setChequeModalVisible] = useState(false);
  const [creditAccountModal, setCreditAccountModal] = useState(false);
  const [cardModalVisible, setCardModalVisible] = useState(false);
  const [barcodeRead, setBarcodeRead] = useState("")
  const [creditDays, setCreditDays] = useState("")
  const [transactionId, setTransactionId] = useState("")
  const [onlineReferenceId, setOnlineReferenceId] = useState("")
  const [chequeDetails, setChequeDetails] = useState({
    chequeNo: "",
    bankName: "",
    day: "",
    month: "",
    year: "",
  });
  const [clickedData, setClickedData] = useState("")
  const getTodayInvoices = async () => {
    if (userData && userData.id) {
      let data = await localStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      axios
        .get(`${Base_Uri}getInvoices/${userData.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          if (!res?.status) {
            alert(res.message);
            return;
          }
          let { data } = res.data;

          data =
            data &&
            data.length > 0 &&
            data.map((e, i) => {
              return {
                ...e,
                id: e._id,
              };
            });
          data =
            data &&
            data.length > 0 &&
            data.sort(
              (a, b) =>
                new Date(b.saleDate).getTime() - new Date(a.saleDate).getTime()
            );

          dispatch(updateInvoice(data && data.length > 0 ? data : []));
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  };


  const getTodayReturnInvoices = async () => {
    if (userData) {
      let data = await localStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      axios
        .get(`${Base_Uri}getReturnInvoices/${userData.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {

          if (!res?.status) {
            alert(res.message);
            return;
          }

          let { data } = res.data;

          data =
            data &&
            data.length > 0 &&
            data.map((e, i) => {
              return {
                ...e,
                id: e._id,
              };
            });

          dispatch(
            updateDayReturnInvoiceEmp(data && data.length > 0 ? data : [])
          );
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  };


  const getTodayExchangeInvoices = async () => {

    if (userData) {

      let data = await localStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      axios.get(`${Base_Uri}getExchangeInvoices/${userData.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      }).then((res) => {


        if (!res?.status) {
          alert(res.message)
          return
        }

        let { data } = res.data

        data = data && data.length > 0 && data.map((e, i) => {
          return {
            ...e,
            id: e._id
          }
        })

        dispatch(updatetodayEmployeeExchangeInvoice(data && data.length > 0 ? data : []))

      }).catch((error) => {

        console.log(error, "error")

      })
    }
  }

  const getAllInvoices = async () => {


    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios.get(`${Base_Uri}getAllInvoices`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Include other headers as needed
      },
    }).then((res) => {

      if (!res?.status) {
        alert(res.message)
        return
      }

      let { data } = res.data
      data = data && data.length > 0 && data.map((e, i) => {
        return {
          ...e,
          id: e._id
        }
      })



      dispatch(updateAllInvoices(data && data.length > 0 ? data : []))


    }).catch((error) => {

      console.log(error, "error")

    })


  }

  const getAllExchangeInvoices = async () => {


    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios.get(`${Base_Uri}getAllExchangeInvoices`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Include other headers as needed
      },
    }).then((res) => {

      if (!res?.status) {
        alert(res.message)
        return
      }

      let { data } = res.data
      data = data && data.length > 0 && data.map((e, i) => {
        return {
          ...e,
          id: e._id
        }
      })


      dispatch(updateAllExchangeInvoice(data && data.length > 0 ? data : []))


    }).catch((error) => {

      console.log(error, "error")

    })


  }

  const getAllReturnInvoices = async () => {


    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios.get(`${Base_Uri}getAllReturnInvoices`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Include other headers as needed
      },
    }).then((res) => {

      if (!res?.status) {
        alert(res.message)
        return
      }

      let { data } = res.data
      data = data && data.length > 0 && data.map((e, i) => {
        return {
          ...e,
          id: e._id
        }
      })


      dispatch(updateAllReturnInvoices(data && data.length > 0 ? data : []))


    }).catch((error) => {

      console.log(error, "error")

    })


  }

  useEffect(() => {

    setProducts(products)

    if (userData?.id) {


      getTodayInvoices()
      getTodayExchangeInvoices()
      getTodayReturnInvoices()
      getAllInvoices()
      getAllExchangeInvoices()
      getAllReturnInvoices()
    }
  }, [products.length, userData?.id])


  const handleViewInvoiceDetails = (invoice) => {
    dispatch(viewSelectedInvoice(invoice));
    setInvoiceDetailModal(true);
  };

  const selectByInvoiceNumber = () => {

    if (!byInvoiceNumber.selected) {
      setByCustomerName({
        ...byCustomerName,
        selected: false
      })
      setByIMEI({
        ...byIMEI,
        selected: false
      })
    }

    setByInvoiceNumber({
      ...byInvoiceNumber,
      selected: !byInvoiceNumber?.selected
    })
  }


  const selectByIMEINumber = () => {

    if (!byIMEI.selected) {
      setByCustomerName({
        ...byCustomerName,
        selected: false
      })
      setByInvoiceNumber({
        ...byInvoiceNumber,
        selected: false
      })
    }

    setByIMEI({
      ...byIMEI,
      selected: !byIMEI?.selected
    })
  }


  const selectByCustomerName = () => {



    if (!byCustomerName.selected) {
      setByInvoiceNumber({
        ...byInvoiceNumber,
        selected: false
      })
      setByIMEI({
        ...byIMEI,
        selected: false
      })
    }

    setByCustomerName({
      ...byCustomerName,
      selected: !byCustomerName?.selected
    })
  }

  const handleOpenPaymentMethod = (invoiceData) => {

    setClickedData(invoiceData)

    setPaymentMethodModal(true)






  }

  const handleGeneratePdf = (invoice) => {

    setPdfDownloadView(true)
    setPrintInvoiceData(invoice)

  }

  const columns = [
    {
      field: "invoiceNumber",
      headerName: "Invoice",
      width: 200,
      filterable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: (cellValues) => {
        return (
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "30%",

              backgroundColor: Colors.white,
            }}
          >
            {`${cellValues?.row?.saleDate ? (cellValues?.row?.vatAmount ? "invoice" : "quotation") : cellValues?.row?.saleReturnDate ? "Return" : "Exchange"} no. ${cellValues?.row?.invoiceNumber}`}
          </Box>
        );
      },
    },
    {
      field: "totalItems",
      headerName: "Total Items",
      width: 150,
      filterable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: "totalQty",
      headerName: "Total Quantity",
      width: 150,
      filterable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: "subtotal",
      headerName: "Amount",
      width: 150,
      filterable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: (cellValues) => {
        return <p>{Number(cellValues?.row?.subtotal).toFixed(2)}</p>
      }
    },
    {
      field: "businessName",
      headerName: "Customer",
      headerClassName: 'super-app-theme--header',
      width: 250,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "30%",

              backgroundColor: Colors.white,
            }}
          >
            {`${cellValues?.row?.customerDetails?.[0]?.businessName}`}
          </Box>
        );
      },
    },
    {
      field: selectedCategory == "Sale Invoices"
        ? "saleDate"
        : selectedCategory == "Return Invoices" ? "saleReturnDate" : "exchangeDate"
      ,
      headerName: "Date",
      headerClassName: 'super-app-theme--header',
      width: 150,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "30%",
              backgroundColor: Colors.white,
            }}
          >
            {`${cellValues?.row?.saleDate ? new Date(cellValues?.row?.saleDate)?.toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            }) : cellValues?.row?.saleReturnDate ?
              new Date(cellValues?.row?.saleReturnDate)?.toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              }) : new Date(cellValues?.row?.exchangeDate)?.toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })

              }`}
          </Box>
        );
      }
    },
    {
      field: "paymentMethod",
      headerName: "Payment Method",
      headerClassName: 'super-app-theme--header',
      width: 200,
      filterable: false,
      // color: "red",
      renderCell: (cellValues) => {
        const paymentMethod = cellValues?.row?.paymentMethod;
        let color;

        if (Array.isArray(paymentMethod)) {
          if (paymentMethod.some(e => e?.toLowerCase() === "on account")) {
            color = "purple";
          } else if (paymentMethod.some(e => e?.toLowerCase() === "credit note" || e?.toLowerCase() === "refund cash" || e?.toLowerCase() == "refund card")) {
            color = Colors.red;
          } else {
            color = Colors.green;
          }
        } else {
          if (paymentMethod?.toLowerCase() === "on account") {
            color = "purple";
          } else if (paymentMethod?.toLowerCase() === "credit note" || paymentMethod?.toLowerCase() === "refund cash" || paymentMethod?.toLowerCase() == "refund card") {
            color = Colors.red;
          } else {
            color = Colors.green;
          }
        }

        return (
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "30%",
              color: color,
              backgroundColor: Colors.white,
            }}
          >
            {Array.isArray(paymentMethod) ? paymentMethod.join(",") : paymentMethod}
          </Box>
        );
      }

      // },
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: 'super-app-theme--header',
      width: 150,
      filterable: false,
      color: "green",
    },
    {
      field: "action",
      headerName: "Actions",
      width: 400,
      headerClassName: 'super-app-theme--header',
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>

            {(cellValues?.row?.status?.toLowerCase() == "sale" && userData?.role == "admin") && <Button
              onClick={() => handleOpenPaymentMethod(cellValues?.row)}
              style={{
                display: "flex",
                width: "250px",
                fontFamily: "Poppins",
                // border:"1px solid red",
                fontSize: "12px",
                marginRight: 10,
                fontWeight: "bold",
                color: Colors.red,
                backgroundColor: Colors.white,
              }}
            >
              Update Payment Method
            </Button>}


            <Button
              onClick={() => handleViewInvoiceDetails(cellValues.row)}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "50%",
                fontFamily: "Poppins",
                fontSize: "12px",
                backgroundColor: Colors.white,
              }}
            >
              View Details
            </Button>

            <Button
              onClick={() => handleGeneratePdf(cellValues?.row)}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginLeft: "10px",
                width: "50%",
                backgroundColor: Colors.white,
              }}
            >
              <DownloadIcon />
            </Button>
          </Box>
        );
      },
    },
  ];


  const Header = () => {
    return (
      <View fixed>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 10,
            alignItems: "flex-start",
          }}
        >
          <View>
            {printInvoiceData?.vatAmount && (
              <View>
                <View>
                  <Image
                    style={{ height: 50, borderRadius: 10 }}
                    source={{
                      uri: `${Image_Uri}companyImage/${vat?.companyLogo}`,
                    }}
                  />

                  <Text
                    style={{
                      color: "black",
                      fontSize: "14px",
                      marginTop: "10px",
                      fontFamily: "MyFont",
                      fontWeight: "bold",
                    }}
                  >
                    {vat?.companyName}
                  </Text>
                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      fontWeight: "normal",
                    }}
                  >
                    {vat?.companyAddress}
                  </Text>

                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      fontWeight: "normal",
                    }}
                  >{vat?.city}</Text>

                  <Text

                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      fontWeight: "normal",
                    }}
                  >{vat?.postCode}</Text>
                </View>
                <View>
                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      marginTop: "3px",
                      fontFamily: "MyFont",
                      fontWeight: "normal",
                    }}
                  >
                    Tel: {vat?.mobileNumber}
                  </Text>
                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      fontWeight: "normal",
                    }}
                  >
                    Email: {vat?.companyEmail}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      fontWeight: "normal",
                    }}
                  >
                    VAT No: {vat?.vatNumber}
                  </Text>
                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      fontWeight: "normal",
                    }}
                  >
                    Company No: {vat?.companyNumber}
                  </Text>

                </View>
              </View>
            )}
          </View>


          <View>
            <View style={{ alignItems: "center" }} >
              <Image
                src={`${Image_Uri}products/${printInvoiceData?.barcodeImagePath}`}
                style={{
                  height: "35px",
                  width: "150px"
                }}
              />
            </View>

            <View style={{ alignItems: "center", marginTop: "10px" }}>
              <Text
                style={{
                  color: "black",
                  fontSize: "16px",
                  fontFamily: "MyFont",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {printInvoiceData?.vatAmount ? "Invoice" : "Quotation"}
              </Text>
            </View>

            <View>
              {printInvoiceData?.vatAmount && <Text
                style={{
                  color: "black",
                  fontSize: "10px",
                  fontFamily: "MyFont",
                  marginTop: "10px",
                  fontWeight: "normal",
                  textAlign: "center",
                }}
              >
                Invoice no: {printInvoiceData?.invoiceNumber}
              </Text>}

              <Text
                style={{
                  color: "black",
                  fontSize: "10px",
                  fontFamily: "MyFont",
                  // marginTop:"10px",
                  fontWeight: "normal",
                  textAlign: "center",
                }}
              >
                Date:{" "}
                {new Date(
                  printInvoiceData?.exchangeDate
                    ? printInvoiceData?.exchangeDate
                    : printInvoiceData.saleReturnDate ? printInvoiceData.saleReturnDate : printInvoiceData.saleDate
                ).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
              </Text>

              <Text
                style={{
                  color: "black",
                  fontSize: "10px",
                  fontFamily: "MyFont",
                  fontWeight: "normal",
                  textAlign: "center",
                }}
              >
                Time:{" "}
                {new Date(
                  printInvoiceData?.exchangeDate
                    ? printInvoiceData?.exchangeDate
                    : printInvoiceData.saleReturnDate ? printInvoiceData.saleReturnDate : printInvoiceData.saleDate
                ).toLocaleTimeString()}
              </Text>

              {(printInvoiceData?.saleReturnDate || printInvoiceData?.exchangeDate) && printInvoiceData?.vatAmount && <View style={{ marginTop: "10px" }} >

                <Text
                  style={{
                    color: "black",
                    fontSize: "10px",
                    fontFamily: "MyFont",
                    // marginTop:"10px",
                    fontWeight: "normal",
                    textAlign: "center",
                  }}
                >
                  Return Inv Ref.:

                  {printInvoiceData?.returnInvoiceRef?.map((e, i) => {
                    return (

                      <Text
                        style={{
                          color: "black",
                          fontSize: "8px",
                          fontFamily: "MyFont",
                          // marginTop:"10px",
                          fontWeight: "normal",
                          textAlign: "center",
                        }}
                      >
                        {e},
                      </Text>
                    )
                  })}


                </Text>





              </View>}


              <View style={{ marginTop: "10px" }}>
                <Text
                  style={{
                    color: "black",
                    fontSize: "12px",
                    fontFamily: "MyFont",
                    // marginTop:"10px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Bill to:
                </Text>

                <Text
                  style={{
                    color: "black",
                    fontSize: "10px",
                    fontFamily: "MyFont",
                    // marginTop:"10px",
                    fontWeight: "normal",
                    textAlign: "center",
                  }}
                >
                  Account No: {printInvoiceData?.customerDetails?.[0]?.accountNo}
                </Text>

                {printInvoiceData?.vatAmount && <Text
                  style={{
                    color: "black",
                    fontSize: "10px",
                    fontFamily: "MyFont",
                    // marginTop:"10px",
                    fontWeight: "normal",
                    textAlign: "center",
                  }}
                >
                  Business Name: {printInvoiceData?.customerDetails?.[0]?.businessName}
                </Text>}

                {printInvoiceData?.vatAmount && <Text
                  style={{
                    color: "black",
                    fontSize: "10px",
                    fontFamily: "MyFont",
                    // marginTop:"10px",
                    fontWeight: "normal",
                    textAlign: "center",
                  }}
                >
                  Email: {printInvoiceData?.customerDetails?.[0]?.email}
                </Text>}

                {printInvoiceData?.vatAmount && <Text
                  style={{
                    color: "black",
                    fontSize: "10px",
                    fontFamily: "MyFont",
                    marginTop: "10px",
                    fontWeight: "normal",
                    textAlign: "center",
                  }}
                >
                  Payment Method:{" "}
                  {Array?.isArray(printInvoiceData?.paymentMethod)
                    ? printInvoiceData?.paymentMethod
                      ?.map((e, i) => e)
                      .join(",")
                    : printInvoiceData?.paymentMethod}
                </Text>}

                {printInvoiceData?.vatAmount && <Text
                  style={{
                    color: "black",
                    fontSize: "10px",
                    fontFamily: "MyFont",
                    marginTop: "10px",
                    fontWeight: "normal",
                    textAlign: "center",
                  }}
                >
                  Status: {printInvoiceData?.status}
                </Text>}


                {/* {printInvoiceData?.creditDays && (
                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      marginTop: "10px",
                      fontWeight: "normal",
                      textAlign: "center",
                    }}
                  >
                    Credit Days: {printInvoiceData?.creditDays}
                  </Text>
                )} */}

                {/* {printInvoiceData?.referenceId && (
                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      // marginTop: "10px",
                      fontWeight: "normal",
                      textAlign: "center",
                    }}
                  >
                    Reference Id: {printInvoiceData?.referenceId}
                  </Text>
                )} */}

                {printInvoiceData?.transactionId && printInvoiceData?.vatAmount && (
                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      // marginTop: "10px",
                      fontWeight: "normal",
                      textAlign: "center",
                    }}
                  >
                    Transaction Id: {printInvoiceData?.transactionId}
                  </Text>
                )}

                {printInvoiceData?.vatAmount && Array?.isArray(printInvoiceData?.paymentMethod) &&
                  printInvoiceData?.paymentMethod?.some(
                    (e) => e?.toLowerCase() == "cheque"
                  ) && (
                    <View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: "10px",
                          fontFamily: "MyFont",
                          // marginTop:"10px",
                          fontWeight: "normal",
                          textAlign: "center",
                        }}
                      >
                        Cheque No: {printInvoiceData?.cheque_no}
                      </Text>

                      <Text
                        style={{
                          color: "black",
                          fontSize: "10px",
                          fontFamily: "MyFont",
                          // marginTop:"10px",
                          fontWeight: "normal",
                          textAlign: "center",
                        }}
                      >
                        Bank Name: {printInvoiceData?.bank_name}
                      </Text>

                      <Text
                        style={{
                          color: "black",
                          fontSize: "10px",
                          fontFamily: "MyFont",
                          // marginTop:"10px",
                          fontWeight: "normal",
                          textAlign: "center",
                        }}
                      >
                        Cheque Date:{" "}
                        {new Date(
                          printInvoiceData.clear_date
                        ).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })}
                      </Text>
                    </View>
                  )}
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            borderBottomWidth: 1,
            borderColor: "#000",
            paddingBottom: 5,
            paddingTop: 5,
            border: "1px solid black",
            backgroundColor: "gray",
            alignItems: "center",
            padding: 10,
            marginTop: "20px",
          }}
        >
          <View style={{ flex: 0.5 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Item
            </Text>
          </View>

          <View style={{ flex: 1.5 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Barcode
            </Text>
          </View>

          <View style={{ flex: 4 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Product Description
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Qty
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Price
            </Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Disc
            </Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Amount
            </Text>
          </View>
        </View>
      </View>
    );
  };


  useScanDetection({
    onComplete: (code) => {
      setBarcodeRead(code);
    },
    minLength: 3,
  });


  const MyDocument = () => (
    <Document size="A4">
      {/* <Header /> */}

      <Page style={{ padding: "0px 10px" }}>
        <Header />
        {/* Data Rows */}
        <View>
          {printInvoiceData &&
            printInvoiceData?.productDetails &&
            printInvoiceData?.productDetails?.length > 0 &&
            printInvoiceData?.productDetails?.map((item, index) => (
              <View
                key={index}
                style={{
                  flexDirection: "row",
                  borderBottomWidth: 1,
                  borderColor: "#000",
                  borderLeftWidth: 1,
                  borderRightWidth: 1,
                  padding: "3px 10px",
                }}
              >
                <View style={{ flex: 0.5 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}> {printInvoiceData?.saleReturnDate ? "-1" : "1"} </Text>
                </View>

                <View style={{ flex: 1.5 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    {item.barcode}
                  </Text>
                </View>

                <View style={{ flex: 4 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    {item.productName}
                  </Text>
                  <View>
                    {item?.IMEI && item?.IMEI?.length > 0 &&
                      item?.IMEI?.map((e, i) => {
                        return (
                          <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                            {e}
                          </Text>
                        );
                      })}
                  </View>
                </View>

                <View style={{ flex: 1 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    {printInvoiceData?.saleReturnDate && "-"} {item?.DamageQty ? item?.DamageQty : item?.saleQty}
                  </Text>
                </View>

                <View style={{ flex: 1 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    £
                    {printInvoiceData?.saleReturnDate && "-"}

                    {item?.discountPrice > (printInvoiceData?.customerDetails[0]?.priceLevel[0]?.id ==
                      1 ? item?.trade_price : printInvoiceData?.customerDetails[0]?.priceLevel[0]?.id ==
                        2 ? item?.warehouse_price : item?.retail_price) ? item?.discountPrice : printInvoiceData?.customerDetails[0]?.priceLevel[0]?.id ==
                          1
                      ? Number(item.trade_price).toFixed(2)
                      : printInvoiceData?.customerDetails[0]?.priceLevel[0]
                        ?.id == 2
                        ? Number(item.warehouse_price).toFixed(2)
                        : Number(item.retail_price).toFixed(2)}
                  </Text>
                </View>

                <View style={{ flex: 1 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    £
                    {(item.discountPrice < (printInvoiceData?.customerDetails[0]?.priceLevel[0]?.id ==
                      1 ? item?.trade_price : printInvoiceData?.customerDetails[0]?.priceLevel[0]?.id ==
                        2 ? item?.warehouse_price : item?.retail_price))
                      ? Number(
                        (printInvoiceData?.customerDetails[0]?.priceLevel[0]
                          ?.id == 1
                          ? item.trade_price
                          : printInvoiceData?.customerDetails[0]
                            ?.priceLevel[0]?.id == 2
                            ? item.warehouse_price
                            : item.retail_price) - item.discountPrice
                      ).toFixed(2)
                      : 0}
                  </Text>
                </View>

                <View style={{ flex: 1 }}>
                  <Text
                    style={{
                      fontFamily: "MyFont",
                      fontSize: 8,
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    £
                    {printInvoiceData?.saleReturnDate && "-"} {item.discountPrice
                      ? Number(item.discountPrice * (item?.DamageQty ? item?.DamageQty : item.saleQty)).toFixed(2)
                      : Number(
                        (printInvoiceData?.customerDetails[0]?.priceLevel[0]
                          ?.id == 1
                          ? item.trade_price
                          : printInvoiceData?.customerDetails[0]
                            ?.priceLevel[0]?.id == 2
                            ? item.warehouse_price
                            : item.retail_price) * (item?.DamageQty ? item?.DamageQty : item.saleQty)
                      ).toFixed(2)}
                  </Text>
                </View>
              </View>
            ))}

          {printInvoiceData &&
            printInvoiceData?.returnProductDetails &&
            printInvoiceData?.returnProductDetails?.length > 0 &&
            printInvoiceData?.returnProductDetails?.map((item, index) => (
              <View
                key={index}
                style={{
                  flexDirection: "row",
                  borderBottomWidth: 1,
                  borderLeftWidth: 1,
                  borderRightWidth: 1,
                  borderColor: "#000",
                  padding: "3px 10px",
                }}
              >
                <View style={{ flex: 0.5 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>-1</Text>
                </View>

                <View style={{ flex: 1.5 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    {item.barcode}
                  </Text>
                </View>

                <View style={{ flex: 4 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    {item.productName}
                  </Text>
                  <View>
                    {item?.IMEI && item?.IMEI?.length > 0 &&
                      item?.IMEI?.map((e, i) => {
                        return (
                          <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                            {e}
                          </Text>
                        );
                      })}
                  </View>
                </View>

                <View style={{ flex: 1 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    -{item?.DamageQty ? item?.DamageQty : item?.saleQty}
                  </Text>
                </View>

                <View style={{ flex: 1 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    £
                    {printInvoiceData?.customerDetails[0]?.priceLevel[0]?.id ==
                      1
                      ? Number(item.trade_price).toFixed(2)
                      : printInvoiceData?.customerDetails[0]?.priceLevel[0]
                        ?.id == 2
                        ? Number(item.warehouse_price).toFixed(2)
                        : Number(item.retail_price).toFixed(2)}
                  </Text>
                </View>

                <View style={{ flex: 1 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    £
                    {item.discountPrice
                      ? Number(
                        (printInvoiceData?.customerDetails[0]?.priceLevel[0]
                          ?.id == 1
                          ? item.trade_price
                          : printInvoiceData?.customerDetails[0]
                            ?.priceLevel[0]?.id == 2
                            ? item.warehouse_price
                            : item.retail_price) - item.discountPrice
                      ).toFixed(2)
                      : 0}
                  </Text>
                </View>

                <View style={{ flex: 1 }}>
                  <Text
                    style={{
                      fontFamily: "MyFont",
                      fontSize: 8,
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    £
                    {item.discountPrice
                      ? Number(
                        item.discountPrice *
                        (item?.DamageQty ? item?.DamageQty : item.saleQty)
                      ).toFixed(2)
                      : Number(
                        (printInvoiceData?.customerDetails[0]?.priceLevel[0]
                          ?.id == 1
                          ? item.trade_price
                          : printInvoiceData?.customerDetails[0]
                            ?.priceLevel[0]?.id == 2
                            ? item.warehouse_price
                            : item.retail_price) *
                        (item?.DamageQty ? item?.DamageQty : item.saleQty)
                      ).toFixed(2)}
                  </Text>
                </View>
              </View>
            ))}

          <View
            // key={index}
            style={{
              flexDirection: "row",
              // borderWidth: 1,
              borderColor: "#000",
              padding: "5px 10px",
              borderLeftWidth: 1,
              borderRightWidth: 1,
            }}
          >
            <View style={{ flex: 0.5, }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {printInvoiceData?.saleReturnDate && "-"}{printInvoiceData?.totalItems}
              </Text>
            </View>

            <View style={{ flex: 5.5, alignItems: "center", }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 10,

                  fontWeight: "bold",
                }}
              >
                Subtotal
              </Text>
            </View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {printInvoiceData?.saleReturnDate && "-"}{printInvoiceData?.totalQty}
              </Text>
            </View>

            <View style={{ flex: 1, }}>

              price

            </View>

            <View style={{ flex: 1, }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                £{Number(printInvoiceData?.discount).toFixed(2)}
              </Text>
            </View>

            <View style={{ flex: 1, }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                £ {printInvoiceData?.saleReturnDate && "-"}{Number(printInvoiceData?.total - printInvoiceData?.discount).toFixed(2)}
              </Text>
            </View>
          </View>


          {printInvoiceData?.vatAmount && <View
            // key={index}
            style={{
              flexDirection: "row",
              // borderWidth: 1,
              borderLeftWidth: 1,
              borderRightWidth: 1,
              borderColor: "#000",
              borderTopWidth: 1,
              padding: "5px 10px",
            }}
          >
            <View style={{ flex: 9, alignItems: "center" }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                Vat
              </Text>
            </View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 10,

                  fontWeight: "bold",
                }}
              >
                £ {printInvoiceData?.saleReturnDate && "-"}{Number(printInvoiceData?.vatAmount).toFixed(2)}
              </Text>
            </View>


          </View>}

          {printInvoiceData?.SDAmount && (
            <View
              // key={index}
              style={{
                flexDirection: "row",
                // borderWidth: 1,
                borderLeftWidth: 1,
                borderRightWidth: 1,
                borderColor: "#000",
                borderTopWidth: 1,
                padding: "5px 10px",
              }}
            >
              <View style={{ flex: 9, alignItems: "center" }}>
                <Text
                  style={{
                    fontFamily: "MyFont",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  Special Discount
                </Text>
              </View>

              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontFamily: "MyFont",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  - £ {printInvoiceData?.saleReturnDate && "-"}{Number(printInvoiceData?.SDAmount).toFixed(2)}

                  {/* - £{Number(printInvoiceData?.SDAmount).toFixed(2)} */}
                </Text>
              </View>
            </View>
          )}


          <View
            // key={index}
            style={{
              flexDirection: "row",
              // borderWidth: 1,
              borderColor: "#000",
              borderWidth: 1,
              padding: "5px 10px",
              // borderBottomWidth:1,
              backgroundColor: "#DDD",
              opacity: 0.7
            }}
          >
            <View style={{ flex: 9, alignItems: "center" }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 12,
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                Total
              </Text>
            </View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 12,
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                £ {printInvoiceData?.saleReturnDate && "-"}{Number(printInvoiceData?.subtotal).toFixed(2)}
              </Text>
            </View>


          </View>


        </View>
      </Page>
    </Document>
  );

  const handleSearchProducts = (e) => {

    let value = e.target.value


    if (!value) {

      setSearchAllInvoices([])
      setSearchInvoices([])
      setSearchExchangeInvoices([])
      setSearchReturnInvoices([])

      // setSearchDate(prevState => ({ ...prevState, selected: !prevState.selected }))
      return
    }





    if (byCustomerName?.selected && selectedCategory.toLowerCase() == "show all") {

      if (userData && userData?.role.toLowerCase() == "sales assistant") {
        if (searchDate?.selected) {

          setSearchAllInvoices(dateSearchAllInvoices && dateSearchAllInvoices.length > 0 && dateSearchAllInvoices.filter((e, i) => {
            return e?.customerDetails[0]?.businessName?.toLowerCase().includes(value.toLowerCase()) ||
              e?.customerDetails[0]?.accountNo == value

          }))

        }
        else {
          setSearchAllInvoices(todayAllInvoices && todayAllInvoices.length > 0 && todayAllInvoices.filter((e, i) => {
            return e?.customerDetails[0]?.businessName?.toLowerCase().includes(value.toLowerCase()) ||
              e?.customerDetails[0]?.accountNo == value

          }))
        }
      }
      else {



        if (searchDate?.selected) {

          setSearchAllInvoices(dateSearchAllInvoices && dateSearchAllInvoices.length > 0 && dateSearchAllInvoices.filter((e, i) => {
            return e?.customerDetails[0]?.businessName?.toLowerCase().includes(value.toLowerCase()) ||
              e?.customerDetails[0]?.accountNo == value

          }))
        } else {
          setSearchAllInvoices(showAllInvoices && showAllInvoices.length > 0 && showAllInvoices.filter((e, i) => {
            return e?.customerDetails[0]?.businessName?.toLowerCase().includes(value.toLowerCase()) ||
              e?.customerDetails[0]?.accountNo == value

          }))
        }

      }

      return

    }


    if (byCustomerName?.selected && selectedCategory == "Sale Invoices") {

      if (userData && userData?.role?.toLowerCase() == "sales assistant") {
        if (searchDate?.selected) {


          setSearchInvoices(dateSearchInvoices && dateSearchInvoices.length > 0 && dateSearchInvoices.filter((e, i) => {
            return e?.customerDetails[0]?.businessName?.toLowerCase().includes(value.toLowerCase()) ||
              e?.customerDetails[0]?.accountNo == value

          }))

        }
        else {
          setSearchInvoices(todayInvoices && todayInvoices.length > 0 && todayInvoices.filter((e, i) => {
            return e?.customerDetails[0]?.businessName?.toLowerCase().includes(value.toLowerCase()) ||
              e?.customerDetails[0]?.accountNo == value

          }))
        }

      } else {

        if (searchDate?.selected) {


          setSearchInvoices(dateSearchInvoices && dateSearchInvoices.length > 0 && dateSearchInvoices.filter((e, i) => {
            return e?.customerDetails[0]?.businessName?.toLowerCase().includes(value.toLowerCase()) ||
              e?.customerDetails[0]?.accountNo == value

          }))

        }
        else {

          setSearchInvoices(allInvoices && allInvoices.length > 0 && allInvoices.filter((e, i) => {
            return e?.customerDetails[0]?.businessName?.toLowerCase().includes(value.toLowerCase()) ||
              e?.customerDetails[0]?.accountNo == value

          }))
        }

      }

      return

    }

    if (byCustomerName?.selected && selectedCategory == "Return Invoices") {

      if (userData && userData?.role?.toLowerCase() == "sales assistant") {


        if (searchDate?.selected) {


          setSearchReturnInvoices(dateSearchReturnInvoices && dateSearchReturnInvoices.length > 0 && dateSearchReturnInvoices.filter((e, i) => {
            return e?.customerDetails[0]?.businessName?.toLowerCase().includes(value.toLowerCase()) ||
              e?.customerDetails[0]?.accountNo == value

          }))

        } else {
          setSearchReturnInvoices(returnInvoices && returnInvoices.length > 0 && returnInvoices.filter((e, i) => {
            return e?.customerDetails[0]?.businessName?.toLowerCase().includes(value.toLowerCase()) ||
              e?.customerDetails[0]?.accountNo == value
          }))
        }
      }


      else {
        if (searchDate?.selected) {


          setSearchReturnInvoices(dateSearchReturnInvoices && dateSearchReturnInvoices.length > 0 && dateSearchReturnInvoices.filter((e, i) => {
            return e?.customerDetails[0]?.businessName?.toLowerCase().includes(value.toLowerCase()) ||
              e?.customerDetails[0]?.accountNo == value

          }))

        }
        else {

          setSearchReturnInvoices(allReturnInvoices && allReturnInvoices.length > 0 && allReturnInvoices.filter((e, i) => {
            return e?.customerDetails[0]?.businessName?.toLowerCase().includes(value.toLowerCase()) ||
              e?.customerDetails[0]?.accountNo == value

          }))

        }
      }
      return

    }

    if (byCustomerName?.selected && selectedCategory == "Exchange Invoices") {

      if (userData && userData?.role?.toLowerCase() == "sales assistant") {




        if (searchDate?.selected) {


          setSearchExchangeInvoices(dateSearchExchangeInvoices && dateSearchExchangeInvoices.length > 0 && dateSearchExchangeInvoices.filter((e, i) => {
            return e?.customerDetails[0]?.businessName?.toLowerCase().includes(value.toLowerCase()) ||
              e?.customerDetails[0]?.accountNo == value

          }))

        }
        else {
          setSearchExchangeInvoices(exchangeInvoice && exchangeInvoice.length > 0 && exchangeInvoice.filter((e, i) => {
            return e?.customerDetails[0]?.businessName?.toLowerCase().includes(value.toLowerCase()) ||
              e?.customerDetails[0]?.accountNo == value

          }))
        }
      }

      else {

        if (searchDate?.selected) {


          setSearchExchangeInvoices(dateSearchExchangeInvoices && dateSearchExchangeInvoices.length > 0 && dateSearchExchangeInvoices.filter((e, i) => {
            return e?.customerDetails[0]?.businessName?.toLowerCase().includes(value.toLowerCase()) ||
              e?.customerDetails[0]?.accountNo == value

          }))

        }

        else {
          setSearchExchangeInvoices(allExchangeInvoices && allExchangeInvoices.length > 0 && allExchangeInvoices.filter((e, i) => {
            return e?.customerDetails[0]?.businessName?.toLowerCase().includes(value.toLowerCase()) ||
              e?.customerDetails[0]?.accountNo == value

          }))
        }
      }

      return

    }




    if (byInvoiceNumber?.selected && selectedCategory.toLowerCase() == "show all") {


      if (userData && userData?.role?.toLowerCase() == "sales assistant") {
        if (searchDate?.selected) {

          setSearchAllInvoices(dateSearchAllInvoices && dateSearchAllInvoices.length > 0 && dateSearchAllInvoices.filter((e, i) => {
            return e.invoiceNumber == value
          }))

        }
        else {
          setSearchAllInvoices(todayAllInvoices && todayAllInvoices.length > 0 && todayAllInvoices.filter((e, i) => {
            return e.invoiceNumber == value
          }))
        }
      } else {

        if (searchDate?.selected) {

          setSearchAllInvoices(dateSearchAllInvoices && dateSearchAllInvoices.length > 0 && dateSearchAllInvoices.filter((e, i) => {
            return e.invoiceNumber == value
          }))

        } else {
          setSearchAllInvoices(showAllInvoices && showAllInvoices.length > 0 && showAllInvoices.filter((e, i) => {
            return e.invoiceNumber == value

          }))
        }

      }

      return

    }

    if (byInvoiceNumber?.selected && selectedCategory == "Sale Invoices") {


      if (userData && userData?.role?.toLowerCase() == "sales assistant") {


        if (searchDate?.selected) {

          setSearchInvoices(dateSearchInvoices && dateSearchInvoices.length > 0 && dateSearchInvoices.filter((e, i) => {
            return e.invoiceNumber == value
          }))

        }
        else {
          setSearchInvoices(todayInvoices && todayInvoices.length > 0 && todayInvoices.filter((e, i) => {
            return e.invoiceNumber == value
          }))
        }
      } else {


        if (searchDate?.selected) {

          setSearchInvoices(dateSearchInvoices && dateSearchInvoices.length > 0 && dateSearchInvoices.filter((e, i) => {
            return e.invoiceNumber == value
          }))

        } else {

          setSearchInvoices(allInvoices && allInvoices.length > 0 && allInvoices.filter((e, i) => {
            return e.invoiceNumber == value

          }))
        }
      }

      return

    }


    if (byInvoiceNumber?.selected && selectedCategory == "Return Invoices") {




      if (userData && userData?.role?.toLowerCase() == "sales assistant") {

        if (searchDate?.selected) {

          setSearchReturnInvoices(dateSearchReturnInvoices && dateSearchReturnInvoices.length > 0 && dateSearchReturnInvoices.filter((e, i) => {
            return e.invoiceNumber == value
          }))

        } else {

          setSearchReturnInvoices(returnInvoices && returnInvoices.length > 0 && returnInvoices.filter((e, i) => {
            return Number(e?.invoiceNumber) == value

          }))
        }
      } else {


        if (searchDate?.selected) {

          setSearchReturnInvoices(dateSearchReturnInvoices && dateSearchReturnInvoices.length > 0 && dateSearchReturnInvoices.filter((e, i) => {
            return e.invoiceNumber == value
          }))

        } else {
          setSearchReturnInvoices(allReturnInvoices && allReturnInvoices.length > 0 && allReturnInvoices.filter((e, i) => {
            return Number(e?.invoiceNumber) == value

          }))
        }

      }

      return

    }

    if (byInvoiceNumber?.selected && selectedCategory == "Exchange Invoices") {

      if (userData && userData?.role?.toLowerCase() == "sales assistant") {



        if (searchDate?.selected) {

          setSearchExchangeInvoices(dateSearchExchangeInvoices && dateSearchExchangeInvoices.length > 0 && dateSearchExchangeInvoices.filter((e, i) => {
            return e.invoiceNumber == value
          }))

        } else {
          setSearchExchangeInvoices(exchangeInvoice && exchangeInvoice.length > 0 && exchangeInvoice.filter((e, i) => {

            return e?.invoiceNumber?.toLowerCase()?.includes(value.toLowerCase())

          }))
        }
      } else {


        if (searchDate?.selected) {

          setSearchExchangeInvoices(dateSearchExchangeInvoices && dateSearchExchangeInvoices.length > 0 && dateSearchExchangeInvoices.filter((e, i) => {
            return e.invoiceNumber == value
          }))

        } else {
          setSearchExchangeInvoices(allExchangeInvoices && allExchangeInvoices.length > 0 && allExchangeInvoices.filter((e, i) => {

            return e?.invoiceNumber?.toLowerCase()?.includes(value.toLowerCase())

          }))
        }

      }
      return

    }

    if (byIMEI?.selected && selectedCategory.toLowerCase() == "show all") {

      if (userData && userData?.role?.toLowerCase() == "sales assistant") {
        if (searchDate?.selected) {

          setSearchAllInvoices(
            dateSearchAllInvoices &&
            dateSearchAllInvoices.length > 0 &&
            dateSearchAllInvoices.filter((invoice) => {
              return invoice.productDetails.some((product) =>
                product.IMEI && Array.isArray(product.IMEI) && product.IMEI.length > 0 &&
                product.IMEI.some((imei) => imei.includes(value))
              );
            })
          );

        } else {
          setSearchAllInvoices(
            todayAllInvoices &&
            todayAllInvoices.length > 0 &&
            todayAllInvoices.filter((invoice) => {
              return invoice.productDetails.some((product) =>
                product.IMEI && Array.isArray(product.IMEI) && product.IMEI.length > 0 &&
                product.IMEI.some((imei) => imei.includes(value))
              );
            })
          );
        }
      } else {

        if (searchDate?.selected) {

          setSearchAllInvoices(
            dateSearchAllInvoices &&
            dateSearchAllInvoices.length > 0 &&
            dateSearchAllInvoices.filter((invoice) => {
              return invoice.productDetails.some((product) =>
                product.IMEI && Array.isArray(product.IMEI) && product.IMEI.length > 0 &&
                product.IMEI.some((imei) => imei.includes(value))
              );
            })
          );

        } else {
          setSearchAllInvoices(
            showAllInvoices &&
            showAllInvoices.length > 0 &&
            showAllInvoices.filter((invoice) => {
              return invoice.productDetails.some((product) =>
                product.IMEI && Array.isArray(product.IMEI) && product.IMEI.length > 0 &&
                product.IMEI.some((imei) => imei.includes(value))
              );
            })
          );
        }

      }

      return;
    }

    if (byIMEI?.selected && selectedCategory == "Sale Invoices") {

      if (userData && userData?.role?.toLowerCase() == "sales assistant") {
        if (searchDate?.selected) {

          setSearchInvoices(
            dateSearchInvoices &&
            dateSearchInvoices.length > 0 &&
            dateSearchInvoices.filter((invoice) => {
              return invoice.productDetails.some((product) =>
                product?.IMEI && Array.isArray(product.IMEI) && product.IMEI.length > 0 &&
                product.IMEI.some((imei) => imei.includes(value))
              );
            })
          );

        } else {
          setSearchInvoices(
            todayInvoices &&
            todayInvoices.length > 0 &&
            todayInvoices.filter((invoice) => {
              return invoice.productDetails.some((product) =>
                product.IMEI && Array.isArray(product.IMEI) && product.IMEI.length > 0 &&
                product.IMEI.some((imei) => imei.includes(value))
              );
            })
          );
        }
      } else {

        if (searchDate?.selected) {

          setSearchInvoices(
            dateSearchInvoices &&
            dateSearchInvoices.length > 0 &&
            dateSearchInvoices.filter((invoice) => {
              return invoice.productDetails.some((product) =>
                product.IMEI && Array.isArray(product.IMEI) && product.IMEI.length > 0 &&
                product.IMEI.some((imei) => imei.includes(value))
              );
            })
          );

        } else {
          setSearchInvoices(
            allInvoices &&
            allInvoices.length > 0 &&
            allInvoices.filter((invoice) => {
              return invoice.productDetails.some((product) =>
                product.IMEI && Array.isArray(product.IMEI) && product.IMEI.length > 0 &&
                product.IMEI.some((imei) => imei.includes(value))
              );
            })
          );
        }

      }

      return;
    }

    if (byIMEI?.selected && selectedCategory == "Return Invoices") {

      if (userData && userData?.role?.toLowerCase() == "sales assistant") {
        if (searchDate?.selected) {

          setSearchReturnInvoices(
            dateSearchReturnInvoices &&
            dateSearchReturnInvoices.length > 0 &&
            dateSearchReturnInvoices.filter((invoice) => {
              return invoice.productDetails.some((product) =>
                product.IMEI && Array.isArray(product.IMEI) && product.IMEI.length > 0 &&
                product.IMEI.some((imei) => imei.includes(value))
              );
            })
          );

        } else {
          setSearchReturnInvoices(
            returnInvoices &&
            returnInvoices.length > 0 &&
            returnInvoices.filter((invoice) => {
              return invoice.productDetails.some((product) =>
                product.IMEI && Array.isArray(product.IMEI) && product.IMEI.length > 0 &&
                product.IMEI.some((imei) => imei.includes(value))
              );
            })
          );
        }
      } else {

        if (searchDate?.selected) {

          setSearchReturnInvoices(
            dateSearchReturnInvoices &&
            dateSearchReturnInvoices.length > 0 &&
            dateSearchReturnInvoices.filter((invoice) => {
              return invoice.productDetails.some((product) =>
                product.IMEI && Array.isArray(product.IMEI) && product.IMEI.length > 0 &&
                product.IMEI.some((imei) => imei.includes(value))
              );
            })
          );

        } else {
          setSearchReturnInvoices(
            allReturnInvoices &&
            allReturnInvoices.length > 0 &&
            allReturnInvoices.filter((invoice) => {
              return invoice.productDetails.some((product) =>
                product.IMEI && Array.isArray(product.IMEI) && product.IMEI.length > 0 &&
                product.IMEI.some((imei) => imei.includes(value))
              );
            })
          );
        }

      }

      return;
    }

    if (byIMEI?.selected && selectedCategory == "Exchange Invoices") {

      if (userData && userData?.role?.toLowerCase() == "sales assistant") {
        if (searchDate?.selected) {

          setSearchExchangeInvoices(
            dateSearchExchangeInvoices &&
            dateSearchExchangeInvoices.length > 0 &&
            dateSearchExchangeInvoices.filter((invoice) => {
              return invoice.productDetails.some((product) =>
                product.IMEI && Array.isArray(product.IMEI) && product.IMEI.length > 0 &&
                product.IMEI.some((imei) => imei.includes(value))
              );
            })
          );

        } else {
          setSearchExchangeInvoices(
            exchangeInvoice &&
            exchangeInvoice.length > 0 &&
            exchangeInvoice.filter((invoice) => {
              return invoice.productDetails.some((product) =>
                product.IMEI && Array.isArray(product.IMEI) && product.IMEI.length > 0 &&
                product.IMEI.some((imei) => imei.includes(value))
              );
            })
          );
        }
      } else {

        if (searchDate?.selected) {

          setSearchExchangeInvoices(
            dateSearchExchangeInvoices &&
            dateSearchExchangeInvoices.length > 0 &&
            dateSearchExchangeInvoices.filter((invoice) => {
              return invoice.productDetails.some((product) =>
                product.IMEI && Array.isArray(product.IMEI) && product.IMEI.length > 0 &&
                product.IMEI.some((imei) => imei.includes(value))
              );
            })
          );

        } else {
          setSearchExchangeInvoices(
            allExchangeInvoices &&
            allExchangeInvoices.length > 0 &&
            allExchangeInvoices.filter((invoice) => {
              return invoice.productDetails.some((product) =>
                product.IMEI && Array.isArray(product.IMEI) && product.IMEI.length > 0 &&
                product.IMEI.some((imei) => imei.includes(value))
              );
            })
          );
        }

      }

      return;
    }


    if (selectedCategory.toLowerCase() == "show all") {


      if (userData && userData?.role?.toLowerCase() == "sales assistant") {

        let searchedInvoices;

        if (searchDate?.selected) {

          searchedInvoices = dateSearchAllInvoices && dateSearchAllInvoices.length > 0 && dateSearchAllInvoices.filter((e, i) => {
            if (e.productDetails.some((j) => j.product_name.toLowerCase()?.includes(value.toLowerCase()))) {

              return e

            }
          })

        } else {

          searchedInvoices = todayAllInvoices && todayAllInvoices.length > 0 && todayAllInvoices.filter((e, i) => {

            if (e.productDetails.some((j) => j.product_name.toLowerCase()?.includes(value.toLowerCase()))) {

              return e

            }


          })

        }

        setSearchAllInvoices(searchedInvoices)
      }
      else {


        let searchedInvoices;

        if (searchDate?.selected) {

          searchedInvoices = dateSearchAllInvoices && dateSearchAllInvoices.length > 0 && dateSearchAllInvoices.filter((e, i) => {
            if (e.productDetails.some((j) => j.product_name.toLowerCase()?.includes(value.toLowerCase()))) {

              return e

            }
          })

        }

        else {
          searchedInvoices = showAllInvoices && showAllInvoices.length > 0 && showAllInvoices.filter((e, i) => {

            if (e.productDetails.some((j) => j.product_name.toLowerCase()?.includes(value.toLowerCase()))) {

              return e

            }


          })
        }
        setSearchAllInvoices(searchedInvoices)

      }



    }



    if (selectedCategory == "Sale Invoices") {


      let searchedInvoices;

      if (userData && userData?.role?.toLowerCase() == "sales assistant") {


        if (searchDate?.selected) {

          searchedInvoices = dateSearchInvoices && dateSearchInvoices.length > 0 && dateSearchInvoices.filter((e, i) => {
            if (e.productDetails.some((j) => j.product_name.toLowerCase()?.includes(value.toLowerCase()))) {

              return e

            }
          })

        }

        else {
          searchedInvoices = todayInvoices && todayInvoices.length > 0 && todayInvoices.filter((e, i) => {

            if (e.productDetails.some((j) => j.product_name.toLowerCase()?.includes(value.toLowerCase()))) {

              return e

            }


          })

        }

        setSearchInvoices(searchedInvoices)
      }
      else {


        let searchedInvoices;


        if (searchDate?.selected) {

          searchedInvoices = dateSearchInvoices && dateSearchInvoices.length > 0 && dateSearchInvoices.filter((e, i) => {
            if (e.productDetails.some((j) => j.product_name.toLowerCase()?.includes(value.toLowerCase()))) {

              return e

            }
          })

        }
        else {
          searchedInvoices = allInvoices && allInvoices.length > 0 && allInvoices.filter((e, i) => {

            if (e.productDetails.some((j) => j.product_name.toLowerCase()?.includes(value.toLowerCase()))) {

              return e

            }


          })
        }
        setSearchInvoices(searchedInvoices)

      }



    }


    else if (selectedCategory == "Return Invoices") {



      if (userData && userData?.role?.toLowerCase() == "sales assistant") {

        let searchedInvoices;


        if (searchDate?.selected) {

          searchedInvoices = dateSearchReturnInvoices && dateSearchReturnInvoices.length > 0 && dateSearchReturnInvoices.filter((e, i) => {
            if (e.productDetails.some((j) => j.product_name.toLowerCase()?.includes(value.toLowerCase()))) {

              return e

            }
          })
        } else {
          searchedInvoices = returnInvoices && returnInvoices.length > 0 && returnInvoices.filter((e, i) => {

            if (e.productDetails.some((j) => j.product_name.toLowerCase()?.includes(value.toLowerCase()))) {

              return e

            }


          })
        }

        setSearchReturnInvoices(searchedInvoices)

      }

      else {

        let searchedInvoices;

        if (searchDate?.selected) {

          searchedInvoices = dateSearchReturnInvoices && dateSearchReturnInvoices.length > 0 && dateSearchReturnInvoices.filter((e, i) => {
            if (e.productDetails.some((j) => j.product_name.toLowerCase()?.includes(value.toLowerCase()))) {

              return e

            }
          })
        }
        else {
          searchedInvoices = allReturnInvoices && allReturnInvoices.length > 0 && allReturnInvoices.filter((e, i) => {

            if (e.productDetails.some((j) => j.product_name.toLowerCase()?.includes(value.toLowerCase()))) {

              return e

            }


          })

        }
        setSearchReturnInvoices(searchedInvoices)

      }

    }

    else if (selectedCategory == "Exchange Invoices") {


      if (userData && userData?.role?.toLowerCase() == "sales assistant") {

        let searchedInvoices;
        if (searchDate?.selected) {

          searchedInvoices = dateSearchExchangeInvoices && dateSearchExchangeInvoices.length > 0 && dateSearchExchangeInvoices.filter((e, i) => {
            if (e.productDetails.some((j) => j.product_name.toLowerCase()?.includes(value.toLowerCase()))) {

              return e

            }
          })
        } else {

          searchedInvoices = exchangeInvoice && exchangeInvoice.length > 0 && exchangeInvoice.filter((e, i) => {

            if (e.productDetails.some((j) => j.product_name.toLowerCase()?.includes(value.toLowerCase())) || e.returnProductDetails.some((j) => j.product_name.toLowerCase()?.includes(value.toLowerCase()))) {

              return e

            }


          })
        }

        setSearchExchangeInvoices(searchedInvoices)

      } else {

        let searchedInvoices;

        if (searchDate?.selected) {

          searchedInvoices = dateSearchExchangeInvoices && dateSearchExchangeInvoices.length > 0 && dateSearchExchangeInvoices.filter((e, i) => {
            if (e.productDetails.some((j) => j.product_name.toLowerCase()?.includes(value.toLowerCase()))) {

              return e

            }
          })
        }
        else {

          searchedInvoices = allExchangeInvoices && allExchangeInvoices.length > 0 && allExchangeInvoices.filter((e, i) => {

            if (e.productDetails.some((j) => j.product_name.toLowerCase()?.includes(value.toLowerCase()))) {

              return e
            }
          })
        }
        setSearchExchangeInvoices(searchedInvoices)
      }

    }

  }

  const handleConfirmPaymentMethod = (details) => {



    setPaymentDetails(details)
    setCheckOutModal(true)


    // paymentMethod?.toString()?.toLowerCase() == "cash"
    //   ? handleChangePaymentMethod()
    //   : paymentMethod?.toString()?.toLowerCase() == "card"
    //     ? setCardModalVisible(true)
    //     : paymentMethod?.toString()?.toLowerCase() == "online"
    //       ? setOnlineModalVisible(true)
    //       : paymentMethod?.toString()?.toLowerCase() == "credit"
    //         ? setCreditAccountModal(true)
    //         : setChequeModalVisible(true);

    setPaymentMethodModal(false);
  };


  const handleChangePaymentMethod = async () => {

    let chequeDate;

    if (!paymentDetails?.referenceId && paymentDetails?.online) {
      alert("Write Reference Id");
      return;
    }

    if (!paymentDetails?.creditDays && paymentDetails?.credit) {
      alert("Write Credit Days");
      return;
    }

    if (!paymentDetails?.transactionId && paymentDetails?.card) {
      alert("Write Transaction Id");
      return;
    }

    if (paymentDetails?.cheque) {
      if (!paymentDetails?.chequeDetails?.chequeNo) {
        alert("cheque number is missing");
        return;
      }

      if (!paymentDetails?.chequeDetails?.bankName) {
        alert("Bank Name is missing");
        return;
      }

      if (!paymentDetails?.chequeDetails?.day) {
        alert("cheque day is missing");
        return;
      }
      if (!paymentDetails?.chequeDetails?.month) {
        alert("cheque month is missing");
        return;
      }
      if (!paymentDetails?.chequeDetails?.year) {
        alert("cheque year is missing");
        return;
      }

      let date = new Date(
        `${paymentDetails?.chequeDetails?.month}/${paymentDetails?.chequeDetails?.day}/${paymentDetails?.chequeDetails?.year}`
      );

      if (date == "Invalid Date") {
        alert("Invalid Date");
        return;
      }
      chequeDate = date;
    }

    let dataToSend = {

      transactionId: paymentDetails?.transactionId,
      invoiceData: clickedData,
      referenceId: paymentDetails?.referenceId,
      paymentMethod: [paymentDetails?.cash && "cash", paymentDetails?.card && "card", paymentDetails?.online && "online", paymentDetails?.cheque && "cheque", paymentDetails?.credit && "on Account"],
      bankName: paymentDetails?.chequeDetails?.bankName,
      chequeNo: paymentDetails?.chequeDetails?.chequeNo,
      clearDate: chequeDate,
      creditDays: paymentDetails?.creditDays,
      cash: paymentDetails.cash ? Number(paymentDetails?.cash) : 0,
      card: paymentDetails?.card ? Number(paymentDetails?.card) : 0,
      online: paymentDetails?.online ? Number(paymentDetails?.online) : 0,
      credit: paymentDetails?.credit ? Number(paymentDetails?.credit) : 0,
      cheque: paymentDetails?.cheque ? Number(paymentDetails?.cheque) : 0

    }

    let method = dataToSend && dataToSend?.paymentMethod && dataToSend?.paymentMethod?.length > 0 && dataToSend?.paymentMethod?.filter((e, i) => e)
    dataToSend.paymentMethod = method
    setLoading(true)

    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .put(`${Base_Uri}changePaymentMethod`, dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      }).then((res) => {

        let data = res?.data
        if (!data?.status) {
          alert(data?.message)
          setLoading(false)
          return
        }


        let updatedData = data?.data

        updatedData.id = updatedData._id

        let invoices = [...allInvoices]

        let otherInvoices = invoices?.filter((e, i) => e?._id !== updatedData?._id)

        otherInvoices = otherInvoices && otherInvoices?.length > 0 ? otherInvoices : []

        let updatedInvoices = [...otherInvoices, updatedData]



        dispatch(updateAllInvoices(updatedInvoices))
        alert(data?.message)
        setLoading(false)
        setOnlineModalVisible(false)
        setChequeModalVisible(false)
        setCheckOutModal(false)
        setCardModalVisible(false)
        setCreditAccountModal(false)
        setCreditDays("")
        setTransactionId("")
        setOnlineReferenceId("")
        setChequeDetails({
          chequeNo: "",
          bankName: "",
          day: "",
          month: "",
          year: "",
        })



      }).catch(error => {

        alert(error?.message)
        setLoading(false)
        return


      })





  }



  React.useEffect(() => {

    if (searchDate?.selected && startDate && endDate) {


      if (selectedCategory.toLowerCase() == "show all") {

        if (userData && userData?.role?.toLowerCase() == "sales Assistant") {


          setDateSearchAllInvoices(todayAllInvoices && todayAllInvoices?.length > 0 && todayAllInvoices.filter((e) => {

            let addDate = new Date(e.saleDate ? e.saleDate : e?.saleReturnDate ? e?.saleReturnDate : e?.exchangeDate);

            // Parse selectedDate and dateFrom
            let selectedDateParts = endDate?.split('/');
            let dateFromParts = startDate?.split('/');

            // Create Date objects from the parsed components
            let selectedDateTime = new Date(
              parseInt(selectedDateParts[2]),   // Year
              parseInt(selectedDateParts[1]),   // Month (zero-based)
              parseInt(selectedDateParts[0]),   // Day
              23, // Hours
              59, // Minutes
              59  // Seconds
            );

            let dateFromTime = new Date(
              parseInt(dateFromParts[2]),    // Year
              parseInt(dateFromParts[1]),     // Month (zero-based)
              parseInt(dateFromParts[0]),     // Day
              0,  // Hours
              0,  // Minutes
              0   // Seconds
            );


            // Check if addDate is between dateFrom and selectedDate
            return addDate >= dateFromTime && addDate <= selectedDateTime;
          }));

          return
        }

        setDateSearchAllInvoices(showAllInvoices && showAllInvoices?.length > 0 && showAllInvoices.filter((e) => {

          let addDate = new Date(e.saleDate ? e.saleDate : e?.saleReturnDate ? e?.saleReturnDate : e?.exchangeDate);

          // Parse selectedDate and dateFrom
          let selectedDateParts = endDate?.split('/');
          let dateFromParts = startDate?.split('/');

          // Create Date objects from the parsed components
          let selectedDateTime = new Date(
            parseInt(selectedDateParts[2]),   // Year
            parseInt(selectedDateParts[1]),   // Month (zero-based)
            parseInt(selectedDateParts[0]),   // Day
            23, // Hours
            59, // Minutes
            59  // Seconds
          );

          let dateFromTime = new Date(
            parseInt(dateFromParts[2]),    // Year
            parseInt(dateFromParts[1]),     // Month (zero-based)
            parseInt(dateFromParts[0]),     // Day
            0,  // Hours
            0,  // Minutes
            0   // Seconds
          );


          // Check if addDate is between dateFrom and selectedDate
          return addDate >= dateFromTime && addDate <= selectedDateTime;

        }));

      }

      else if (selectedCategory.toLowerCase() == "sale invoices") {

        if (userData && userData?.role?.toLowerCase() == "sales Assistant") {


          setDateSearchInvoices(todayInvoices && todayInvoices?.length > 0 && todayInvoices.filter((e) => {

            let addDate = new Date(e.saleDate ? e.saleDate : e?.saleReturnDate ? e?.saleReturnDate : e?.exchangeDate);

            // Parse selectedDate and dateFrom
            let selectedDateParts = endDate?.split('/');
            let dateFromParts = startDate?.split('/');

            // Create Date objects from the parsed components
            let selectedDateTime = new Date(
              parseInt(selectedDateParts[2]),   // Year
              parseInt(selectedDateParts[1]),   // Month (zero-based)
              parseInt(selectedDateParts[0]),   // Day
              23, // Hours
              59, // Minutes
              59  // Seconds
            );

            let dateFromTime = new Date(
              parseInt(dateFromParts[2]),    // Year
              parseInt(dateFromParts[1]),     // Month (zero-based)
              parseInt(dateFromParts[0]),     // Day
              0,  // Hours
              0,  // Minutes
              0   // Seconds
            );


            // Check if addDate is between dateFrom and selectedDate
            return addDate >= dateFromTime && addDate <= selectedDateTime;
          }));

          return
        }

        setDateSearchInvoices(allInvoices && allInvoices?.length > 0 && allInvoices.filter((e) => {

          let addDate = new Date(e.saleDate ? e.saleDate : e?.saleReturnDate ? e?.saleReturnDate : e?.exchangeDate);

          // Parse selectedDate and dateFrom
          let selectedDateParts = endDate?.split('/');
          let dateFromParts = startDate?.split('/');

          // Create Date objects from the parsed components
          let selectedDateTime = new Date(
            parseInt(selectedDateParts[2]),   // Year
            parseInt(selectedDateParts[1]),   // Month (zero-based)
            parseInt(selectedDateParts[0]),   // Day
            23, // Hours
            59, // Minutes
            59  // Seconds
          );

          let dateFromTime = new Date(
            parseInt(dateFromParts[2]),    // Year
            parseInt(dateFromParts[1]),     // Month (zero-based)
            parseInt(dateFromParts[0]),     // Day
            0,  // Hours
            0,  // Minutes
            0   // Seconds
          );


          // Check if addDate is between dateFrom and selectedDate
          return addDate >= dateFromTime && addDate <= selectedDateTime;

        }));

      }



      else if (selectedCategory.toLowerCase() == "return invoices") {

        if (userData && userData?.role?.toLowerCase() == "sales Assistant") {


          setDateSearchReturnInvoices(returnInvoices && returnInvoices?.length > 0 && returnInvoices.filter((e) => {

            let addDate = new Date(e.saleDate ? e.saleDate : e?.saleReturnDate ? e?.saleReturnDate : e?.exchangeDate);

            // Parse selectedDate and dateFrom
            let selectedDateParts = endDate?.split('/');
            let dateFromParts = startDate?.split('/');

            // Create Date objects from the parsed components
            let selectedDateTime = new Date(
              parseInt(selectedDateParts[2]),   // Year
              parseInt(selectedDateParts[1]),   // Month (zero-based)
              parseInt(selectedDateParts[0]),   // Day
              23, // Hours
              59, // Minutes
              59  // Seconds
            );

            let dateFromTime = new Date(
              parseInt(dateFromParts[2]),    // Year
              parseInt(dateFromParts[1]),     // Month (zero-based)
              parseInt(dateFromParts[0]),     // Day
              0,  // Hours
              0,  // Minutes
              0   // Seconds
            );


            // Check if addDate is between dateFrom and selectedDate
            return addDate >= dateFromTime && addDate <= selectedDateTime;
          }));

          return
        }

        setDateSearchReturnInvoices(allReturnInvoices && allReturnInvoices?.length > 0 && allReturnInvoices.filter((e) => {

          let addDate = new Date(e.saleDate ? e.saleDate : e?.saleReturnDate ? e?.saleReturnDate : e?.exchangeDate);

          // Parse selectedDate and dateFrom
          let selectedDateParts = endDate?.split('/');
          let dateFromParts = startDate?.split('/');

          // Create Date objects from the parsed components
          let selectedDateTime = new Date(
            parseInt(selectedDateParts[2]),   // Year
            parseInt(selectedDateParts[1]),   // Month (zero-based)
            parseInt(selectedDateParts[0]),   // Day
            23, // Hours
            59, // Minutes
            59  // Seconds
          );

          let dateFromTime = new Date(
            parseInt(dateFromParts[2]),    // Year
            parseInt(dateFromParts[1]),     // Month (zero-based)
            parseInt(dateFromParts[0]),     // Day
            0,  // Hours
            0,  // Minutes
            0   // Seconds
          );


          // Check if addDate is between dateFrom and selectedDate
          return addDate >= dateFromTime && addDate <= selectedDateTime;

        }));

      }

      else if (selectedCategory.toLowerCase() == "exchange invoices") {

        if (userData && userData?.role?.toLowerCase() == "sales Assistant") {


          setDateSearchExchangeInvoices(exchangeInvoice && exchangeInvoice?.length > 0 && exchangeInvoice.filter((e) => {

            let addDate = new Date(e.saleDate ? e.saleDate : e?.saleReturnDate ? e?.saleReturnDate : e?.exchangeDate);

            // Parse selectedDate and dateFrom
            let selectedDateParts = endDate?.split('/');
            let dateFromParts = startDate?.split('/');

            // Create Date objects from the parsed components
            let selectedDateTime = new Date(
              parseInt(selectedDateParts[2]),   // Year
              parseInt(selectedDateParts[1]),   // Month (zero-based)
              parseInt(selectedDateParts[0]),   // Day
              23, // Hours
              59, // Minutes
              59  // Seconds
            );

            let dateFromTime = new Date(
              parseInt(dateFromParts[2]),    // Year
              parseInt(dateFromParts[1]),     // Month (zero-based)
              parseInt(dateFromParts[0]),     // Day
              0,  // Hours
              0,  // Minutes
              0   // Seconds
            );


            // Check if addDate is between dateFrom and selectedDate
            return addDate >= dateFromTime && addDate <= selectedDateTime;
          }));

          return
        }

        setDateSearchExchangeInvoices(allExchangeInvoices && allExchangeInvoices?.length > 0 && allExchangeInvoices.filter((e) => {

          let addDate = new Date(e.saleDate ? e.saleDate : e?.saleReturnDate ? e?.saleReturnDate : e?.exchangeDate);

          // Parse selectedDate and dateFrom
          let selectedDateParts = endDate?.split('/');
          let dateFromParts = startDate?.split('/');

          // Create Date objects from the parsed components
          let selectedDateTime = new Date(
            parseInt(selectedDateParts[2]),   // Year
            parseInt(selectedDateParts[1]),   // Month (zero-based)
            parseInt(selectedDateParts[0]),   // Day
            23, // Hours
            59, // Minutes
            59  // Seconds
          );

          let dateFromTime = new Date(
            parseInt(dateFromParts[2]),    // Year
            parseInt(dateFromParts[1]),     // Month (zero-based)
            parseInt(dateFromParts[0]),     // Day
            0,  // Hours
            0,  // Minutes
            0   // Seconds
          );


          // Check if addDate is between dateFrom and selectedDate
          return addDate >= dateFromTime && addDate <= selectedDateTime;

        }));

      }

    } else {
      setDateSearchAllInvoices([]);
      setDateSearchInvoices([])
      setDateSearchReturnInvoices([])
      setDateSearchExchangeInvoices([])
    }




  }, [startDate, searchDate?.selected, endDate])



  const handleSelectCategory = (category) => {


    setSearchDate({
      ...searchDate,
      selected: false
    })
    setSelectedCategory(category)

  }


  useEffect(() => {


    if (
      barcodeRead?.length > 0 && byInvoiceNumber?.selected

    ) {
      let invoice;
      if ((userData?.role == "admin" || userData?.role_access?.some((e, i) => e.name.toLowerCase() == "allinvoices" && e?.selected))) {

        invoice =
          showAllInvoices &&
          showAllInvoices?.length > 0 &&
          showAllInvoices?.find((e) =>
            e?.barcodeNumber == barcodeRead)


      } else {
        invoice =
          todayAllInvoices &&
          todayAllInvoices?.length > 0 &&
          todayAllInvoices?.find((e) =>
            e?.barcodeNumber == barcodeRead)

      }


      if (invoice) {

        setSearchAllInvoices([invoice]);
        setBarcodeRead("");
      } else {
        setBarcodeRead("")
      }
    }

    else if (barcodeRead?.length > 0 && !byInvoiceNumber?.selected) {
      let invoice;
      if ((userData?.role == "admin" || userData?.role_access?.some((e, i) => e.name.toLowerCase() == "allinvoices" && e?.selected))) {
        invoice =
          showAllInvoices &&
          showAllInvoices?.length > 0 &&
          showAllInvoices?.filter((e) => e?.productDetails?.some((j, i) => j?.barcode == barcodeRead) || e?.returnProductDetails?.some((k, i) => k?.barcode == barcodeRead))

      } else {
        invoice =
          todayAllInvoices &&
          todayAllInvoices?.length > 0 &&
          todayAllInvoices?.filter((e) => e?.productDetails?.some((j, i) => j?.barcode == barcodeRead) || e?.returnProductDetails?.some((k, i) => k?.barcode == barcodeRead))

      }


      if (invoice) {

        setSearchAllInvoices(invoice);
        setBarcodeRead("");
      } else {
        setBarcodeRead("")
      }
    }



  }, [barcodeRead]);




  return (
    // loading ? <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minHeight: "70vh", width: "100%" }} >
    //   <RingLoader loading={loading} size={100} color={Colors.black} />
    //   <Typography style={{ fontSize: "24px", color: Colors.black, fontFamily: "Poppins" }} >Getting Products...</Typography>
    // </Box> :

    pdfDownloadView ? (
      <Box
        style={{
          wodth: "100%",
          height: "100%",
          // border: "1px solid red",
          display: "flex",
          justifyContent: "center",
        }}
      >

        <PDFViewer width={850} height={700} style={{ marginTop: 20 }}>
          <MyDocument />
        </PDFViewer>
        <CloseOutlinedIcon fontSize="large" style={{ color: Colors.black, marginTop: "20px", cursor: "pointer", }} onClick={() => setPdfDownloadView(false)} />
      </Box>
    ) :

      <Box sx={{ padding: "20px", backgroundColor: Colors.white, marginTop: "0px" }} >
        <Navbar currentPage={userData?.role == "admin" ? "All Invoices" : "Today Invoices"} />
        <Box sx={{ display: "flex" }} >
          <Grid container md={12} xs={12} sm={12} >

            <Grid item xs={12} sm={12} md={6} >
              <Box sx={{ width: "100%" }} >

                <Grid md={11} xs={10} sm={10} >
                  <Box sx={{ width: "100%", marginTop: { xs: "10px", sm: "10px", md: "0px" }, border: `1px solid ${Colors.button}`, borderRadius: "5px", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px 10px 5px 10px", margin: 0 }} >
                    <TextField
                      variant="standard" // <== changed this
                      margin="normal"
                      required
                      fullWidth
                      InputProps={{
                        // startAdornment: <AccountCircle />, // <== adjusted this
                        disableUnderline: true,
                        fontSize: "12px"
                        // <== added this
                        // fontSize: "2px"
                      }}
                      onChange={handleSearchProducts}
                      placeholder={byInvoiceNumber?.selected ? "Search Invoices" : byCustomerName?.selected ? "Search Business Name" : "Search Products"}
                      sx={{ width: "90%", fontSize: "12px", padding: 0, fontFamily: "Poppins", fontWeight: "bold", margin: 0 }}
                    />
                    <img src={require("../../../../Images/search.png")} style={{ height: "20px" }} />
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", width: "100%" }} >
                    <Box style={{ display: "flex", marginTop: 10, alignItems: "center" }} >

                      <Checkbox data={byInvoiceNumber} onClick={() => selectByInvoiceNumber()} />

                      <Typography style={{ color: Colors.text, fontSize: 12, fontWeight: "500", fontFamily: "Poppins", textAlign: "left", marginLeft: "10px" }} >
                        By Invoice no
                      </Typography>

                    </Box>


                    <Box style={{ display: "flex", marginTop: 10, alignItems: "center", marginLeft: 10 }} >

                      <Checkbox data={byCustomerName} onClick={() => selectByCustomerName()} />

                      <Typography style={{ color: Colors.text, fontSize: 12, fontWeight: "500", fontFamily: "Poppins", textAlign: "left", marginLeft: "10px" }} >
                        By Business Name
                      </Typography>

                    </Box>

                    <Box style={{ display: "flex", marginTop: 10, alignItems: "center", marginLeft: "10px" }} >

                      <Checkbox data={byIMEI} onClick={() => selectByIMEINumber()} />

                      <Typography style={{ color: Colors.text, fontSize: 12, fontWeight: "500", fontFamily: "Poppins", textAlign: "left", marginLeft: "10px" }} >
                        By IMEI
                      </Typography>

                    </Box>

                  </Box>
                </Grid>




              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} style={{ display: "flex", justifyContent: "flex-end" }} >
              <BasicSelect style={{ marginTop: 0.5, width: "70%" }} value={selectedCategory} status="Select Invoice Type.." id={productCategory} width={"100%"} onChange={(e) => handleSelectCategory(e)} />
            </Grid>
          </Grid>
        </Box>


        {userData && userData?.role?.toLowerCase() !== "sales assistant" && <Grid container xs={12} >
          <Grid item xs={12} sm={12} md={12} style={{ marginTop: 10 }} >
            <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }} >
              <Grid item xs={3} sm={3} md={3} lg={2} >
                <Box sx={{ display: "flex", alignItems: "center" }} >
                  <Typography sx={{ fontSize: "14px", fontFamily: "Poppins", mr: 1, color: Colors.text }} >
                    Search By Date
                  </Typography>
                  <Checkbox
                    data={searchDate}
                    onClick={() => setSearchDate(prevState => ({ ...prevState, selected: !prevState.selected }))}
                  />
                </Box>


              </Grid>
              <Grid item xs={12} sm={5} md={5} lg={2} xl={2} style={{ marginRight: "5px" }} >

                <Typography sx={{ fontSize: "14px", fontFamily: "Poppins", mr: 1, color: Colors.text }} >
                  From
                </Typography>
                <DatePickers style={{ borderRadius: 10, marginTop: { xs: "5px", sm: "0px" } }} values={startDate} onChange={(e) => setStartDate(e)} />
              </Grid>

              <Grid item xs={12} sm={5} md={5} lg={2} xl={2} style={{ marginRight: "5px" }} >

                <Typography sx={{ fontSize: "14px", fontFamily: "Poppins", mr: 1, color: Colors.text }} >
                  To
                </Typography>
                <DatePickers style={{ borderRadius: 10, marginTop: { xs: "5px", sm: "0px" } }} values={endDate} onChange={(e) => setEndDate(e)} />

              </Grid>

            </Box>
          </Grid>

        </Grid>}



        <Box sx={{ height: "500px", backgroundColor: "white", marginTop: 5 }} >
          <div style={{ width: "100%" }}>
            <DataGrid
              key={gridKey}
              rows={
                (selectedCategory.toLowerCase() == "show all" && searchAllInvoices?.length > 0) ? searchAllInvoices : (dateSearchAllInvoices && dateSearchAllInvoices?.length > 0) ? dateSearchAllInvoices : selectedCategory.toLowerCase() == "show all" ? (userData?.role == "admin" || userData?.role_access?.some((e, i) => e.name.toLowerCase() == "allinvoices" && e?.selected)) ? showAllInvoices : todayAllInvoices :
                  (selectedCategory == "Sale Invoices" && searchInvoices.length > 0)
                    ? searchInvoices : (dateSearchInvoices && dateSearchInvoices?.length > 0) ? dateSearchInvoices : selectedCategory == "Sale Invoices" ? (userData?.role == "admin" || userData?.role_access?.some((e, i) => e.name.toLowerCase() == "allinvoices" && e?.selected)) ? allInvoices : todayInvoices
                      : (selectedCategory == "Return Invoices" && searchReturnInvoices.length > 0) ? searchReturnInvoices : (dateSearchReturnInvoices && dateSearchReturnInvoices?.length > 0) ? dateSearchReturnInvoices : selectedCategory == "Return Invoices" ? (userData?.role == "admin" || userData?.role_access?.some((e, i) => e.name.toLowerCase() == "allinvoices" && e?.selected)) ? allReturnInvoices : returnInvoices :
                        (selectedCategory == "Exchange Invoices" && searchExchangeInvoices.length > 0) ? searchExchangeInvoices : (dateSearchExchangeInvoices && dateSearchExchangeInvoices?.length > 0) ? dateSearchExchangeInvoices : (userData?.role == "admin" || userData?.role_access?.some((e, i) => e.name.toLowerCase() == "allinvoices" && e?.selected)) ? allExchangeInvoices : exchangeInvoice
              }
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 8, page: 0 },
                },
              }}
              sx={{
                minHeight: 500, width: "100%",


                '& .MuiDataGrid-cell': {
                  fontFamily: 'Poppins',
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "#58595B"
                  // Change to your desired cell text color
                },

                // '& .MuiDataGrid-columnHeader': {
                //     backgroundColor: Colors.black,
                //     color: "white",
                //     fontWeight: 700,
                //     border: "2px solid white",
                //     margin: 0,
                //     padding: '8px', // Adjust this value as needed
                //     alignItems: "center"
                // },
                '& .MuiDataGrid-colCell, & .MuiDataGrid-colCellTitle': {
                  padding: '0px', // Adjust this value as needed
                },
                '& .MuiDataGrid-columnHeaderCheckbox': {
                  backgroundColor: Colors.black, // Change this to your desired checkbox background color
                  color: 'white', // Change this to your desired checkbox color
                },
                // '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked':{
                //     color:"blue" 
                // },

                '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root': {
                  color: "gray"
                }
                // '& .MuiDataGrid-columnHeaderCheckbox': {
                //     backgroundColor: Colors.white
                // },
                ,
                '& .MuiDataGrid-columnHeader': {
                  fontFamily: "Poppins",
                  // color: "black",
                  fontWeight: "bold",
                },

              }}
            />
          </div>
          {invoiceDetailModal && (
            <ViewInvoiceDetailModal
              open={invoiceDetailModal}
              closeModal={() => setInvoiceDetailModal(false)}
            />
          )}

          <PaymentMethodModal
            heading={`Select Payment Method`}
            loading={loading}
            invoiceData={clickedData}
            subtotal={clickedData?.subtotal}
            // text={"Can you confirm that you have received this amount?"}
            open={paymentMethodModal}
            closeModal={() => setPaymentMethodModal(false)}
            confirm={handleConfirmPaymentMethod}
          />



          {checkOutModal && (
            <CustomModal
              heading={"Update Payment Method"}
              loading={loading}
              // credit={true}
              // onChange={(e) => setCreditDays(e?.target?.value)}
              text={"Are you sure you want to change payment method?"}
              open={checkOutModal}
              closeModal={() => setCheckOutModal(false)}
              confirm={() => handleChangePaymentMethod()}
            />
          )}

          {creditAccountModal && (
            <CustomModal
              heading={"Update Payment Method"}
              loading={loading}
              credit={true}
              onChange={(e) => setCreditDays(e?.target?.value)}
              text={"Are you sure you want to change payment method on credit?"}
              open={creditAccountModal}
              closeModal={() => setCreditAccountModal(false)}
              confirm={() => handleChangePaymentMethod()}
            />
          )}


          <OnlineTransactionModal
            heading={"Online Transaction"}
            text={"Reference ID:"}
            onChange={(e) => setOnlineReferenceId(e.target.value)}
            open={onlineModalVisible}
            closeModal={() => setOnlineModalVisible(false)}
            loading={loading}
            buttonStyle2={{ width: "200px", fontSize: "14px", mt: 2 }}
            buttonText="Received Amount"
            confirm={() =>
              handleChangePaymentMethod()
            }
          />

          <OnlineTransactionModal
            heading={"Card Transaction"}
            text={"Transaction ID:"}
            onChange={(e) => setTransactionId(e.target.value)}
            open={cardModalVisible}
            closeModal={() => setCardModalVisible(false)}
            loading={loading}
            buttonStyle2={{ width: "200px", fontSize: "14px", mt: 2 }}
            buttonText="Confirm"
            confirm={() =>
              handleChangePaymentMethod()
            }
          />

          <ChequeModal
            heading={"Cheque"}
            text={"Cheque No:"}
            text1={"Bank Name:"}
            text2={"Clear Date:"}
            loading={loading}
            onChange={(e) =>
              setChequeDetails({ ...chequeDetails, chequeNo: e.target.value })
            }
            onChange1={(e) =>
              setChequeDetails({ ...chequeDetails, bankName: e.target.value })
            }
            onChangeDay={(e) =>
              setChequeDetails({ ...chequeDetails, day: e.target.value })
            }
            onChangeMonth={(e) =>
              setChequeDetails({ ...chequeDetails, month: e.target.value })
            }
            onChangeYear={(e) =>
              setChequeDetails({ ...chequeDetails, year: e.target.value })
            }
            open={chequeModalVisible}
            closeModal={() => setChequeModalVisible(false)}
            buttonStyle2={{ width: "200px", fontSize: "14px", mt: 2 }}
            buttonText="Confirm"
            confirm={() =>
              handleChangePaymentMethod()
            }
          />

        </Box>
      </Box>
  )
}

export default TodayInvoices
