import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Colors } from '../../Constant/Color'
import CustomButton from '../../Components/Button'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import BasicSelect from '../../Components/Dropdown';
import { DataGrid } from "@mui/x-data-grid"
import Checkbox from '../../Components/checkbox';
import { CheckBox } from '@mui/icons-material';
import { Navigate, useNavigate } from 'react-router-dom';
import { RingLoader } from "react-spinners";
import axios from 'axios';
import { Base_Uri, Image_Uri } from '../../Constant/BaseUri';
import { useDispatch, useSelector } from 'react-redux';
import { edit, updateAllEmployees, updateAllExchangeInvoice, updateAllInvoices, updateAllReturnInvoices, updateDayReturnInvoiceEmp, updateInvoice, updateProducts, updatetodayEmployeeExchangeInvoice, viewSelectedInvoice } from '../../Redux/LoginReduces';
import Navbar from '../../Components/Navbar';
import CustomModal from '../../Components/modal';
import EmployeeRoleModal from '../../Components/EmployeeRoleModal';
import RoleAccessModal from '../../Components/roleAccessModal';
import EditEmployeeDetailsModal from '../../Components/EditEmployeeDetailsModal';
import AdminChangePasswordModal from '../../Components/adminChangePasswordModal';



function Employees() {


    const navigate = useNavigate()
    const dispatch = useDispatch()

    const allEmployees = useSelector(state => state?.loginReducer?.allEmployees)

    const [dataClicked, setDataClicked] = useState("")

    const userData = useSelector((state) => state.loginReducer.updatedState);

    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openStatusModal, setOpenStatusModal] = useState(false)
    const [openPriceShowModal, setOpenPriceShowModal] = useState(false)
    const [gridKey, setGridKey] = useState(0); // Add this state variable
    const [loading, setLoading] = useState(false)
    const [employees, setEmployees] = useState([])
    const [searchData, setSearchData] = useState([])
    const [openChangeRoleModal, setOpenChangeRoleModal] = useState(false)
    const [openRoleAccessModal, setOpenRoleAccessModal] = useState(false)

    const [openEditEmployeeModal, setOpenEditEmployeeModal] = useState(false)

    const [roleLoading, setRoleLoading] = useState(false)

    const [editEmployeeLoading, setEditEmployeeLoading] = useState(false)

    const [employeeRoleAccess, setEmployeeRoleAccess] = useState(false)

    const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false)

    const [changeAdminPassLoading,setChangeAdminPassLoading] = useState(false)


    const getAllEmployees = async () => {


        if (userData) {
            let data = await localStorage.getItem("userData");
            data = JSON.parse(data);
            let token = data;


            axios.get(`${Base_Uri}getAllEmployees`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {

                let data = res.data

                if (data?.status) {


                    let employee = data?.data


                    employee = employee.filter((e, i) => e.role !== "admin")

                    employee = employee && employee.length > 0 && employee.map((e, i) => {
                        return {
                            ...e,
                            id: i + 1
                        }
                    })

                    setEmployees(employee)
                    dispatch(updateAllEmployees(employee))
                } else {
                    alert(data?.message)
                }

            }).catch((error) => {

                alert(error.message)

            })

        }




    }

    useEffect(() => {


        setEmployees(allEmployees)

        getAllEmployees()

    }, [userData.id])


    useEffect(() => {

        let role = userData?.role_access

        let employeeRole = role?.find((e) => e?.name?.toLowerCase() == "employees")

        let subRole = employeeRole?.subcategory

        subRole = subRole?.[0]

        setEmployeeRoleAccess(subRole?.selected)









    }, [userData?.id])


    const handleDeleteClick = (data) => {


        if (data.role == "admin") {

            alert("Admin cannot be deleted")
            return

        }


        setOpenDeleteModal(true)
        setDataClicked(data)

    }

    const handleStatusClicked = (data) => {

        setOpenStatusModal(true)
        setDataClicked(data)




    }

    const handleChangeRole = (data) => {

        setOpenChangeRoleModal(true)
        setDataClicked(data)



    }

    const handleShowPriceClicked = (data) => {

        setOpenPriceShowModal(true)
        setDataClicked(data)
    }


    const handleShowRoleAccess = (data) => {

        setDataClicked(data)
        setOpenRoleAccessModal(true)

    }

    const handleOpenEditModal = (data) => {

        setDataClicked(data)
        setOpenEditEmployeeModal(true)

    }
    const columns = [
        {
            field: "id",
            headerName: "S.no",
            width: 100,
            filterable: false,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: "full_name",
            headerName: "Employee Name",
            width: 150,
            filterable: false,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: "employee_id",
            headerName: "Employee Id",
            width: 150,
            filterable: false,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: "email_address",
            headerName: "Email",
            width: 250,
            filterable: false,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: "created_at",
            headerName: "Date",
            headerClassName: 'super-app-theme--header',
            width: 150,
            filterable: false,
            renderCell: (cellValues) => {
                return (
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "30%",
                            backgroundColor: Colors.white,
                        }}
                    >
                        {`${new Date(cellValues?.row?.created_at).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                        })}`}
                    </Box>
                );
            },
        },

        {
            field: "role",
            headerName: "Role",
            headerClassName: 'super-app-theme--header',
            width: 150,
            filterable: false,

            renderCell: (cellValues) => {
                return <p style={{ color: cellValues?.row?.role == "Manager" ? "green" : "purple", fontFamily: "Poppins" }} >{cellValues?.row?.role ?? "Sales Assistant"}</p>
            },
        },
        {
            field: "action",
            headerName: "Actions",
            width: 600,
            headerClassName: 'super-app-theme--header',
            filterable: false,
            renderCell: (cellValues) => {
                return (
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>

                        <Button
                            onClick={() => handleOpenEditModal(cellValues?.row)}
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "20%",
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                textAlign: "center",
                                backgroundColor: Colors.white,
                                fontWeight: "bold",
                                color: "blue"
                            }}
                        >
                            Edit
                        </Button>

                        <Button
                            onClick={() => handleDeleteClick(cellValues?.row)}
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "20%",
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                backgroundColor: Colors.white,
                                color: Colors.red,
                                fontWeight: "bold"
                            }}
                        >
                            Delete
                        </Button>

                        <Button
                            onClick={() => (employeeRoleAccess || userData?.role == "admin") ? handleShowRoleAccess(cellValues?.row) : alert("You don't have access to update roles")}
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "20%",
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                backgroundColor: Colors.white,
                                color: Colors.green,
                                fontWeight: "bold"
                            }}
                        >
                            Roles
                        </Button>

                        <Button
                            onClick={() => handleStatusClicked(cellValues?.row)}
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "20%",
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                backgroundColor: Colors.white,
                                fontWeight: "bold",
                                color: cellValues?.row?.status == "active" ? Colors.button : Colors.red
                            }}
                        >
                            {cellValues?.row?.status ? cellValues?.row?.status : "active"}
                        </Button>

                        <Button
                            onClick={() => (employeeRoleAccess || userData?.role == "admin") ? handleChangeRole(cellValues?.row) : alert("You don't have access to update roles")}
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "20%",
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                textAlign: "center",
                                backgroundColor: Colors.white,
                                fontWeight: "bold",
                                color: cellValues?.row?.role.toLowerCase() == "manager" ? Colors.button : Colors.red
                            }}
                        >
                            {cellValues?.row?.role ? cellValues?.row?.role : "Sales Assistant"}
                        </Button>





                    </Box>
                );
            },
        },
    ];


    const handleSearchProducts = (e) => {

        let value = e.target.value


        if (!value) {
            setSearchData([])
            return
        }

        setSearchData(employees && employees?.length > 0 && employees?.filter((e, i) => {

            if (e?.full_name?.toLowerCase().includes(value?.toLowerCase()) || e?.status?.toLowerCase().includes(value?.toLowerCase())
                || e?.employee_id == value || e?.email_address?.toLowerCase().includes(value?.toLowerCase())

            ) {
                return e
            }
        }))





    }

    const handleConfirmDeleteEmployee = async () => {

        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;


        setLoading(true)

        axios.delete(`${Base_Uri}deleteEmployee/${dataClicked?.employee_id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {

            let data = res.data
            if (data?.status) {

                setOpenDeleteModal(false)
                getAllEmployees()
                setLoading(false)
                alert(data?.message)

            } else {
                alert(data?.message)
                setLoading(false)
            }

        }).catch((error) => {

            alert(error?.message)
            setLoading(false)

        })


    }

    const handleConfirmChangeStatus = async () => {




        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;


        setLoading(true)

        let dataToSend = {
            id: dataClicked?.employee_id,
            status: dataClicked?.status ? dataClicked?.status : "active"
        }


        axios.put(`${Base_Uri}changeEmployeeStatus`, dataToSend, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {

            let data = res.data
            if (data?.status) {

                setOpenStatusModal(false)
                getAllEmployees()
                setLoading(false)
                alert(data?.message)

            } else {
                alert(data?.message)
                setLoading(false)
            }

        }).catch((error) => {

            alert(error?.message)
            setLoading(false)

        })


    }

    const handleConfirmChangePriceShow = async () => {




        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;


        setLoading(true)

        let dataToSend = {
            id: dataClicked?.employee_id,
            status: dataClicked?.price_show ? true : false
        }

        axios.put(`${Base_Uri}changeEmployeeShowPriceStatus`, dataToSend, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {

            let data = res.data
            if (data?.status) {

                setOpenPriceShowModal(false)
                getAllEmployees()
                setLoading(false)
                alert(data?.message)

            } else {
                alert(data?.message)
                setLoading(false)
            }

        }).catch((error) => {

            alert(error?.message)
            setLoading(false)

        })


    }

    const handleConfirmChangeEmployeeRole = async (selectedRoles) => {


        setRoleLoading(true)

        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;



        let dataToSend = {
            id: dataClicked?.employee_id,
            role: selectedRoles
        }

        axios.put(`${Base_Uri}changeEmployeeRole`, dataToSend, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {

            let data = res.data
            if (data?.status) {

                setOpenChangeRoleModal(false)


                if (dataClicked?.employee_id == userData?.employeeId) {
                    let newData = { ...userData }
                    newData.role_access = selectedRoles
                    dispatch(edit(newData))
                }

                getAllEmployees()
                setRoleLoading(false)
                alert(data?.message)

            } else {
                alert(data?.message)
                setRoleLoading(false)
            }

        }).catch((error) => {

            alert(error?.message)
            setRoleLoading(false)

        })
    }

    const handleNavigateToSignup = () => {

        navigate('/SignupEmployee')


    }


    const handleChangeRoleAccess = async (updatedRoles) => {




        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;


        setRoleLoading(true)


        let dataToSend = {

            updatedRoles: updatedRoles,
            employeeData: dataClicked
        }

        axios.put(`${Base_Uri}changeEmployeeRoleAccess`, dataToSend, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {

            let data = res.data
            if (data?.status) {

                setEmployees(employees && employees.length > 0 && employees?.map((e, i) => {

                    if (e?.employee_id == data?.data?.employee_id) {
                        return {
                            ...e,
                            role_access: updatedRoles
                        }
                    } else {
                        return e
                    }

                }))

                setOpenRoleAccessModal(false)
                setDataClicked("")
                setRoleLoading(false)
                alert(data?.message)

            }
            else {
                alert(data?.message)
                setRoleLoading(false)
            }
        }).catch((error) => {

            alert(error?.message)
            setRoleLoading(false)

        })


    }


    const handleEditEmployeeDetails = async (updatedData) => {

        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;


        // setEditEmployeeLoading(true)


        const passwordRegex = /^(?=.*[A-Z])(?=.*[@]).{8,}$/;

        if (updatedData?.password && !passwordRegex.test(updatedData?.password)) {
            alert("Password must include at least one uppercase letter, one '@' symbol, and be at least 8 characters long.");
            return false;
        }



        let dataToSend = {
            employeeId: updatedData?.employeeId,
            id: dataClicked?._id,
            fullName: updatedData?.fullName,
            emailAddress: updatedData?.emailAddress,
            password: updatedData.password
        }





        axios.put(`${Base_Uri}update_employee_details`, dataToSend, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {

            let data = res.data
            if (data?.status) {


                const updatedEmployees = employees && employees?.length > 0 && employees.map((e, i) => {
                    if (e?._id === data?.data?._id) {
                        return {
                            ...data?.data,
                            id: i + 1
                        };
                    } else {
                        return {
                            ...e,
                            id: i + 1
                        };
                    }
                });




                setEmployees(updatedEmployees)
                setOpenEditEmployeeModal(false)
                setDataClicked("")
                dispatch(updateAllEmployees(updatedEmployees))
                setEditEmployeeLoading(false)
                alert(data?.message)

            }
            else {
                alert(data?.message)
                setRoleLoading(false)
            }
        }).catch((error) => {

            alert(error?.message)
            setEditEmployeeLoading(false)

        })

    }

    const handleChangePassword = async (password) => {


        if(!password) {
            alert("new password missing")
            return
        }

        const passwordRegex = /^(?=.*[A-Z])(?=.*[@]).{8,}$/;

        if (!passwordRegex.test(password)) {
            alert("Password must include at least one uppercase letter, one '@' symbol, and be at least 8 characters long.");
            return false;
        }


        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;
    
        

        let dataToSend = {
            newPassword : password,
            id : userData?.id
        }

        console.log(dataToSend)
        setChangeAdminPassLoading(true)
        try{


            let response = await axios.put(`${Base_Uri}changeAdminPassword`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })

            let data = response.data

            if(data?.status){

            
                alert("Password changed successfully")
                setChangeAdminPassLoading(false)
                setOpenChangePasswordModal(false)


                return

            }


            alert(data.message)
            setChangeAdminPassLoading(false)

            

        }catch(err){

            alert("Internal Server Error")
            setChangeAdminPassLoading(false)
            return

        }



    }

    return (
        loading ? <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minHeight: "70vh", width: "100%" }} >
            <RingLoader loading={loading} size={100} color={Colors.black} />
            <Typography style={{ fontSize: "24px", color: Colors.black, fontFamily: "Poppins" }} >Deleting Data...</Typography>
        </Box> :

            <Box sx={{ padding: "20px", backgroundColor: Colors.white, marginTop: "0px" }} >
                <Navbar currentPage="Employees" />
                <Box sx={{ display: "flex" }} >
                    <Grid container md={12} xs={12} sm={12} >

                        <Grid item xs={12} sm={12} md={6} >
                            <Box sx={{ width: "100%" }} >

                                <Grid md={11} xs={10} sm={10} >
                                    <Box sx={{ width: "100%", marginTop: { xs: "10px", sm: "10px", md: "0px" }, border: `1px solid ${Colors.button}`, borderRadius: "5px", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px 10px 5px 10px", margin: 0 }} >
                                        <TextField
                                            variant="standard" // <== changed this
                                            margin="normal"
                                            required
                                            fullWidth
                                            InputProps={{
                                                // startAdornment: <AccountCircle />, // <== adjusted this
                                                disableUnderline: true,
                                                fontSize: "12px"
                                                // <== added this
                                                // fontSize: "2px"
                                            }}
                                            onChange={handleSearchProducts}
                                            placeholder={"Search Employee Or Email or Employee Id"}
                                            sx={{ width: "90%", fontSize: "12px", padding: 0, fontFamily: "Poppins", fontWeight: "bold", margin: 0 }}
                                        />
                                        <img src={require("../../Images/search.png")} style={{ height: "20px" }} />
                                    </Box>

                                </Grid>




                            </Box>
                        </Grid>

                        <CustomButton text="Create New Employee" onClick={() => handleNavigateToSignup()} style={{ width: "200px", padding: 1, borderRadius: 1, fontSize: "14px" }} />

                        {userData?.role == "admin" && <CustomButton text="Update Password" onClick={() => setOpenChangePasswordModal(true)} style={{ width: "200px", padding: 1, borderRadius: 1, fontSize: "14px" ,marginLeft:"10px"}} />
}

                    </Grid>
                </Box>



                <Box sx={{ height: "500px", backgroundColor: "white", marginTop: 5 }} >



                    <div style={{ width: "100%" }}>
                        <DataGrid
                            key={gridKey}
                            rows={(searchData && searchData?.length > 0) ? searchData : (employees && employees.length > 0) ? employees : []}
                            columns={columns}

                            sx={{
                                minHeight: 500, width: "100%",


                                '& .MuiDataGrid-cell': {
                                    fontFamily: 'Poppins',
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    color: "#58595B"
                                    // Change to your desired cell text color
                                },

                                // '& .MuiDataGrid-columnHeader': {
                                //     backgroundColor: Colors.black,
                                //     color: "white",
                                //     fontWeight: 700,
                                //     border: "2px solid white",
                                //     margin: 0,
                                //     padding: '8px', // Adjust this value as needed
                                //     alignItems: "center"
                                // },
                                '& .MuiDataGrid-colCell, & .MuiDataGrid-colCellTitle': {
                                    padding: '0px', // Adjust this value as needed
                                },
                                '& .MuiDataGrid-columnHeaderCheckbox': {
                                    backgroundColor: Colors.black, // Change this to your desired checkbox background color
                                    color: 'white', // Change this to your desired checkbox color
                                },
                                // '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked':{
                                //     color:"blue" 
                                // },

                                '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root': {
                                    color: "gray"
                                }
                                // '& .MuiDataGrid-columnHeaderCheckbox': {
                                //     backgroundColor: Colors.white
                                // },
                                ,
                                '& .MuiDataGrid-columnHeader': {
                                    fontFamily: "Poppins",
                                    // color: "black",
                                    fontWeight: "bold",
                                },

                            }}
                        />
                    </div>

                </Box>

                <CustomModal
                    heading={`Delete Employee`}
                    loading={loading}
                    text={"Are you sure you want to delete this employee?"}
                    open={openDeleteModal}
                    closeModal={() => setOpenDeleteModal(false)}
                    confirm={handleConfirmDeleteEmployee}
                />

                <CustomModal
                    heading={`Change Employee Status`}
                    loading={loading}
                    text={`Are you sure you want to ${dataClicked?.status ? dataClicked?.status == "active" ? "block" : "active" : "block"} this employee?`}
                    open={openStatusModal}
                    closeModal={() => setOpenStatusModal(false)}
                    confirm={handleConfirmChangeStatus}
                />


                <CustomModal
                    heading={`Show Price Status`}
                    loading={loading}
                    text={`Are you sure you want to ${dataClicked?.show_price ? dataClicked?.show_price ? "hide" : "show" : "show"} cost price to this employee?`}
                    open={openPriceShowModal}
                    closeModal={() => setOpenPriceShowModal(false)}
                    confirm={handleConfirmChangePriceShow}
                />

                <EmployeeRoleModal
                    heading={`Change Role`}
                    loading={roleLoading}
                    open={openChangeRoleModal}
                    closeModal={() => setOpenChangeRoleModal(false)}
                    confirm={handleConfirmChangeEmployeeRole}
                />

                <RoleAccessModal
                    heading={`Role Access`}
                    loading={roleLoading}
                    employeeData={dataClicked}
                    open={openRoleAccessModal}
                    closeModal={() => setOpenRoleAccessModal(false)}
                    confirm={(roleAccess) => handleChangeRoleAccess(roleAccess)}
                />


                <EditEmployeeDetailsModal
                    heading={`Edit Employee Details`}
                    loading={editEmployeeLoading}
                    employeeDetails={dataClicked}
                    open={openEditEmployeeModal}
                    buttonText={"Save"}
                    closeModal={() => setOpenEditEmployeeModal(false)}
                    confirm={(details) => handleEditEmployeeDetails(details)}
                />


                <AdminChangePasswordModal
                    heading={`update password`}
                    loading={changeAdminPassLoading}
                    // text={`Are you sure you want to ${dataClicked?.show_price ? dataClicked?.show_price ? "hide" : "show" : "show"} cost price to this employee?`}
                    open={openChangePasswordModal}
                    closeModal={() => setOpenChangePasswordModal(false)}
                    confirm={handleChangePassword}
                />

            </Box>
    )
}

export default Employees
