import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Colors } from '../../Constant/Color'
import CustomButton from '../../Components/Button'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import BasicSelect from '../../Components/Dropdown';
import { DataGrid } from "@mui/x-data-grid"
import Checkbox from '../../Components/checkbox';
import { CheckBox, ConnectingAirportsOutlined } from '@mui/icons-material';
import { Navigate, useNavigate } from 'react-router-dom';
import { RingLoader } from "react-spinners";
import axios from 'axios';
import { Base_Uri } from '../../Constant/BaseUri';
import { useDispatch, useSelector } from 'react-redux';
import { updateProducts } from '../../Redux/LoginReduces';
import RestockModal from '../../Components/restockmodal';
import AddProductModal from '../../Components/addProductModal';
import SaleHistoryModal from '../../Components/saleHistoryModal';
import SaleReturnHistoryModal from '../../Components/saleReturnHistoryModal';
import LedgerModal from '../../Components/ledgerModal';
import CustomModal from '../../Components/modal';
import BarcodePrintModal from '../../Components/printBarcodeModal';
import DescriptionModal from '../../Components/DescriptionModal';
import QRCode from 'react-qr-code';



function Products({ barcodeData, setBarcodeRead }) {


  const navigate = useNavigate()
  const dispatch = useDispatch()
  const products = useSelector(state => state?.loginReducer?.products)

  const userData = useSelector((state) => state.loginReducer.updatedState);



  const [category, setCategory] = useState([
    "mobile",
    "electronics",
    "clothes",
    "jewelery"
  ])
  const [selectedRows, setSelectedRows] = useState([])
  const [gridKey, setGridKey] = useState(0); // Add this state variable

  const [labelHeight, setLabelHeight] = useState(40)
  const [labelWidth, setLabelWidth] = useState(25)



  const [loader, setLoader] = useState({
    delete: false,
    active: false,
    inactive: false
  })

  const [ledgerLoading, setLedgerLoading] = useState(false)

  const [filter, setFilter] = useState({
    active: false,
    inactive: false,
    restock: false,
    all: true
  })

  const [openDescriptionModal, setOpenDescriptionModal] = useState(false)

  const [productClicked, setProductClicked] = useState("")
  const [product, setProducts] = useState([])
  const [dataClicked, setDataClicked] = useState("")

  const [restockModal, setRestockModal] = useState(false)
  const [openAddProduct, setOpenAddProduct] = useState(false)
  const [openSaleHistoryModal, setOpenSaleHistoryModal] = useState(false)
  const [openSaleReturnHistoryModal, setOpenSaleReturnHistoryModal] = useState(false)
  const [openLedgerModal, setOpenLedgerModal] = useState(false)
  const [openBarcodePrintModal, setOpenBarcodePrintModal] = useState(false)
  const [withPrice, setWithPrice] = useState(false)

  useEffect(() => {

    if (products && products?.length > 0) {
      let allProducts = [...products]

      allProducts = allProducts && allProducts.length > 0 && allProducts?.map((e, i) => {

        if (Number(e?.qty) <= Number(e?.reminder_qty)) {


          return {
            ...e,
            status: "Restock"
          }


        } else {
          return e
        }

      })


      setProducts(allProducts)
      dispatch(updateProducts(allProducts))

    }

  }, [products.length])


  const [searchCategory, setSearchCategory] = useState([])
  const [loading, setLoading] = useState(false)
  const [showcost, setShowCost] = useState(true)



  const handleOpenDescriptionModal = (product) => {


    setDataClicked(product)
    setOpenDescriptionModal(true)

  }


  const reloadPage = () => {

    window.location.reload()

  }

  const [column, setColumn] = useState
    ([
      {
        field: "id",
        headerName: "ID",
        width: "50",
        headerClassName: 'super-app-theme--header',
      },

      {
        field: "barcode",
        headerName: "Barcode",
        width: "150",
        headerClassName: 'super-app-theme--header',
      },
      {
        field: "product_name",
        headerName: "Product Name",
        headerClassName: 'super-app-theme--header',
        minWidth: "350",
      },


      {
        field: "qty",
        headerClassName: 'super-app-theme--header',
        headerName: "Quantity",
        width: "150",
      },
      {
        field: "cost_price",
        headerClassName: 'super-app-theme--header',
        headerName: "Cost",
        width: "150",
        renderCell: (cellValues) => {

          return (
            <p>{Number(cellValues?.row?.cost_price).toFixed(2)}</p>
          )
        }
      },
      {
        field: "trade_price",
        headerName: "Trade Price",
        headerClassName: 'super-app-theme--header',
        width: "150",
        renderCell: (cellValues) => {

          return (
            <p>{Number(cellValues?.row?.trade_price).toFixed(2)}</p>
          )
        }
      },
      {
        field: "warehouse_price",
        headerName: "Warehouse Price",
        headerClassName: 'super-app-theme--header',
        width: "150",
        renderCell: (cellValues) => {

          return (
            <p>{Number(cellValues?.row?.warehouse_price).toFixed(2)}</p>
          )
        }
      },

      {
        field: "retail_price",
        headerName: "Retail Price",
        headerClassName: 'super-app-theme--header',
        width: "150",
        renderCell: (cellValues) => {

          return (
            <p>{Number(cellValues?.row?.retail_price).toFixed(2)}</p>
          )
        }
      },
      {
        field: "compatibility",
        headerName: "Compatibility",
        headerClassName: 'super-app-theme--header',
        width: 550,
        renderCell: (cellValues) => {

          return (
            <p>{cellValues?.row?.compatibility ? cellValues?.row?.compatibility : "-"}</p>
          )

        }
      },
      {
        field: "department",
        headerName: "Department",
        headerClassName: 'super-app-theme--header',
        width: "150",
      },
      {
        field: "category",
        headerName: "Category",
        headerClassName: 'super-app-theme--header',
        width: "150",
      },
      {
        field: "sub_category",
        headerName: "Subcategory",
        headerClassName: 'super-app-theme--header',
        width: "150",
      },
      {
        field: "make",
        headerName: "Make",
        headerClassName: 'super-app-theme--header',
        width: "150",
      },
      {
        field: "model",
        headerName: "Model",
        headerClassName: 'super-app-theme--header',
        width: "150",
      },
      {
        field: "product_color",
        headerName: "Color",
        headerClassName: 'super-app-theme--header',
        width: "150",
      },

      {
        field: "IMEI",
        headerName: "IMEI",
        headerClassName: 'super-app-theme--header',
        width: "150",
        renderCell: (cellValues) => {

          return (
            <p>{cellValues?.row?.IMEI ? cellValues?.row?.IMEI : "-"}</p>
          )

        }
      },

      {
        field: "status",
        headerName: "Status",
        headerClassName: 'super-app-theme--header',
        width: "150",
        renderCell: (cellValues) => {
          return <p style={{ color: cellValues.value == "Active" ? Colors.green : cellValues.value == "Restock" ? "#DC9E00" : cellValues?.value == "Out Of Stock" ? Colors.red : "purple", fontFamily: "Poppins", fontWeight: "bold" }} >
            {cellValues?.value}
          </p>

        }
      },

      {
        field: "product_description",
        headerName: "Description",
        headerClassName: 'super-app-theme--header',
        width: "150",
        renderCell: (cellValues) => {
          return <CustomButton onClick={() => handleOpenDescriptionModal(cellValues?.row)} text={"Show Description"} style={{ width: 200, padding: 1, borderRadius: 1, fontSize: 12 }} />

        }
      },
    ])


  useEffect(() => {


    if (userData && userData?.role !== "admin") {

      let roleAccess = userData?.role_access

      let stockBooking = roleAccess && roleAccess?.length > 0 && roleAccess?.find((e) => e.name == "ShowCostPrice" && e?.selected)

      if (stockBooking?.selected) {

        const columns =
          [
            {
              field: "id",
              headerName: "ID",
              width: "50",
              headerClassName: 'super-app-theme--header',
            },

            {
              field: "barcode",
              headerName: "Barcode",
              width: "150",
              headerClassName: 'super-app-theme--header',
            },
            {
              field: "product_name",
              headerName: "Product Name",
              headerClassName: 'super-app-theme--header',
              minWidth: "350",
            },


            {
              field: "qty",
              headerClassName: 'super-app-theme--header',
              headerName: "Quantity",
              width: "150",
            },
            {
              field: "cost_price",
              headerClassName: 'super-app-theme--header',
              headerName: "Cost",
              width: "150",
              renderCell: (cellValues) => {

                return (
                  <p>{Number(cellValues?.row?.cost_price).toFixed(2)}</p>
                )
              }
            },
            {
              field: "trade_price",
              headerName: "Trade Price",
              headerClassName: 'super-app-theme--header',
              width: "150",
              renderCell: (cellValues) => {

                return (
                  <p>{Number(cellValues?.row?.trade_price).toFixed(2)}</p>
                )
              }
            },
            {
              field: "warehouse_price",
              headerName: "Warehouse Price",
              headerClassName: 'super-app-theme--header',
              width: "150",
              renderCell: (cellValues) => {

                return (
                  <p>{Number(cellValues?.row?.warehouse_price).toFixed(2)}</p>
                )
              }
            },

            {
              field: "retail_price",
              headerName: "Retail Price",
              headerClassName: 'super-app-theme--header',
              width: "150",
              renderCell: (cellValues) => {

                return (
                  <p>{Number(cellValues?.row?.retail_price).toFixed(2)}</p>
                )
              }
            },
            {
              field: "compatibility",
              headerName: "Compatibility",
              headerClassName: 'super-app-theme--header',
              width: 550,
              renderCell: (cellValues) => {

                return (
                  <p>{cellValues?.row?.compatibility ? cellValues?.row?.compatibility : "-"}</p>
                )

              }
            },
            {
              field: "department",
              headerName: "Department",
              headerClassName: 'super-app-theme--header',
              width: "150",
            },
            {
              field: "category",
              headerName: "Category",
              headerClassName: 'super-app-theme--header',
              width: "150",
            },
            {
              field: "sub_category",
              headerName: "Subcategory",
              headerClassName: 'super-app-theme--header',
              width: "150",
            },
            {
              field: "make",
              headerName: "Make",
              headerClassName: 'super-app-theme--header',
              width: "150",
            },
            {
              field: "model",
              headerName: "Model",
              headerClassName: 'super-app-theme--header',
              width: "150",
            },
            {
              field: "product_color",
              headerName: "Color",
              headerClassName: 'super-app-theme--header',
              width: "150",
            },

            {
              field: "IMEI",
              headerName: "IMEI",
              headerClassName: 'super-app-theme--header',
              width: "150",
              renderCell: (cellValues) => {

                return (
                  <p>{cellValues?.row?.IMEI ? cellValues?.row?.IMEI : "-"}</p>
                )

              }
            },

            {
              field: "status",
              headerName: "Status",
              headerClassName: 'super-app-theme--header',
              width: "150",
              renderCell: (cellValues) => {
                return <p style={{ color: cellValues.value == "Active" ? Colors.green : cellValues.value == "Restock" ? "#DC9E00" : cellValues?.value == "Out Of Stock" ? Colors.red : "purple", fontFamily: "Poppins", fontWeight: "bold" }} >
                  {cellValues?.value}
                </p>

              }
            },

            {
              field: "product_description",
              headerName: "Description",
              headerClassName: 'super-app-theme--header',
              width: "150",
              renderCell: (cellValues) => {
                return <CustomButton onClick={() => handleOpenDescriptionModal(cellValues?.row)} text={"Show Description"} style={{ width: 200, padding: 1, borderRadius: 1, fontSize: 12 }} />

              }
            },
          ]

        setColumn(columns)




      } else {

        const columns =
          [
            {
              field: "id",
              headerName: "ID",
              width: "50",
              headerClassName: 'super-app-theme--header',
            },

            {
              field: "barcode",
              headerName: "Barcode",
              width: "150",
              headerClassName: 'super-app-theme--header',
            },
            {
              field: "product_name",
              headerName: "Product Name",
              headerClassName: 'super-app-theme--header',
              minWidth: "350",
            },


            {
              field: "qty",
              headerClassName: 'super-app-theme--header',
              headerName: "Quantity",
              width: "150",
            },
            {
              field: "trade_price",
              headerName: "Trade Price",
              headerClassName: 'super-app-theme--header',
              width: "150",
              renderCell: (cellValues) => {

                return (
                  <p>{Number(cellValues?.row?.trade_price).toFixed(2)}</p>
                )
              }
            },
            {
              field: "warehouse_price",
              headerName: "Warehouse Price",
              headerClassName: 'super-app-theme--header',
              width: "150",
              renderCell: (cellValues) => {

                return (
                  <p>{Number(cellValues?.row?.warehouse_price).toFixed(2)}</p>
                )
              }
            },

            {
              field: "retail_price",
              headerName: "Retail Price",
              headerClassName: 'super-app-theme--header',
              width: "150",
              renderCell: (cellValues) => {

                return (
                  <p>{Number(cellValues?.row?.retail_price).toFixed(2)}</p>
                )
              }
            },
            {
              field: "compatibility",
              headerName: "Compatibility",
              headerClassName: 'super-app-theme--header',
              width: 550,
              renderCell: (cellValues) => {

                return (
                  <p>{cellValues?.row?.compatibility ? cellValues?.row?.compatibility : "-"}</p>
                )

              }
            },
            {
              field: "department",
              headerName: "Department",
              headerClassName: 'super-app-theme--header',
              width: "150",
            },
            {
              field: "category",
              headerName: "Category",
              headerClassName: 'super-app-theme--header',
              width: "150",
            },
            {
              field: "sub_category",
              headerName: "Subcategory",
              headerClassName: 'super-app-theme--header',
              width: "150",
            },
            {
              field: "make",
              headerName: "Make",
              headerClassName: 'super-app-theme--header',
              width: "150",
            },
            {
              field: "model",
              headerName: "Model",
              headerClassName: 'super-app-theme--header',
              width: "150",
            },
            {
              field: "product_color",
              headerName: "Color",
              headerClassName: 'super-app-theme--header',
              width: "150",
            },

            {
              field: "IMEI",
              headerName: "IMEI",
              headerClassName: 'super-app-theme--header',
              width: "150",
              renderCell: (cellValues) => {

                return (
                  <p>{cellValues?.row?.IMEI ? cellValues?.row?.IMEI : "-"}</p>
                )

              }
            },

            {
              field: "status",
              headerName: "Status",
              headerClassName: 'super-app-theme--header',
              width: "150",
              renderCell: (cellValues) => {
                return <p style={{ color: cellValues.value == "Active" ? Colors.green : cellValues.value == "Restock" ? "#DC9E00" : cellValues?.value == "Out Of Stock" ? Colors.red : "purple", fontFamily: "Poppins", fontWeight: "bold" }} >
                  {cellValues?.value}
                </p>

              }
            },

            {
              field: "product_description",
              headerName: "Description",
              headerClassName: 'super-app-theme--header',
              width: "150",
              renderCell: (cellValues) => {
                return <CustomButton onClick={() => handleOpenDescriptionModal(cellValues?.row)} text={"Show Description"} style={{ width: 200, padding: 1, borderRadius: 1, fontSize: 12 }} />

              }
            },
          ]

        setColumn(columns)

      }

    }



  }, [userData])



  const getProducts = async () => {

    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data

    axios.get(`${Base_Uri}getProducts`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Include other headers as needed
      },
    }).then((res) => {

      let myData = res.data

      let { data } = myData

      if (data && data.length > 0) {

        let allProducts = []

        for (var i = 0; i < data.length; i++) {

          let product = data[i]

          product.id = i + 1
          product["productName"] = product?.product_name


          if (product.status == "active") {

            product.status = "Active"

          }

          if (product?.qty <= product?.reminder_qty) {

            product.status = "Restock"

          }

          allProducts.push(product)



        }

        setProducts(allProducts)
        dispatch(updateProducts(allProducts))

      }


    }).catch((error) => {

      console.log(error, "error")
      // setLoading(false)

    })


  }

  useEffect(() => {


    getProducts()

  }, [])


  useEffect(() => {

    let category = product && product?.length > 0 && product.map((e, i) => {
      return e.category
    })
    if (category) {
      category = [...new Set(category)]
      category.unshift("All Categories")
      setCategory(category)
    }

  }, [product.length])

  const selectProduct = (id) => {

    setProducts(product.map((e, i) => {
      if (e.id == id) {
        return {
          ...e,
          selected: !e?.selected
        }
      } else {
        return e
      }
    }))
  }

  // const selectAllProduct = () => {
  //     setSelectAll({
  //         ...selectAll,
  //         selected: !selectAll.selected
  //     })

  //     setProducts(product.map((e, i) => {
  //         return {
  //             ...e,
  //             selected: selectAll ? false : true
  //         }
  //     }))

  // }



  const [selectedCategory, setSelectedCategory] = useState("select")



  const handleSelectionModelChange = (selectionModel) => {


    if (selectionModel.length > 0) {

      selectionModel = selectionModel.length > 1 ? selectionModel[1] : selectionModel[0]

      setProducts(
        product && product.length > 0
          ? product.map((e) => {
            if (selectionModel == e?.id) {
              return {
                ...e,
                selected: true,
              };
            } else {
              return {
                ...e,
                selected: false,
              };
            }
          })
          : []
      );
    }
    else {
      setProducts(product && product?.length > 0 && product.map((e, i) => {
        return {
          ...e,
          selected: false
        }
      }))
    }
  };


  const handleDeleteProducts = async () => {


    let deleteProducts = product.filter((e, i) => e.selected)

    let otherProducts = product && product.length > 0 && product.filter((e, i) => !e?.selected)




    if (deleteProducts && deleteProducts.length > 0) {


      setLoader({
        ...loader,
        delete: true
      })

      let data = await localStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;


      axios.delete(`${Base_Uri}deleteproducts`, {
        data: deleteProducts,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      }).then((res) => {

        let data = res.data


        if (data.status) {


          let productsToSend = otherProducts && otherProducts.length > 0 && otherProducts.map((e, i) => {

            return {
              ...e,
              id: i + 1,
              selected: false
            }

          })


          setProducts(productsToSend)
          dispatch(updateProducts(productsToSend))
          setSelectedRows([])
          setGridKey(gridKey + 1)
          alert(data.message)

          setLoader({
            ...loader,
            delete: false
          })

        } else {
          alert(data.message)
          setLoader({
            ...loader,
            delete: false
          })
        }


      }).catch((error) => {


        setLoader({
          ...loader,
          delete: false
        })
        alert("sorry there is a server error in deleting the products")

      })

    }





  }

  const handleActiveProducts = async () => {


    let selectedProducts = product && product.length > 0 && product.filter((e, i) => e.selected)

    if (selectedProducts && selectedProducts.length > 0) {

      selectedProducts = selectedProducts.map((e, i) => {
        return {
          ...e,
          status: "Active"
        }
      })



      try {


        setLoader({
          ...loader,
          active: true
        })



        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;



        const response = await axios.put(`${Base_Uri}changeProductStatus`, selectedProducts, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        // Handle the response here
        let res = response.data

        if (!res.status) {

          alert(res.message)
          setLoader({
            ...loader,
            active: false
          })

        } else {




          let updatedProducts = product && product.length > 0 && product.map((e, i) => {
            if (e.selected) {
              return {
                ...e,
                status: "Active"
              }
            } else {
              return e
            }
          })

          setProducts(updatedProducts)
          dispatch(updateProducts(updatedProducts))
          setGridKey(gridKey + 1)
          setSelectedRows([])
          alert(res.message)


          setLoader({
            ...loader,
            active: false
          })



        }


        // If you want to update the state based on the API response, you can do something like:
        // setProducts(updatedProducts);

      } catch (error) {
        // Handle errors here
        console.error('Error:', error);

        alert(error.message)
      }


    } else {
      alert("Kindly select product")
    }

  }


  const handleInActiveProducts = async () => {
    let selectedProducts = product && product.length > 0 && product.filter((e, i) => e.selected)



    if (selectedProducts && selectedProducts.length > 0) {

      selectedProducts = selectedProducts.map((e, i) => {
        return {
          ...e,
          status: "Inactive"
        }
      })


      try {


        setLoader({
          ...loader,
          inactive: true
        })



        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;

        const response = await axios.put(`${Base_Uri}changeProductStatus`, selectedProducts, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        // Handle the response here
        let res = response.data

        if (!res.status) {

          alert(res.message)
          setLoader({
            ...loader,
            inactive: false
          })

        } else {

          let updatedProducts = product && product.length > 0 && product.map((e, i) => {
            if (e.selected) {
              return {
                ...e,
                status: "Inactive"
              }
            } else {
              return e
            }
          })

          setProducts(updatedProducts)
          dispatch(updateProducts(updatedProducts))
          setGridKey(gridKey + 1)
          setSelectedRows([])
          alert(res.message)

          setLoader({
            ...loader,
            inactive: false
          })



        }


        // If you want to update the state based on the API response, you can do something like:
        // setProducts(updatedProducts);

      } catch (error) {
        // Handle errors here
        console.error('Error:', error);

        alert(error.message)
      }


    } else {
      alert("Kindly select product")
    }

  }


  const handleFilterCategory = (name) => {


    setSelectedCategory(name)

    if (filter.active && name == "All Categories") {

      setSearchCategory((product && product.length > 0 && product.filter((e, i) => {
        return e.status == "Active"
      })))

    }

    else if (filter.restock && name == "All Categories") {

      setSearchCategory((product && product.length > 0 && product.filter((e, i) => {
        return e.status == "Restock" || e.status == "Out Of Stock"
      })))

    }

    else if (filter.inactive && name == "All Categories") {

      setSearchCategory((product && product.length > 0 && product.filter((e, i) => {
        return e.status.toLowerCase() == "inactive"
      })))

    }

    else if (!filter.active && !filter.restock && !filter?.inactive && name == "All Categories") {
      setSearchCategory([])
    }

    else if (filter.active && name !== "All Categories") {

      setSearchCategory((product && product.length > 0 && product.filter((e, i) => {
        return e.status == "Active" && e.category == name
      })))


    }

    else if (filter.restock && name !== "All Categories") {

      setSearchCategory((product && product.length > 0 && product.filter((e, i) => {
        return (e.status.toLowerCase() == "restock" || e.status == ("Out Of Stock")) && e.category == name
      })))


    }

    else if (filter.inactive && name !== "All Categories") {

      setSearchCategory((product && product.length > 0 && product.filter((e, i) => {
        return e.status.toLowerCase() == "inactive" && e.category == name
      })))

    }

    else {


      setSearchCategory(product && product.length > 0 && product.filter((e, i) => {
        return e.category == name
      }))
    }

  }


  const filterRestockProduct = () => {

    setFilter({
      ...filter,
      restock: !filter.restock,
      active: false,
      inactive: false,
      all: false
    })

    if (filter.restock && selectedCategory == "All Categories") {
      setSearchCategory([])
      return
    }

    if (filter.restock && selectedCategory !== "All Categories") {
      setSearchCategory(product && product.length > 0 && product.filter((e, i) => {
        return e.category == selectedCategory
      }))

      return
    }

    if (!filter.restock && (selectedCategory == "All Categories" || selectedCategory == "select")) {
      setSearchCategory(product && product.length > 0 && product.filter((e, i) => {
        return e.status == "Restock" || e.status == "Out Of Stock"
      }))
      return
    }

    if (!filter.restock && selectedCategory !== "All Categories") {
      setSearchCategory(product && product.length > 0 && product.filter((e, i) => {
        return e.category == selectedCategory && (e.status == "Restock" || e.status == "Out Of Stock")
      }))

      return
    }





    if (selectedCategory !== "select") {
      setSearchCategory(product && product.length > 0 && product.filter((e, i) => {
        return (e.status == "Restock" || e.status == "Out Of Stock") && e.category == selectedCategory
      }))
      return
    }

    setSearchCategory(product && product.length > 0 && product.filter((e, i) => {
      return e.status == "Restock" || e.status == "Out Of Stock"
    }))

  }


  const filterActiveProduct = () => {

    setFilter({
      ...filter,
      restock: false,
      active: !filter.active,
      inactive: false,
      all: false
    })

    if (filter.active && selectedCategory == "All Categories") {
      setSearchCategory([])
      return
    }

    if (filter.active && selectedCategory !== "All Categories") {
      setSearchCategory(product && product.length > 0 && product.filter((e, i) => {
        return e.category == selectedCategory
      }))

      return
    }

    if (!filter.active && (selectedCategory == "All Categories" || selectedCategory == "select")) {
      setSearchCategory(product && product.length > 0 && product.filter((e, i) => {
        return e.status == "Active"
      }))
      return
    }

    if (!filter.active && selectedCategory !== "All Categories") {
      setSearchCategory(product && product.length > 0 && product.filter((e, i) => {
        return e.category == selectedCategory && e.status == "Active"
      }))

      return
    }





    if (selectedCategory !== "select") {
      setSearchCategory(product && product.length > 0 && product.filter((e, i) => {
        return e.status == "Active" && e.category == selectedCategory
      }))
      return
    }

    setSearchCategory(product && product.length > 0 && product.filter((e, i) => {
      return e.status == "Active"
    }))

  }


  // const filterActiveProduct = () => {

  //     setFilter({
  //         ...filter,
  //         restock: false,
  //         active: !filter.active,
  //         inactive: false,
  //         all: false
  //     })

  //     if (filter.active && selectedCategory == "All Categories") {
  //         setSearchCategory([])
  //         return
  //     }

  //     if (filter.active && selectedCategory !== "All Categories") {
  //         setSearchCategory(product && product.length > 0 && product.filter((e, i) => {
  //             return e.category == selectedCategory
  //         }))

  //         return
  //     }
  //     if (selectedCategory !== "select") {
  //         setSearchCategory(product && product.length > 0 && product.filter((e, i) => {
  //             return e.status == "Active" && e.category == selectedCategory
  //         }))
  //         return
  //     }
  //     setSearchCategory(product && product.length > 0 && product.filter((e, i) => {
  //         return e.status == "Active"
  //     }))

  // }


  const filterInactiveProduct = () => {

    setFilter({
      ...filter,
      restock: false,
      active: false,
      inactive: !filter.inactive,
      all: false
    })

    if (filter.inactive && selectedCategory == "All Categories") {
      setSearchCategory([])
      return
    }

    if (filter.inactive && selectedCategory !== "All Categories") {
      setSearchCategory(product && product.length > 0 && product.filter((e, i) => {
        return e.category == selectedCategory
      }))

      return
    }

    if (!filter.inactive && (selectedCategory == "All Categories" || selectedCategory == "select")) {
      setSearchCategory(product && product.length > 0 && product.filter((e, i) => {
        return e.status == "Inactive"
      }))
      return
    }

    if (!filter.inactive && selectedCategory !== "All Categories") {
      setSearchCategory(product && product.length > 0 && product.filter((e, i) => {
        return e.category == selectedCategory && e.status == "Inactive"
      }))

      return
    }


    if (selectedCategory !== "select") {
      setSearchCategory(product && product.length > 0 && product.filter((e, i) => {
        return e.status == "Inactive" && e.category == selectedCategory
      }))
      return
    }

    setSearchCategory(product && product.length > 0 && product.filter((e, i) => {
      return e.status == "Inactive"
    }))

  }


  // const filterInactiveProduct = () => {

  //     setFilter({
  //         ...filter,
  //         restock: false,
  //         active: false,
  //         inactive: !filter.inactive,
  //         all: false
  //     })

  //     if (filter.inactive && selectedCategory == "All Categories") {
  //         setSearchCategory([])
  //         return
  //     }

  //     if (filter.active && selectedCategory !== "All Categories") {
  //         setSearchCategory(product && product.length > 0 && product.filter((e, i) => {
  //             return e.category == selectedCategory
  //         }))

  //         return
  //     }

  //     if (selectedCategory !== "select") {
  //         setSearchCategory(product && product.length > 0 && product.filter((e, i) => {
  //             return e.status == "Inactive" && e.category == selectedCategory
  //         }))
  //         return
  //     }
  //     setSearchCategory(product && product.length > 0 && product.filter((e, i) => {
  //         return e.status == "Inactive"
  //     }))

  // }



  const handleSearchProducts = (e) => {

    let value = e.target.value


    if (!filter.active && !filter.inactive && !filter.restock && (selectedCategory == "All Categories" || selectedCategory == "select")) {

      setSearchCategory(product && product.length > 0 && product.filter((e, i) => {

        return e.productName.toLowerCase().includes(value.toLowerCase()) || e?.barcode?.toString()?.toLowerCase()?.includes(value?.toString()?.toLowerCase())

      }))

      return

    }

    if (filter.active && (selectedCategory == "All Categories" || selectedCategory == "select")) {

      setSearchCategory(product && product.length > 0 && product.filter((e, i) => {

        return (e.productName.toLowerCase().includes(value.toLowerCase()) || e?.barcode?.toString()?.toLowerCase()?.includes(value?.toString()?.toLowerCase())) && e.status == "Active"

      }))
      return
    }

    if (filter.inactive && (selectedCategory == "All Categories" || selectedCategory == "select")) {

      setSearchCategory(product && product.length > 0 && product.filter((e, i) => {

        return (e.productName.toLowerCase().includes(value.toLowerCase()) || e?.barcode?.toString()?.toLowerCase()?.includes(value?.toString()?.toLowerCase())) && e.status == "Inactive"

      }))
      return
    }

    if (filter.restock && (selectedCategory == "All Categories" || selectedCategory == "select")) {

      setSearchCategory(product && product.length > 0 && product.filter((e, i) => {

        return (e.productName.toLowerCase().includes(value.toLowerCase()) || e?.barcode?.toString()?.toLowerCase()?.includes(value?.toString()?.toLowerCase())) && (e.status == "Restock" || e.status == "Out Of Stock")

      }))
      return
    }


    if (filter.active && (selectedCategory !== "All Categories" && selectedCategory !== "select")) {

      setSearchCategory(product && product.length > 0 && product.filter((e, i) => {

        return (e.productName.toLowerCase().includes(value.toLowerCase()) || e?.barcode?.toString()?.toLowerCase()?.includes(value?.toString()?.toLowerCase())) && e.status == "Active" && e.category == selectedCategory

      }))
      return
    }

    if (filter.inactive && (selectedCategory !== "All Categories" && selectedCategory !== "select")) {

      setSearchCategory(product && product.length > 0 && product.filter((e, i) => {

        return (e.productName.toLowerCase().includes(value.toLowerCase()) || e?.barcode?.toString()?.toLowerCase()?.includes(value?.toString()?.toLowerCase())) && e.status == "Inactive" && e.category == selectedCategory

      }))
      return
    }


    if (filter.restock && (selectedCategory !== "All Categories" && selectedCategory !== "select")) {

      setSearchCategory(product && product.length > 0 && product.filter((e, i) => {

        return (e.productName.toLowerCase().includes(value.toLowerCase()) || e?.barcode?.toString()?.toLowerCase()?.includes(value?.toString()?.toLowerCase())) && (e.status == "Restock" || e.status == "Out Of Stock") && e.category == selectedCategory

      }))
      return
    }
  }


  const handleRestockProduct = () => {

    let restocKProduct = product && product.length > 0 && product.filter((e, i) => e.selected)

    if (restocKProduct.length > 0) {

      restocKProduct = restocKProduct[0]

    }

    setProductClicked(restocKProduct)
    setRestockModal(true)
  }

  const handleConfirmUpdate = () => {

    getProducts()
    setSearchCategory([])
    setRestockModal(false)

  }


  const handleOpenAddHistoryModal = () => {


    setLedgerLoading(true)

    let clickedProduct = product && product.length > 0 && product.filter((e, i) => e.selected)
    if (clickedProduct.length > 0) {
      clickedProduct = clickedProduct[0]
    }

    let barcode = clickedProduct?.barcode


    axios.get(`${Base_Uri}getProductLedger/${barcode}`).then((res) => {

      let data = res.data

      if (!data.status) {
        alert(data?.message)
        setLedgerLoading(false)
        return
      }

      let ledgerData = data?.data


      clickedProduct.productLedger = ledgerData

      setProductClicked(clickedProduct)
      setLedgerLoading(false)
      setOpenAddProduct(true)

    }).catch((error) => {

      alert(error?.message)
      setLedgerLoading(false)
      return
    })

  }

  const handleOpenSaleHistoryModal = () => {

    setLedgerLoading(true)

    let clickedProduct = product && product.length > 0 && product.filter((e, i) => e.selected)
    if (clickedProduct.length > 0) {
      clickedProduct = clickedProduct[0]
    }

    let barcode = clickedProduct?.barcode

    axios.get(`${Base_Uri}getProductLedger/${barcode}`).then((res) => {

      let data = res.data

      if (!data.status) {
        alert(data?.message)
        setLedgerLoading(false)
        return
      }

      let ledgerData = data?.data

      clickedProduct.productLedger = ledgerData

      setProductClicked(clickedProduct)
      setLedgerLoading(false)
      setOpenSaleHistoryModal(true)


    }).catch((error) => {

      alert(error?.message)
      setLedgerLoading(false)
      return
    })
  }

  const handleOpenSaleReturnHistoryModal = () => {

    setLedgerLoading(true)

    let clickedProduct = product && product.length > 0 && product.filter((e, i) => e.selected)
    if (clickedProduct.length > 0) {
      clickedProduct = clickedProduct[0]
    }


    let barcode = clickedProduct?.barcode

    axios.get(`${Base_Uri}getProductLedger/${barcode}`).then((res) => {

      let data = res.data

      if (!data.status) {
        alert(data?.message)
        setLedgerLoading(false)
        return
      }

      let ledgerData = data?.data
      clickedProduct.productLedger = ledgerData

      setProductClicked(clickedProduct)
      setLedgerLoading(false)
      setOpenSaleReturnHistoryModal(true)


    }).catch((error) => {

      alert(error?.message)
      setLedgerLoading(false)
      return

    })



  }

  const handleOpenLedgerModal = () => {

    setLedgerLoading(true)

    let clickedProduct = product && product.length > 0 && product.filter((e, i) => e.selected)
    if (clickedProduct.length > 0) {
      clickedProduct = clickedProduct[0]
    }

    let barcode = clickedProduct?.barcode

    axios.get(`${Base_Uri}getProductLedger/${barcode}`).then((res) => {

      let data = res.data

      if (!data.status) {
        setLedgerLoading(false)
        alert(data?.message)
        return
      }

      let ledgerData = data?.data
      clickedProduct.productLedger = ledgerData

      setProductClicked(clickedProduct)
      setLedgerLoading(false)
      setOpenLedgerModal(true)


    }).catch((error) => {

      alert(error?.message)
      setLedgerLoading(false)
      return

    })



  }

  const handleOpenBarcodeModal = (codeKey) => {

    let clickedProduct = product && product.length > 0 && product.filter((e, i) => e.selected)
    if (clickedProduct.length > 0) {
      clickedProduct = clickedProduct[0]
    }
    console.log(clickedProduct, "clickedproduct")
    setProductClicked({...clickedProduct,codeKey})

    setOpenBarcodePrintModal(true)

  }

  const handleEditProduct = () => {

    let clickedProduct = product && product.length > 0 && product.filter((e, i) => e.selected)
    if (clickedProduct.length > 0) {
      clickedProduct = clickedProduct[0]
    }

    clickedProduct.edit = true

    navigate("/Home", {
      state: clickedProduct
    })

  }



  useEffect(() => {
    if (barcodeData?.length > 0) {

      let product =
        products &&
        products?.length > 0 &&
        products?.find((e) => e?.barcode == barcodeData);

      if (product) {
        setSearchCategory([product]);
        setBarcodeRead("");
      }
    }
  }, [barcodeData]);


  return (

    ledgerLoading ? <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minHeight: "70vh", width: "100%" }} >
      <RingLoader loading={ledgerLoading} size={100} color={Colors.black} />
    </Box>

      :


      loading ? <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minHeight: "70vh", width: "100%" }} >
        <RingLoader loading={loading} size={100} color={Colors.black} />
        <Typography style={{ fontSize: "24px", color: Colors.black, fontFamily: "Poppins" }} >Getting Products...</Typography>
      </Box> : <Box sx={{ padding: "20px", backgroundColor: Colors.white, marginTop: "20px" }} >

        <Box sx={{ display: "flex" }} >
          <Grid container md={12} xs={12} sm={12} >

            <Grid item xs={12} sm={12} md={6} >
              <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }} >
                <Grid item xs={1} sm={1} md={1}  >
                  <img src={require("../../Images/filter.png")} height={"15px"} style={{ marginRight: "5px" }} />
                </Grid>
                <Grid item xs={6} sm={2} md={3} lg={3} xl={2.5} style={{ marginRight: "5px", marginTop: { xs: "5px", sm: "0px" } }} >
                  <CustomButton onClick={filterActiveProduct} text="Active" icon={<img src={require("../../Images/cross.png")} height="15px" />} style={{ width: "100%", padding: "5px 5px 5px 5px", fontSize: { xl: "12px", lg: "12px", md: "10px", sm: "10px", xs: "8px" }, display: "flex", justifyContent: "space-around", marginRight: "5px", alignItems: "center", backgroundColor: filter.active ? Colors.button : Colors.white, color: filter.active ? Colors.white : Colors?.button }} />
                </Grid>
                <Grid item xs={6} sm={3} md={4} lg={5} xl={2.5} style={{ marginRight: "5px", marginTop: { xs: "5px", sm: "0px" } }} >
                  <CustomButton onClick={filterRestockProduct} text="Restock Product" style={{ width: "100%", padding: "5px 5px 5px 5px", fontSize: { xl: "12px", lg: "12px", md: "10px", sm: "10px", xs: "8px" }, display: "flex", justifyContent: "space-around", marginRight: "5px", alignItems: "center", backgroundColor: filter.restock ? "#DC9E00" : Colors.white, color: filter.restock ? Colors.white : "#DC9E00", border: `1px solid #DC9E00` }} />
                </Grid>
                <Grid item xs={6} sm={3} md={4} lg={5} xl={2.5} style={{ marginRight: "5px", marginTop: { xs: "5px", sm: "0px" } }} >
                  <CustomButton onClick={filterInactiveProduct} text="Inactive Products" style={{ width: "100%", padding: "5px 5px 5px 5px", fontSize: { xl: "12px", lg: "12px", md: "10px", sm: "10px", xs: "8px" }, display: "flex", justifyContent: "space-around", marginRight: "5px", alignItems: "center", backgroundColor: filter.inactive ? "#DC9E00" : Colors.white, color: filter.inactive ? Colors.white : "#DC9E00", border: `1px solid #DC9E00` }} />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={4} xl={2.5} style={{ marginRight: "5px", marginTop: { xs: "5px", sm: "0px" } }} >
                  <BasicSelect onChange={handleFilterCategory} value={selectedCategory} status="Category" id={category} innerStyle={{ fontSize: "12px", border: `1px solid ${Colors.border}`, borderRadius: 10, fontWeight: "bold" }} style={{ border: "0px" }} />
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} >
              <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >

                <Grid md={11} xs={10} sm={10} >
                  <Box sx={{ width: "100%", marginTop: { xs: "10px", sm: "10px", md: "0px" }, border: `1px solid ${Colors.button}`, borderRadius: "5px", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px 10px 5px 10px", margin: 0 }} >
                    <TextField
                      variant="standard" // <== changed this
                      margin="normal"
                      required
                      fullWidth
                      InputProps={{
                        // startAdornment: <AccountCircle />, // <== adjusted this
                        disableUnderline: true,
                        fontSize: "12px"
                        // <== added this
                        // fontSize: "2px"
                      }}
                      onChange={handleSearchProducts}
                      placeholder="Search Name ,Id , Phone & Bar Code"
                      sx={{ width: "90%", fontSize: "12px", padding: 0, fontFamily: "Poppins", fontWeight: "bold", margin: 0 }}
                    />
                    <img src={require("../../Images/search.png")} style={{ height: "20px" }} />
                  </Box>
                </Grid>

                <Box sx={{ margin: 0, height: "35px", width: "35px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: Colors.button }} >
                  <img src={require("../../Images/qrcode.png")} style={{ height: "20px" }} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>


        {product && product?.length > 0 && product.some((e, i) => e.selected) && <Box sx={{ display: "flex", mt: 1, ml: 1 }} >
          <Grid container md={12} xs={12} sm={12} >
            <Grid item xs={12} sm={12} md={6} >
              <Box sx={{ display: "flex", alignItems: "center" }} >

                <Grid item xs={3} sm={2} md={3} lg={3} xl={2.5} style={{ marginRight: "5px" }} >
                  <CustomButton onClick={() => !loader.delete && handleDeleteProducts()} text={loader.delete ? <RingLoader loading={loader.delete} size={30} /> : "Delete"} style={{ width: "100%", padding: "5px 5px 5px 5px", fontSize: { xl: "12px", lg: "12px", md: "10px", sm: "10px", xs: "8px" }, display: "flex", justifyContent: "space-around", marginRight: "5px", alignItems: "center", backgroundColor: Colors.red }} />
                </Grid>
                <Grid item xs={4} sm={3} md={4} lg={5} xl={2.5} style={{ marginRight: "5px" }} >
                  <CustomButton onClick={() => !loader.active && handleActiveProducts()} text={loader.active ? <RingLoader size={30} loading={loader.active} /> : "Active"} style={{ width: "100%", padding: "5px 5px 5px 5px", fontSize: { xl: "12px", lg: "12px", md: "10px", sm: "10px", xs: "8px" }, display: "flex", justifyContent: "space-around", marginRight: "5px", alignItems: "center", backgroundColor: Colors.white, color: "green", border: `1px solid green` }} />
                </Grid>

                <Grid item xs={4} sm={3} md={4} lg={5} xl={2.5} style={{ marginRight: "5px" }} >
                  <CustomButton onClick={handleInActiveProducts} text="Inactive" style={{ width: "100%", padding: "5px 5px 5px 5px", fontSize: { xl: "12px", lg: "12px", md: "10px", sm: "10px", xs: "8px" }, display: "flex", justifyContent: "space-around", marginRight: "5px", alignItems: "center", backgroundColor: Colors.white, color: "purple", border: `1px solid purple` }} />
                </Grid>

                <Grid item xs={4} sm={3} md={4} lg={5} xl={2.5} style={{ marginRight: "5px" }} >
                  {product && product.length > 0 && product.filter((item) => item.selected).length == 1 && <CustomButton onClick={() => handleRestockProduct()} text="Restock" style={{ width: "100%", padding: "5px 5px 5px 5px", fontSize: { xl: "12px", lg: "12px", md: "10px", sm: "10px", xs: "8px" }, display: "flex", justifyContent: "space-around", marginRight: "5px", alignItems: "center", backgroundColor: Colors.white, color: "purple", border: `1px solid purple` }} />}
                </Grid>

                {/* {product && product.length > 0 && product.filter((item) => item.selected).length == 1 && <Grid item xs={4} sm={3} md={4} lg={5} xl={2.5} style={{ marginRight: "5px" }} >
                                <CustomButton onClick={() => navigate("/Home/ProductDetails")} text="View Detail" style={{ width: "100%", padding: "5px 5px 5px 5px", fontSize: { xl: "12px", lg: "12px", md: "10px", sm: "10px", xs: "8px" }, display: "flex", justifyContent: "space-around", marginRight: "5px", alignItems: "center", backgroundColor: Colors.button, color: "white", border: `1px solid purple` }} />
                            </Grid>} */}



              </Box>
            </Grid>

          </Grid>
        </Box>}

        {product && product?.length > 0 && product.some((e, i) => e.selected) && <Box sx={{ display: "flex", mt: 2, ml: 1 }} >
          <Grid container md={12} xs={12} sm={12} >
            <Grid item xs={12} sm={12} md={6} >
              <Box sx={{ display: "flex", alignItems: "center" }} >
                <Grid item xs={3} sm={2} md={3} lg={3} xl={2.5} style={{ marginRight: "5px" }} >
                  {product && product.length > 0 && product.filter((item) => item.selected).length == 1 && <CustomButton onClick={() => handleOpenAddHistoryModal()} text={"Add History"} style={{ width: "100%", padding: "5px 5px 5px 5px", fontSize: { xl: "12px", lg: "12px", md: "10px", sm: "10px", xs: "8px" }, display: "flex", justifyContent: "space-around", marginRight: "5px", alignItems: "center", backgroundColor: Colors.green }} />
                  }
                </Grid>
                <Grid item xs={4} sm={3} md={4} lg={5} xl={2.5} style={{ marginRight: "5px" }} >
                  {product && product.length > 0 && product.filter((item) => item.selected).length == 1 && <CustomButton onClick={() => handleOpenSaleHistoryModal()} text={"Sale History"} style={{ width: "100%", padding: "5px 5px 5px 5px", fontSize: { xl: "12px", lg: "12px", md: "10px", sm: "10px", xs: "8px" }, display: "flex", justifyContent: "space-around", marginRight: "5px", alignItems: "center", backgroundColor: Colors.white, color: "green", border: `1px solid green` }} />
                  }
                </Grid>
                <Grid item xs={4} sm={3} md={4} lg={5} xl={2.5} style={{ marginRight: "5px" }} >
                  {product && product.length > 0 && product.filter((item) => item.selected).length == 1 && <CustomButton onClick={() => handleOpenSaleReturnHistoryModal()} text="Sale Return History" style={{ width: "100%", padding: "5px 5px 5px 5px", fontSize: { xl: "12px", lg: "12px", md: "10px", sm: "10px", xs: "8px" }, display: "flex", justifyContent: "space-around", marginRight: "5px", alignItems: "center", backgroundColor: Colors.white, color: "purple", border: `1px solid purple` }} />
                  }
                </Grid>
                <Grid item xs={4} sm={3} md={4} lg={5} xl={2.5} style={{ marginRight: "5px" }} >
                  {product && product.length > 0 && product.filter((item) => item.selected).length == 1 && <CustomButton onClick={() => handleOpenLedgerModal()} text="Ledger" style={{ width: "100%", padding: "5px 5px 5px 5px", fontSize: { xl: "12px", lg: "12px", md: "10px", sm: "10px", xs: "8px" }, display: "flex", justifyContent: "space-around", marginRight: "5px", alignItems: "center", backgroundColor: Colors.white, color: "purple", border: `1px solid purple` }} />}
                </Grid>
              </Box>
            </Grid>


          </Grid>
        </Box>}

        <Grid container xs={12} >
          <Grid item xs={2} sm={3} md={2} lg={3} xl={2.5} style={{ marginRight: "10px", marginTop: 10 }} >
            {product && product.length > 0 && product.filter((item) => item.selected).length == 1 && <CustomButton onClick={() => handleOpenBarcodeModal("bar")} text="Print Barcode" style={{ width: "200px", padding: "5px 5px 5px 5px", fontSize: { xl: "12px", lg: "12px", md: "10px", sm: "10px", xs: "8px" }, display: "flex", justifyContent: "space-around", marginRight: "5px", alignItems: "center", borderRadius: 1, padding: 1 }} />}
          </Grid>
          <Grid item xs={2} sm={3} md={2} lg={3} xl={2.5} style={{ marginRight: "10px", marginTop: 10 }} >
            {product && product.length > 0 && product.filter((item) => item.selected).length == 1 && <CustomButton onClick={() => handleOpenBarcodeModal("qr")} text="Print Qr Code" style={{ width: "200px", padding: "5px 5px 5px 5px", fontSize: { xl: "12px", lg: "12px", md: "10px", sm: "10px", xs: "8px" }, display: "flex", justifyContent: "space-around", marginRight: "5px", alignItems: "center", borderRadius: 1, padding: 1 }} />}
          </Grid>

          <Grid item xs={2} sm={3} md={2} lg={3} xl={2.5} style={{ marginRight: "10px", marginTop: 10 }} >
            {product && product.length > 0 && product.filter((item) => item.selected).length == 1 && <CustomButton onClick={() => handleEditProduct()} text="Edit Product" style={{ width: "200px", padding: "5px 5px 5px 5px", fontSize: { xl: "12px", lg: "12px", md: "10px", sm: "10px", xs: "8px" }, display: "flex", justifyContent: "space-around", marginRight: "5px", alignItems: "center", borderRadius: 1, padding: 1, backgroundColor: Colors.red }} />}
          </Grid>
        </Grid>

        <Box sx={{ height: "500px", backgroundColor: "white", marginTop: 5, minWidth: "100%" }} >
          <DataGrid key={gridKey}
            disableColumnFilter
            sx={{

              '& .MuiDataGrid-cell': {
                fontFamily: 'Poppins',
                fontSize: "12px",
                fontWeight: "600",
                color: "#58595B"
              },
              '& .MuiDataGrid-colCell, & .MuiDataGrid-colCellTitle': {
                padding: '0px', // Adjust this value as needed
              },
              '& .MuiDataGrid-columnHeaderCheckbox': {
                backgroundColor: Colors.black, // Change this to your desired checkbox background color
                color: 'white', // Change this to your desired checkbox color
              },

              '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root': {
                color: "gray"
              }
              ,
              '& .MuiDataGrid-columnHeader': {
                fontFamily: "Poppins",
                // color: "black",
                fontWeight: "bold",
              },


            }}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={handleSelectionModelChange} rowSelectionModel={product && product.length > 0 ? product.filter((e, i) => e?.selected)?.map((row) => row.id) : []} rows={searchCategory.length > 0 ? searchCategory : (product && product?.length > 0 ? product : [])} columns={column} />

          {restockModal && <RestockModal data={productClicked} open={restockModal} confirm={() => handleConfirmUpdate()} closeModal={() => setRestockModal(false)} />}


          {openAddProduct && <AddProductModal data={productClicked} open={openAddProduct} closeModal={() => setOpenAddProduct(false)} />}


          {openSaleHistoryModal && <SaleHistoryModal data={productClicked} open={openSaleHistoryModal} closeModal={() => setOpenSaleHistoryModal(false)} />}


          {openSaleReturnHistoryModal && <SaleReturnHistoryModal data={productClicked} open={openSaleReturnHistoryModal} closeModal={() => setOpenSaleReturnHistoryModal(false)} />}

          {openLedgerModal && <LedgerModal data={productClicked} open={openLedgerModal} closeModal={() => setOpenLedgerModal(false)} />}

         


          {openBarcodePrintModal && <BarcodePrintModal
            heading={`Print Barcode`}
            loading={loading}
            reload={reloadPage}
            data={productClicked}
            setOpenBarcodePrintModal={setOpenBarcodePrintModal}
            open={openBarcodePrintModal}
            closeModal={() => setOpenBarcodePrintModal(false)}
          // confirm={}
          />}


          <DescriptionModal
            open={openDescriptionModal}
            closeModal={() => setOpenDescriptionModal(false)}
            description={dataClicked?.product_description}

          />


        </Box>

      </Box>
  )
}

export default Products
