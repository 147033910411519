import React, { useState } from 'react'
import { Colors } from '../../Constant/Color'
import { Box, Grid, Typography, TextField } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import MessageIcon from '@mui/icons-material/Message';
import CustomButton from '../../Components/Button';
import axios from 'axios';
import { Base_Uri } from '../../Constant/BaseUri';
import { useNavigate } from 'react-router-dom';
import BasicSelect from '../../Components/Dropdown';


function Signup() {

    const [userData, setUserData] = useState({
        fullName: "",
        employeeId: "",
        emailAddress: "",
        password: "",
        role: "select",
        confirmPassword: ""
    })

    let initialErrors = {

        fullName: false,
        employeeId: false,
        emailAddress: false,
        password: false,
        confirmPassword: false

    }


    const [roles, setRoles] = useState(['Manager', 'Sales Assistant'])

    const navigate = useNavigate()

    const [error, setError] = useState(initialErrors)

    function isValidEmail(email) {
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }


    const handleSignup = () => {


        let { emailAddress, password, confirmPassword, employeeId, fullName } = userData


        let error = {}

        if (!userData?.fullName) {

            error.fullName = true

        }

        if (!userData?.employeeId) {

            error.employeeId = true

        }

        if (!userData?.emailAddress) {

            error.emailAddress = true

        }

        if (!isValidEmail(emailAddress)) {
            error.emailAddress = true
        }

        if (!userData?.role || userData?.role == "select") {

            error.role = true

        }


        if (!userData?.password) {

            error.password = true

        }



        const passwordRegex = /^(?=.*[A-Z])(?=.*[@]).{8,}$/;

        if (!passwordRegex.test(userData?.password)) {
            alert("Password must include at least one uppercase letter, one '@' symbol, and be at least 8 characters long.");
            return false;
        }

        if (!userData?.confirmPassword) {

            error.confirmPassword = true

        }

        if (confirmPassword !== password) {
            error.confirmPassword = true
        }


        if (userData?.password?.length < 8 && Object.keys(error).length == 0) {
            error.passwordCharacters = true
            alert("password must be of atleast 8 characters")
        }

        if (error && Object.keys(error).length > 0) {
            setError(error)
            return
        } else {

            setError(initialErrors)


            if (userData?.role == "Manager") {

                userData.roleAccess = [
                    {
                        name: "PointOfSale",
                        selected: true
                    },
                    {
                        name: "AllInvoices",
                        selected: true
                    },
                    {
                        name: "TodayInvoices",
                        selected: false
                    },
                    {
                        name: "CustomerAccount",
                        subcategory: [{
                            name: "CustomerAccountDetails",
                            selected: true
                        },
                        {
                            name: "Transaction",
                            selected: true
                        },
                        ],
                        selected: true
                    },
                    {
                        name: "StockBooking",
                        selected: true,
                        subcategory: [
                            {
                                name: "AddNewStock",
                                selected: true
                            },
                            {
                                name: "Products",
                                selected: true
                            },
                            {
                                name: "Damages",
                                selected: true
                            },
                            {
                                name: "DemandedProduct",
                                selected: true
                            },
                            {
                                name: "ProductArrangement",
                                selected: true
                            },
                            {
                                name: "Bin",
                                selected: true
                            },
                            {
                                name: "PurchaseOrders",
                                selected: true
                            },
                            {
                                name: "Department",
                                selected: true
                            },
                            {
                                name: "ProductMake",
                                selected: true
                            },
                            {
                                name: "ProductColor",
                                selected: true
                            },
                        ],
                    },
                    {
                        name: "Employees",
                        subcategory: [
                            {
                                name: "roles",
                                selected: false
                            }],
                        selected: true
                    },
                    {
                        name: "Suppliers",
                        selected: true
                    },
                    {
                        name: "CompanyInformation",
                        selected: true
                    },
                    {
                        name: "Reports",
                        selected: true
                    },
                    {
                        name: "ShowCostPrice",
                        selected: true
                    },
                    {
                        name: "ShowProfit",
                        selected: true
                    },
                ]


            } else {
                userData.roleAccess = [
                    {
                        name: "PointOfSale",
                        selected: true
                    },
                    {
                        name: "AllInvoices",
                        selected: false
                    },
                    {
                        name: "TodayInvoices",
                        selected: true
                    },
                    {
                        name: "CustomerAccount",
                        subcategory: [{
                            name: "CustomerAccountDetails",
                            selected: false
                        },
                        {
                            name: "Transaction",
                            selected: false
                        },
                        ],
                        selected: false
                    },
                    {
                        name: "StockBooking",
                        selected: false,
                        subcategory: [
                            {
                                name: "AddNewStock",
                                selected: false
                            },
                            {
                                name: "Products",
                                selected: false
                            },
                            {
                                name: "Damages",
                                selected: false
                            },
                            {
                                name: "DemandedProduct",
                                selected: false
                            },
                            {
                                name: "ProductArrangement",
                                selected: false
                            },
                            {
                                name: "Bin",
                                selected: false
                            },
                            {
                                name: "PurchaseOrders",
                                selected: false
                            },
                            {
                                name: "Department",
                                selected: false
                            },
                            {
                                name: "ProductMake",
                                selected: false
                            },
                            {
                                name: "ProductColor",
                                selected: false
                            },
                        ],
                    },
                    {
                        name: "Employees",
                        subcategory: [
                            {
                                name: "roles",
                                selected: false
                            }
                        ],
                        selected: false
                    },
                    {
                        name: "Suppliers",
                        selected: false
                    },
                    {
                        name: "CompanyInformation",
                        selected: false
                    },
                    {
                        name: "Reports",
                        selected: false
                    },
                    {
                        name: "ShowCostPrice",
                        selected: false
                    },
                    {
                        name: "ShowProfit",
                        selected: false
                    },
                ]
            }

            axios.post(`${Base_Uri}signup`, userData).then((res) => {

                let { data } = res


                if (data?.status) {
                    alert("user Registed successfully")
                    navigate("/Employees")
                } else {
                    alert(data?.message)
                }

            }).catch((error) => {

                console.log(error, "error")

            })




        }


    }

    return (
        <Box style={{ color: Colors.black }} >
            <Grid container md={12} sm={12} xs={12} >

                <Grid item xs={0} sm={0} md={7} >

                    <Box className="signupBackground" sx={{ display: { xs: "none", sm: "none", md: "flex" } }} >
                        <Box sx={{ display: "flex", alignItems: "center", minHeight: "100vh", justifyContent: "center" }} >

                            <img src={require("../../Images/signicon.png")} style={{ height: 90 }} />
                            <img src={require("../../Images/saleIcon.png")} style={{ height: 60, marginLeft: 40 }} />

                        </Box>
                    </Box>
                </Grid>



                <Grid item xs={12} sm={12} md={5} >

                    <Box sx={{ backgroundColor: "#EEE", minHeight: "100%", display: "flex", justifyContent: "center" }} >

                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", minHeight: "100vh", width: { xs: "90%", sm: "80%" }, }} >

                            <Typography sx={{ fontFamily: "Poppins", fontSize: "28px", fontWeight: "bold", alignSelf: "flex-start" }} >
                                Hello
                            </Typography>

                            <Typography sx={{ fontFamily: "Poppins", fontSize: "16px", fontWeight: "500", alignSelf: "flex-start" }} >
                                Sign Up to Get Started
                            </Typography>
                            <Box sx={{ marginTop: "20px", width: "90%", alignSelf: "flex-start" }} >
                                <TextField
                                    onChange={(e) => setUserData({ ...userData, fullName: e.target.value })}
                                    placeholder="Full Name"
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    required
                                    fullWidth
                                    sx={{ border: error?.fullName ? "2px solid red" : "0px", width: "100%", fontSize: "10px", padding: "10px", fontFamily: "Poppins", fontWeight: "500", backgroundColor: Colors.white, borderRadius: 10, width: "95%" }}


                                    InputProps={{
                                        disableUnderline: true, // <== added this
                                        startAdornment: (
                                            <InputAdornment position="start">

                                                <img src={require("../../Images/mail.png")} style={{ height: 25 }} />

                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <TextField
                                    onChange={(e) => setUserData({ ...userData, employeeId: e.target.value })}
                                    placeholder="Employee ID"
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    required
                                    fullWidth
                                    sx={{ border: error?.employeeId ? "2px solid red" : "0px", width: "100%", fontSize: "10px", padding: "10px", marginTop: "15px", fontFamily: "Poppins", fontWeight: "500", backgroundColor: Colors.white, borderRadius: 10, width: "95%" }}


                                    InputProps={{
                                        disableUnderline: true, // <== added this
                                        startAdornment: (
                                            <InputAdornment position="start">

                                                <img src={require("../../Images/mail.png")} style={{ height: 25 }} />

                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <TextField
                                    onChange={(e) => setUserData({ ...userData, emailAddress: e.target.value })}
                                    placeholder="Email Address"
                                    type="email"
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    required
                                    fullWidth
                                    sx={{ border: error?.emailAddress ? "2px solid red" : "0px", width: "100%", fontSize: "10px", padding: "10px", marginTop: "15px", fontFamily: "Poppins", fontWeight: "500", backgroundColor: Colors.white, borderRadius: 10, width: "95%" }}


                                    InputProps={{
                                        disableUnderline: true, // <== added this
                                        startAdornment: (
                                            <InputAdornment position="start">

                                                <img src={require("../../Images/lock.png")} style={{ height: 25 }} />

                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <BasicSelect
                                    style={{ marginTop: "15px", backgroundColor: Colors.white, borderRadius: 10 }}
                                    value={userData?.role}
                                    innerStyle={{ padding: 1, borderRadius: 10, border: error?.role ? "2px solid red" : "0px", }}
                                    status="Select Role"
                                    id={roles}
                                    width={"100%"}
                                    onChange={(e) => setUserData({ ...userData, role: e })}
                                />

                                <TextField
                                    onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                                    placeholder="Password"
                                    type="password"
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    required
                                    fullWidth
                                    sx={{ border: error?.password ? "2px solid red" : "0px", width: "100%", fontSize: "10px", padding: "10px", marginTop: "15px", fontFamily: "Poppins", fontWeight: "500", backgroundColor: Colors.white, borderRadius: 10, width: "95%" }}


                                    InputProps={{
                                        disableUnderline: true, // <== added this
                                        startAdornment: (
                                            <InputAdornment position="start">

                                                <img src={require("../../Images/lock.png")} style={{ height: 25 }} />

                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <TextField
                                    onChange={(e) => setUserData({ ...userData, confirmPassword: e.target.value })}
                                    placeholder="Confirm Password"
                                    type="password"
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    required
                                    fullWidth
                                    sx={{ border: error?.confirmPassword ? "2px solid red" : "0px", width: "100%", fontSize: "10px", padding: "10px", marginTop: "15px", fontFamily: "Poppins", fontWeight: "500", backgroundColor: Colors.white, borderRadius: 10, width: "95%" }}
                                    InputProps={{
                                        disableUnderline: true, // <== added this
                                        startAdornment: (
                                            <InputAdornment position="start">

                                                <img src={require("../../Images/lock.png")} style={{ height: 25 }} />

                                            </InputAdornment>
                                        ),
                                    }}
                                />


                                <CustomButton onClick={handleSignup} text="Sign Up" style={{ backgroundColor: Colors.button, margin: "15px 0px 0px 0px", width: "100%", borderRadius: 10, padding: "15px 10px 15px 10px", fontWeight: "500", alignSelf: "center" }} />

                                <CustomButton text="Forgot Password" style={{ backgroundColor: "#EEE", marginTop: "15px", width: "100%", borderRadius: 10, padding: "15px 10px 15px 10px", fontWeight: "500", border: "0px", color: Colors.text, textTransform: "capitalize" }} />



                            </Box>

                        </Box>




                    </Box>
                </Grid>


            </Grid>
        </Box>
    )
}

export default Signup
