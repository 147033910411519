import { Box, Button, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Colors } from '../../Constant/Color'
import CustomButton from '../../Components/Button'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import BasicSelect from '../../Components/Dropdown';
import { DataGrid } from "@mui/x-data-grid"
import Checkbox from '../../Components/checkbox';
import { CheckBox, ProductionQuantityLimitsTwoTone } from '@mui/icons-material';
import { Navigate, useNavigate } from 'react-router-dom';
import { Base_Uri } from '../../Constant/BaseUri';
import axios from 'axios';
import { updateDamageProducts, updateProducts, updateTrashProducts } from '../../Redux/LoginReduces';
import { useDispatch, useSelector } from 'react-redux';
import CustomModal from '../../Components/modal';
import TrashLedgerModal from '../../Components/TrashLedgerModal';
import TrashReturnHistoryModal from '../../Components/TrashReturnModal';
import TrashTransferModal from '../../Components/TrashTransferModal';
import { RingLoader } from 'react-spinners';




function Trash({ barcodeData, setBarcodeRead }) {


  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [category, setCategory] = useState([
    "mobile",
    "electronics",
    "clothes",
    "jewelery"
  ])

  const trashProducts = useSelector(state => state.loginReducer.trashProducts)

  const [addInInventoryModalVisible, setAddInInventoryModalVisible] =
    useState(false);
  const [addInDamagesModalVisible, setAddInDamagesModalVisible] = useState(false);
  const [quantityAddInInventory, setQuantityAddInInventory] = useState(0);
  const [quantityAddInDamages, setQuantityAddInDamages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [gridKey, setGridKey] = useState(0)


  const [openLedgerModal, setOpenLedgerModal] = useState(false);
  const [openReturnHistoryModal, setOpenReturnHistoryModal] = useState(false);
  const [openTransferModal, setOpenTransferModal] = useState(false);

  const [ledgerLoading, setLedgerLoading] = useState(false);


  const [productClicked, setProductClicked] = useState([]);



  const [product, setProducts] = useState([])
  const [searchCategory, setSearchCategory] = useState([])


  const column = [
    {
      field: "id",
      headerName: "ID",
      width: "50",
      headerClassName: 'super-app-theme--header',
    },
    {
      field: "product_name",
      headerName: "Product Name",
      headerClassName: 'super-app-theme--header',
      width: "300",
    },
    {
      field: "category",
      headerName: "Category",
      headerClassName: 'super-app-theme--header',
      width: "200",
    },
    {
      field: "model",
      headerName: "Model",
      headerClassName: 'super-app-theme--header',
      width: "200",
    },

    {
      field: "qty",
      headerClassName: 'super-app-theme--header',
      headerName: "Quantity",
      width: "150",
    },
    {
      field: "cost_price",
      headerClassName: 'super-app-theme--header',
      headerName: "Cost",

      width: "150",
    },
    {
      field: "retail_price",
      headerName: "Price",
      headerClassName: 'super-app-theme--header',
      width: "150",
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: 'super-app-theme--header',
      width: "150",
      renderCell: (cellValues) => {
        return <p style={{ color: Colors.red, fontFamily: "Poppins", fontWeight: "bold" }} >
          {"Trash"}
        </p>

      }
    },
    // {
    //     field: "action",
    //     headerName: "Action",
    //     headerClassName: 'super-app-theme--header',

    //     width: "150",
    // renderCell: (cellValues) => {
    //     return <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "30%", backgroundColor: Colors.white }} >
    //         <button onClick={() => navigate("/Home")} style={{ padding: 0, margin: 0, backgroundColor: Colors.white, border: "0px" }} >
    //             <img src={require("../../Images/edit.png")} style={{ height: "20px", backgroundColor: Colors.white }} />
    //         </button>
    //         {/* <button style={{ padding: 0, margin: 0, border: "0px" }}  >
    //             <img src={require("../../Images/dots.png")} style={{ height: "20px" }} />
    //         </button> */}

    //     </Box>
    // }
    // },


  ]

  const [selectedCategory, setSelectedCategory] = useState("select")








  useEffect(() => {

    getTrashProducts()

  }, [])

  useEffect(() => {

    let updateId = trashProducts && trashProducts.length > 0 && trashProducts.map((e, i) => {

      return {
        ...e,
        id: i + 1,
        productName: e.product_name
      }

    })

    setProducts(updateId)


    let category = trashProducts && trashProducts.length > 0 && trashProducts.map((e, i) => {

      return e.category

    })

    if (category) {
      category = [...new Set(category)]
      category.unshift("All Categories")
      setCategory(category)
    }

  }, [trashProducts, trashProducts?.length])



  const handleFilterCategory = (name) => {

    setSelectedCategory(name)

    if (name == "All Categories") {

      setSearchCategory(product)

    }
    else {

      setSearchCategory(product && product.length > 0 && product.filter((e, i) => {

        if (e.category == name) {
          return e
        }

      }))

    }



  }

  const getTrashProducts = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .get(`${Base_Uri}getTrashProducts`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status) {
          dispatch(updateTrashProducts(data.data));
        }
      });
  };


  const getDamageProducts = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .get(`${Base_Uri}getDamageProducts`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res.data;

          if (data.status) {
            let myData = res.data;

            let { data } = myData;

            if (data && data.length > 0) {
              let allProducts = [];

              for (var i = 0; i < data.length; i++) {
                let product = data[i];

                product.id = i + 1;
                product["productName"] = product?.product_name;

                if (product?.status == "active") {
                  product.status = "Active";
                }

                allProducts.push(product);
              }

              dispatch(updateDamageProducts(allProducts));
            }
          }
        });
    }
  };

  const getProducts = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .get(`${Base_Uri}getProducts`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        let myData = res.data;

        let { data } = myData;

        if (data && data.length > 0) {
          let allProducts = [];

          for (var i = 0; i < data.length; i++) {
            let product = data[i];

            product.id = i + 1;
            product["productName"] = product?.product_name;


            if (product.status == "active") {
              product.status = "Active";
            }

            allProducts.push(product);
          }

          updateProducts(allProducts);
        }
      })
      .catch((error) => {
        console.log(error, "error");
        // setLoading(false)
      });
  };


  const getAllRowData = (event) => {



  }

  const handleSelectionModelChange = (selectionModel) => {

    if (selectionModel.length > 0) {


      selectionModel = selectionModel.length > 1 ? selectionModel[1] : selectionModel[0]


      let allProducts =
        product && product.length > 0
          ? product.map((e) => {
            if (selectionModel?.toString().includes(e.id)) {
              return {
                ...e,
                selected: true,
              };
            } else {
              return {
                ...e,
                selected: false,
              };
            }
          })
          : [];
      // setGridKey(gridKey + 1);
      setProducts(allProducts)
      dispatch(updateTrashProducts(allProducts));
    } else {
      let allProducts =
        product &&
        product?.length > 0 &&
        product.map((e, i) => {
          return {
            ...e,
            selected: false,
          };
        });
      // setGridKey(gridKey + 1);
      setProducts(allProducts)
      dispatch(updateTrashProducts(allProducts));
    }
  };

  const handleSearchProducts = (text) => {

    let search = text.target.value

    if (!search && selectedCategory) {

      setSearchCategory(product && product.length > 0 && product.filter((e, i) => {

        return e.category == selectedCategory

      }))

      return
    }


    if (selectedCategory == "select" && !search) {

      setSearchCategory([])
      return
    }



    if ((selectedCategory == "All Categories" || selectedCategory == "select") && search) {


      setSearchCategory(product && product.length > 0 && product.filter((e, i) => {

        if (e.productName.toLowerCase().includes(search.toLowerCase()) || e?.barcode?.includes(search)) {
          return e
        }

      }))
      return
    }

    if ((selectedCategory !== "All Categories" && selectedCategory !== "select")) {


      let searching = product && product.length > 0 && product.filter((e, i) => {

        if (e.category == selectedCategory && (e.productName.toLowerCase().includes(search.toLowerCase()) || e?.barcode?.includes(search))) {
          return e
        }

      })


      if (searching.length > 0) {

        setSearchCategory(searching)
      }


    }



  }


  const handleAddInventory = async () => {
    if (!quantityAddInInventory) {
      alert("Kindly enter quantity");
      return;
    }

    let myProducts = [...product];

    let productDetail = myProducts.filter((e, i) => e.selected);

    if (Number(quantityAddInInventory) > Number(productDetail[0]?.DamageQty)) {
      alert("The given quantity exceed the available quantity");
      return;
    }

    productDetail =
      productDetail.length > 0 &&
      productDetail.map((e, i) => {
        return {
          ...e,
          DamageQty: quantityAddInInventory,
        };
      });

    setLoading(true);

    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .post(`${Base_Uri}addTrashProductsInInventory`, productDetail[0], {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res.data;

          if (!data.status) {
            alert(data.message);
            return;
          }

          alert(data.message);
          setGridKey(gridKey + 1);
          setLoading(false);
          setAddInInventoryModalVisible(false);
          setQuantityAddInInventory(0)
          setSearchCategory([]);
          getTrashProducts();
          getProducts();
        })
        .catch((error) => {
          alert(error.message);
          setLoading(false);
          return;
        });
    }
  };

  const handleAddDamages = async () => {
    if (!quantityAddInDamages) {
      alert("Kindly enter quantity");
      return;
    }

    let myProducts = [...product];

    let productDetail = myProducts.filter((e, i) => e.selected);



    if (Number(quantityAddInDamages) > Number(productDetail[0]?.qty)) {
      alert("The given quantity exceed the available quantity");
      return;
    }

    productDetail =
      productDetail.length > 0 &&
      productDetail.map((e, i) => {
        return {
          ...e,
          DamageQty: quantityAddInDamages,
          qty: quantityAddInDamages,
        };
      });


    setLoading(true);
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .post(`${Base_Uri}addTrashProductsInDamages`, productDetail[0], {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res.data;

          if (!data.status) {
            alert(data.message);
            setLoading(false);
            return;
          }

          alert(data.message);
          setGridKey(gridKey + 1);
          setLoading(false);
          setQuantityAddInDamages(0)
          setAddInDamagesModalVisible(false);
          setSearchCategory([]);
          getDamageProducts();
          getTrashProducts();
        })
        .catch((error) => {
          alert(error.message);
          setLoading(false);
          return;
        });
    }
  };



  const handleOpenLedgerModal = () => {
    setLedgerLoading(true);

    let clickedProduct =
      trashProducts &&
      trashProducts.length > 0 &&
      trashProducts.filter((e, i) => e.selected);
    if (clickedProduct.length > 0) {
      clickedProduct = clickedProduct[0];
    }

    let barcode = clickedProduct?.barcode;

    axios
      .get(`${Base_Uri}getTrashProductLedger/${barcode}`)
      .then((res) => {
        let data = res.data;


        if (!data.status) {
          setLedgerLoading(false);
          alert(data?.message);
          return;
        }

        let ledgerData = data?.data;

        let dataToSend = { ...clickedProduct };
        dataToSend.productLedger = ledgerData;

        // clickedProduct.productLedger = ledgerData;

        setProductClicked(dataToSend);
        setLedgerLoading(false);
        setOpenLedgerModal(true);
      })
      .catch((error) => {
        alert(error?.message);
        setLedgerLoading(false);
        return;
      });
  };

  const handleOpenReturnHistoryModal = () => {
    let clickedProduct =
      trashProducts &&
      trashProducts.length > 0 &&
      trashProducts.filter((e, i) => e.selected);
    if (clickedProduct.length > 0) {
      clickedProduct = clickedProduct[0];
    }


    let barcode = clickedProduct?.barcode;



    setLedgerLoading(true)

    axios
      .get(`${Base_Uri}getTrashProductLedger/${barcode}`)
      .then((res) => {
        let data = res.data;


        if (!data.status) {
          setLedgerLoading(false);
          alert(data?.message);
          return;
        }

        let ledgerData = data?.data;

        let dataToSend = { ...clickedProduct };
        dataToSend.productLedger = ledgerData;

        // clickedProduct.productLedger = ledgerData;

        setProductClicked(dataToSend);
        setLedgerLoading(false);
        setOpenReturnHistoryModal(true);
      })
      .catch((error) => {
        alert(error?.message);
        setLedgerLoading(false);
        return;
      });
  };

  const handleOpenTransferHistoryModal = () => {
    let clickedProduct =
      trashProducts &&
      trashProducts.length > 0 &&
      trashProducts.filter((e, i) => e.selected);
    if (clickedProduct.length > 0) {
      clickedProduct = clickedProduct[0];
    }


    let barcode = clickedProduct?.barcode;

    setLedgerLoading(true)

    axios
      .get(`${Base_Uri}getTrashProductLedger/${barcode}`)
      .then((res) => {
        let data = res.data;


        if (!data.status) {
          setLedgerLoading(false);
          alert(data?.message);
          return;
        }

        let ledgerData = data?.data;
        let dataToSend = { ...clickedProduct };
        dataToSend.productLedger = ledgerData;

        // clickedProduct.productLedger = ledgerData;

        setProductClicked(dataToSend);
        setLedgerLoading(false);
        setOpenTransferModal(true);
      })
      .catch((error) => {
        alert(error?.message);
        setLedgerLoading(false);
        return;
      });
  };



  useEffect(() => {
    if (barcodeData?.length > 0) {

      let searchproduct =
        product &&
        product?.length > 0 &&
        product?.find((e) => e?.barcode == barcodeData);


      if (searchproduct) {
        setSearchCategory([searchproduct]);
        setBarcodeRead("");
      }
    }
  }, [barcodeData]);

  return (
    ledgerLoading ? (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "70vh",
          width: "100%",
        }}
      >
        <RingLoader loading={ledgerLoading} size={100} color={Colors.black} />
      </Box>
    ) :
      <Box sx={{ padding: "20px", backgroundColor: Colors.white, marginTop: "20px" }} >

        <Box sx={{ display: "flex" }} >
          <Grid container md={12} xs={12} sm={12} >
            <Grid item xs={12} sm={12} md={6} >
              <Box sx={{ display: "flex", alignItems: "center" }} >
                <Grid item xs={1} sm={1} md={1}  >
                  <img src={require("../../Images/filter.png")} height={"15px"} style={{ marginRight: "5px" }} />
                </Grid>
                <Grid item xs={3} sm={2} md={3} lg={3} xl={2.5} style={{ marginRight: "5px" }} >
                  <CustomButton text="Bin" icon={<img src={require("../../Images/cross.png")} height="15px" />} style={{ width: "100%", padding: "5px 5px 5px 5px", fontSize: { xl: "12px", lg: "12px", md: "10px", sm: "10px", xs: "8px" }, display: "flex", justifyContent: "space-around", marginRight: "5px", alignItems: "center" }} />
                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4} xl={2.5} style={{ marginRight: "5px" }} >
                  <BasicSelect value={selectedCategory} status="Category" id={category} innerStyle={{ fontSize: "12px", border: `1px solid ${Colors.border}`, borderRadius: 10, fontWeight: "bold" }} style={{ border: "0px" }} onChange={(e) => handleFilterCategory(e)} />
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} >
              <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >

                <Grid md={11} xs={10} sm={10} >
                  <Box sx={{ width: "100%", marginTop: { xs: "10px", sm: "10px", md: "0px" }, border: `1px solid ${Colors.button}`, borderRadius: "5px", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px 10px 5px 10px", margin: 0 }} >
                    <TextField
                      variant="standard" // <== changed this
                      margin="normal"
                      required
                      fullWidth
                      InputProps={{
                        // startAdornment: <AccountCircle />, // <== adjusted this
                        disableUnderline: true,
                        fontSize: "12px"
                        // <== added this
                        // fontSize: "2px"
                      }}
                      onChange={handleSearchProducts}
                      placeholder="Search Name ,Id , Phone & Bar Code"
                      sx={{ width: "90%", fontSize: "12px", padding: 0, fontFamily: "Poppins", fontWeight: "bold", margin: 0 }}
                    />
                    <img src={require("../../Images/search.png")} style={{ height: "20px" }} />
                  </Box>
                </Grid>


                <Box sx={{ margin: 0, height: "35px", width: "35px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: Colors.button }} >
                  <img src={require("../../Images/qrcode.png")} style={{ height: "20px" }} />
                </Box>

              </Box>
            </Grid>

            {product &&
              product?.length > 0 &&
              product.filter((e, i) => e.selected).length == 1 && (
                <Box sx={{ display: "flex", mt: 1, ml: 1 }}>
                  <Grid container md={12} xs={12} sm={12}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "80vw",
                        }}
                      >
                        <Grid
                          item
                          xs={3}
                          sm={2}
                          md={3}
                          lg={3}
                          xl={2.5}
                          style={{ marginRight: "5px" }}
                        >
                          <CustomButton
                            onClick={() => setAddInInventoryModalVisible(true)}
                            text={"Add In Inventory"}
                            //   onClick={() => setAddInInventoryModalVisible(true)}
                            style={{
                              width: "100%",
                              padding: "5px 5px 5px 5px",
                              fontSize: {
                                xl: "12px",
                                lg: "12px",
                                md: "10px",
                                sm: "10px",
                                xs: "8px",
                              },
                              display: "flex",
                              color: Colors.green,
                              justifyContent: "space-around",
                              marginRight: "5px",
                              alignItems: "center",
                              backgroundColor: Colors.white,
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sm={3}
                          md={4}
                          lg={5}
                          xl={2.5}
                          style={{ marginRight: "5px" }}
                        >
                          <CustomButton
                            onClick={() => setAddInDamagesModalVisible(true)}
                            text={"Add In Damages"}
                            //   onClick={() => setAddInTrashModalVisible(true)}
                            style={{
                              width: "100%",
                              padding: "5px 5px 5px 5px",
                              fontSize: {
                                xl: "12px",
                                lg: "12px",
                                md: "10px",
                                sm: "10px",
                                xs: "8px",
                              },
                              display: "flex",
                              justifyContent: "space-around",
                              marginRight: "5px",
                              alignItems: "center",
                              backgroundColor: Colors.red,
                              color: Colors.white,
                              border: `1px solid green`,
                            }}
                          />
                        </Grid>
                      </Box>

                      <Box sx={{ display: "flex", width: "100%" }}>
                        <Grid
                          item
                          xs={4}
                          sm={3}
                          md={4}
                          lg={5}
                          xl={2.5}
                          style={{ marginRight: "5px", marginTop: "10px" }}
                        >
                          <CustomButton
                            text={"Show Ledger"}
                            onClick={() => handleOpenLedgerModal()}
                            style={{
                              width: "100%",
                              padding: "5px 5px 5px 5px",
                              fontSize: {
                                xl: "12px",
                                lg: "12px",
                                md: "10px",
                                sm: "10px",
                                xs: "8px",
                              },
                              display: "flex",
                              justifyContent: "space-around",
                              marginRight: "5px",
                              alignItems: "center",
                              backgroundColor: Colors.green,
                              color: Colors.white,
                              border: `1px solid green`,
                            }}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={4}
                          sm={3}
                          md={4}
                          lg={5}
                          xl={2.5}
                          style={{ marginRight: "5px", marginTop: "10px" }}
                        >
                          <CustomButton
                            text={"Show Return History"}
                            onClick={() => handleOpenReturnHistoryModal()}
                            style={{
                              width: "100%",
                              padding: "5px 5px 5px 5px",
                              fontSize: {
                                xl: "12px",
                                lg: "12px",
                                md: "10px",
                                sm: "10px",
                                xs: "8px",
                              },
                              display: "flex",
                              justifyContent: "space-around",
                              marginRight: "5px",
                              alignItems: "center",
                              backgroundColor: Colors.button,
                              color: Colors.white,
                              border: `1px solid green`,
                            }}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={4}
                          sm={3}
                          md={4}
                          lg={5}
                          xl={2.5}
                          style={{ marginRight: "5px", marginTop: "10px" }}
                        >
                          <CustomButton
                            text={"Show Transfer History"}
                            onClick={() => handleOpenTransferHistoryModal()}
                            style={{
                              width: "100%",
                              padding: "5px 5px 5px 5px",
                              fontSize: {
                                xl: "12px",
                                lg: "12px",
                                md: "10px",
                                sm: "10px",
                                xs: "8px",
                              },
                              display: "flex",
                              justifyContent: "space-around",
                              marginRight: "5px",
                              alignItems: "center",
                              backgroundColor: Colors.white,
                              color: Colors.green,
                              border: `1px solid green`,
                            }}
                          />
                        </Grid>
                      </Box>

                    </Grid>
                  </Grid>
                </Box>
              )}

          </Grid>
        </Box>
        <Box sx={{ height: "500px", backgroundColor: "white", marginTop: 5 }} >
          {<DataGrid key={gridKey} sx={{
            '& .MuiDataGrid-cell': {
              fontFamily: 'Poppins',
              fontSize: "12px",
              fontWeight: "600",
              color: "#58595B"
            },
            '& .MuiDataGrid-colCell, & .MuiDataGrid-colCellTitle': {
              padding: '0px', // Adjust this value as needed,
              fontFamily: "Poppins"
            },
            '& .MuiDataGrid-columnHeaderCheckbox': {
              backgroundColor: Colors.black,
              color: 'white',
            },
            '& .css-1vjf6hs-MuiDataGrid-root .css-ptiqhd-MuiSvgIcon-root': {
              fill: Colors.white,
            },

            '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root': {
              color: "gray"
            }
            ,
            '& .MuiDataGrid-columnHeader': {
              fontFamily: "Poppins",
              fontWeight: "bold",
            },

          }} onColumnHeaderClick={(e) => getAllRowData(e)} checkboxSelection onRowSelectionModelChange={handleSelectionModelChange}
            rowSelectionModel={product && product.length > 0 ? product.filter((e, i) => e?.selected)?.map((row) => row.id) : []}
            rows={searchCategory.length > 0 ? searchCategory : (product && product.length > 0 ? product : [])} columns={column} />}
        </Box>


        {addInInventoryModalVisible && (
          <CustomModal
            open={addInInventoryModalVisible}
            closeModal={() => setAddInInventoryModalVisible(false)}
            heading={"Add In Inventory"}
            credit={true}
            loading={loading}
            placeholder={"Enter Quantity"}
            onChange={(e) => setQuantityAddInInventory(e.target.value)}
            confirm={() => handleAddInventory()}
            text={"Are your sure you want to add this product in inventory."}
          />
        )}

        {addInDamagesModalVisible && (
          <CustomModal
            open={addInDamagesModalVisible}
            closeModal={() => setAddInDamagesModalVisible(false)}
            heading={"Add In Damages"}
            credit={true}
            loading={loading}
            placeholder={"Enter Quantity"}
            onChange={(e) => setQuantityAddInDamages(e.target.value)}
            confirm={() => handleAddDamages()}
            text={"Are your sure you want to add this product in Damage."}
          />
        )}


        {openLedgerModal && (
          <TrashLedgerModal
            data={productClicked}
            open={openLedgerModal}
            closeModal={() => setOpenLedgerModal(false)}
          />
        )}
        {openReturnHistoryModal && (
          <TrashReturnHistoryModal
            data={productClicked}
            open={openReturnHistoryModal}
            closeModal={() => setOpenReturnHistoryModal(false)}
          />
        )}
        {openTransferModal && (
          <TrashTransferModal
            data={productClicked}
            open={openTransferModal}
            closeModal={() => setOpenTransferModal(false)}
          />
        )}

      </Box>
  )
}

export default Trash
